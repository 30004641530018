.text-left{
    text-align: left !important;
}
.w-full{
    width: 100% !important;
}
.mint-nft-page{
    padding-top: 100px;
    padding-bottom: 50px;
    background: #FFF;
    min-height: 500px;
}
.mint-nft-page .gif-nft-block{
    text-align: center;
}
.mint-nft-page .gif-nft-block img{
    width: auto;
    max-width: 100%;
    border-radius: 10px;
}
.mint-nft-content{
    margin-top: 20px;
}
.mint-nft-content h3.mint-nft-title{
    color: #1E2329;
    font-size: 32px;
    font-weight: 500;
}
.mint-nft-content .mint-nft-description{
    color: #707A8A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.42px;
}
.mint-nft-content .mint-nft-progress{
    margin-top: 15px;
    margin-bottom: 15px;
}
.mint-nft-content .mint-nft-progress span.progress-title{
    color: #707A8A;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.42px;
}
.mint-nft-content .mint-nft-progress .progress-number-sold{
    color: #707A8A;
    font-size: 14px;
    line-height: normal;
    letter-spacing: 0.42px;
    text-align: right !important;
    margin-top: 5px;
}
.mint-nft-content .mint-nft-progress .mint-progress .ant-progress-bg{
    height: 11px !important;
}
.mint-nft-content .mint-nft-progress .mint-progress .ant-progress-outer{
    padding-right: 0px !important;
    margin-right: 0px !important;
}
.mint-nft-content .mint-nft-progress .mint-progress .ant-progress-text {
    position: absolute;
    margin-top: -15px;
    right: 0px;
}

.mint-nft-content .mint-nft-remaning{
    border-radius: 6px;
    border: 1px solid #E9EFF5;
    min-height: 130px;
    background: #F3F4F6;
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: left;
    padding: 15px;
}
.mint-nft-content .mint-nft-remaning .mint-remaning-title{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    background: var(--main, linear-gradient(93deg, #FFD75A -40.87%, #EB0029 170.93%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.mint-nft-content .mint-nft-remaning .mint-remaning-time{
    margin-top: 10px;
}
.mint-nft-content .mint-nft-remaning .mint-remaning-time p{
    margin-top: 5px;
    margin-bottom: 0px;
    color: #707A8A;
    font-size: 14px;
}
.mint-nft-content .mint-nft-remaning .mint-remaning-time .mint-remaning-countdown{
    margin-top: 10px;
}
.mint-nft-content .mint-nft-remaning .mint-remaning-time .mint-remaning-button{
    margin-top: 7px;
}
.btn-full{
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
}
.mint-nft-content .mint-nft-remaning .mint-remaning-time .mint-remaning-countdown span{
    color: #1E2329;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
}
.mint-nft-content .mint-schedule h4{
    color: #1E2329;
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
    margin-bottom: 0px;
}
.mint-nft-content .mint-schedule .mint-schedule-block{
    border-radius: 6px;
    border: 1px solid #E9EFF5;
    min-height: 130px;
    background: #F3F4F6;
    text-align: left;
    padding: 15px;
    margin-top: 15px;
}
.mint-nft-content .mint-schedule .mint-schedule-block .mint-schedule-title{
    display: flex;
    justify-content: space-between;
}
.mint-nft-content .mint-schedule .mint-schedule-block .mint-schedule-title span:nth-child(1){
    color: #1E2329;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.54px;
}
.mint-nft-content .mint-schedule .mint-schedule-block .mint-schedule-title span:nth-child(2){
    padding: 6px 10px;
    border-radius: 5px;
    background: #707A8A;
    color: #C2CFE3;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.12px;
}
.mint-nft-content .mint-schedule .mint-schedule-block .mint-schedule-title span.is-eligible{
    background: #20DFA8;
    color: #FFF;
}
.mint-nft-content .mint-schedule .mint-schedule-block .mint-schedule-date{
    margin-top: 5px;
    margin-bottom: 5px;
    color: #707A8A;
    font-size: 16px;
    letter-spacing: 0.54px;
}
.mint-nft-content .mint-schedule .mint-schedule-block .mint-schedule-amount{
    color: #1E2329;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.54px;
}
.mint-nft-content .mint-schedule .mint-schedule-block .mint-schedule-limit{
    color: #707A8A;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.54px;
}
.mint-nft-content .mint-nft-form{
    border-radius: 6px;
    border: 1px solid #E9EFF5;
    min-height: 130px;
    background: #F3F4F6;
    text-align: left;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.mint-nft-content .mint-nft-form .mint-form-title{
    color: #707A8A;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}
.mint-nft-content .mint-nft-form .mint-form-balance{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}
.mint-nft-content .mint-nft-form .mint-form-balance span{
    margin-right: 7px;
}
.mint-nft-content .mint-nft-form .mint-form-balance span:nth-child(1) img{
    max-width: 25px;
    width: 100%;
}
.mint-nft-content .mint-nft-form .mint-form-balance span:nth-child(2){
    color: #1E2329;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.mint-nft-content .mint-nft-form .mint-form-input{
    display: flex;
}
.mint-nft-content .mint-nft-form .mint-form-input .mint-form-quantity{
    width: 50%;
    padding-right: 10px;
}
.mint-nft-content .mint-nft-form .mint-form-input .mint-form-quantity .input-amount{
    width: 100%;
    padding: 5px;
    background: #DCDCDC;
    border-radius: 6px;
}
.mint-nft-content .mint-nft-form .mint-form-input .mint-form-quantity .input-amount .ant-input-group-addon{
    padding: 0px !important;
}
.mint-nft-content .mint-nft-form .mint-form-input .mint-form-quantity .input-amount .ant-input{
    height: 40px;
    text-align: center;
    background: #DCDCDC;
}
.mint-nft-content .mint-nft-form .mint-form-input .mint-form-quantity .btn-addon{
    padding: 12px;
    line-height: 13px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 6px;
    background: #FFF;
    border: none !important;
    box-shadow: none !important;
}
.mint-nft-content .mint-nft-form .mint-form-input .mint-form-button{
    width: 50%;
}
.gif-mo{
    padding-top: 20px;
    padding-bottom: 5px;
}