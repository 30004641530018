img {
  width: 100%;
}

.container {
  width: 1160px;

  padding: 0 10px;

  margin: 0 auto;
}

/* .ant-menu-submenu:nth-child(2) .ant-menu-submenu-title:after {
  display: none;
} */
a {
  touch-action: inherit !important;
}
.main-header {
  padding: 12.5px 35px;

  @media screen and (max-width: 1450px) {
    padding: 15px;
  }

  display: flex;

  justify-content: space-between;

  position: fixed;
  background: transparent;
  top: 0;
  z-index: 999;
  width: 100%;
}

.main-header.scroll {
  background: #fff;
  box-shadow: 0px 1px 20px #00000024;
  z-index: 1000;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #000;
}

.main-logo .box-img {
  height: auto;

  width: 142px;
}

.main-menu .menu-dropdown {
  display: flex;

  background: transparent !important;
}

.menu-dropdown .ant-menu-item,
.menu-dropdown .ant-menu-submenu-title {
  color: #687788;

  font-size: 15px;

  font-weight: 500;
}

.menu-dropdown.ant-menu-light .ant-menu-item:hover,
.menu-dropdown.ant-menu-light .ant-menu-submenu:hover .ant-menu-submenu-title {
  color: #1e2329 !important;

  filter: brightness(0.1);
}

.ant-menu-light .ant-menu-submenu:hover .ant-menu-submenu-title {
  color: #1e2329 !important;

  /* filter: brightness(0.1); */
}

.menu-dropdown.ant-menu-light
  .ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow {
  color: #1e2329 !important;
}

.menu-dropdown.ant-menu-light .ant-menu-submenu-expand-icon,
.menu-dropdown.ant-menu-light .ant-menu-submenu-arrow {
  transform: rotate(90deg) translateY(50%);

  color: #687688 !important;
}

.ant-menu-root.ant-menu-vertical.menu-dropdown {
  border-right: 0px solid #f0f0f0;
}

.main-connect {
  display: flex;

  align-items: center;
}

.main-connect .cnt-left {
  margin-right: 28px;

  color: #687788;

  font-size: 15px;

  font-weight: 500;

  display: flex;

  align-items: center;
}

.main-connect .cnt-left .img-price {
  width: 25px;

  height: auto;

  margin-right: 8px;
}

.main-connect .cnt-right .btn-connect {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);

  padding: 10px 20px;

  font-size: 16px;

  color: #fff;

  border: 0px solid transparent;

  border-radius: 25px;

  display: flex;

  align-items: center;

  cursor: pointer;
  transition: 0.3s ease;
  white-space: nowrap;
}

.main-connect .cnt-right .btn-connect:hover {
  background: linear-gradient(
    93.41deg,
    rgba(240, 185, 11, 0.7) -40.87%,

    rgba(235, 0, 41, 0.7) 170.93%
  );

  box-shadow: 0px 4px 20px rgba(114, 113, 113, 0.17);

  border-radius: 33px;
}

.arrow-btn {
  width: 18.5px;

  height: auto;

  margin-left: 10px;
}

.columns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  &.center {
    align-items: center;
    justify-content: center;
  }
}

.colum {
  padding: 15px;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.main-footer {
  padding-bottom: 10px;

  background-position: bottom;

  background-repeat: no-repeat;

  background-size: cover;

  padding-top: 50px;
}

.content-footer-l {
  text-align: left;
}

.content-footer-r {
  text-align: left;

  padding-left: 30px;
}

.content-menu .title-ft {
  font-style: normal;

  font-weight: 600;

  font-size: 18px;

  line-height: 27px;

  margin-bottom: 45px;

  /* identical to box height */

  color: #1e2329;
}

.list-menu-ft li {
  list-style-type: none;

  font-style: normal;

  font-weight: 400;

  font-size: 14px;

  line-height: 21px;

  /* identical to box height */

  color: #1e2329;
}

.list-menu-ft {
  padding-left: 0;

  margin-bottom: 0;
}

.list-menu-ft li img {
  width: 16px;
  margin-right: 5px;
}

.content-footer-r .list-content .box-logo {
  width: 142px;

  margin-bottom: 25px;
}

.content-footer-r .list-content .desc {
  font-weight: 400;

  font-size: 14px;

  line-height: 21px;

  letter-spacing: 0.01em;

  color: #616772;
}

.content-footer-r .list-content .desc-mail {
  font-weight: 400;

  font-size: 14px;

  line-height: 21px;

  letter-spacing: 0.01em;

  color: #616772;

  display: flex;

  align-items: center;

  text-decoration: underline;
}

.content-footer-r .list-content .desc-mail img {
  width: 20px;

  margin-right: 5px;
}

.img-price-ft {
  width: 25px;

  height: auto;

  margin-right: 8px;
}

.price-ft .price-l {
  margin-right: 28px;

  color: #687788;

  font-size: 15px;

  font-weight: 600;

  display: flex;

  align-items: center;
}

.price-ft {
  display: flex;

  align-items: center;

  margin-top: 15px;

  padding-bottom: 45px;

  border-bottom: 0.5px solid #aab6cd;
}

.price-ft .price-r {
  display: flex;

  align-items: center;
}

.price-ft .price-r .icon-meta {
  width: 40px;

  margin-right: 6px;
}

.btn-buy-bscs {
  background: #20dfa8;

  border: 0px solid transparent;

  padding: 10px 15px;

  border-radius: 6px;

  color: #fff;
}

.copy-right {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #707a8a;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.box-icon-menu {
  width: 20px;

  margin-right: 5px;
}

.main-menu .ant-menu-item {
  display: flex;

  align-items: center;
}

.main-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.main-menu .ant-menu-submenu-title {
  display: flex;

  align-items: center;
}

.main-menu .ant-menu-submenu:last-child .ant-menu-submenu-arrow {
  display: none;
}

.main-menu .ant-menu.ant-menu-sub {
  z-index: 999;
}

.btn.focus,
.btn:focus {
  outline: 0 !important;

  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44) !important;
}

.main-cnt-mobile .btn-connect {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);

  /* padding: 10px 20px; */

  font-size: 16px;

  color: #fff;

  border: 0px solid transparent;

  border-radius: 25px;

  display: flex;

  align-items: center;

  cursor: pointer;

  white-space: nowrap;
}

.main-header-mobile {
  display: flex;

  align-items: center;

  justify-content: space-between;

  padding: 10px;

  position: fixed;

  width: 100%;

  top: 0;
  z-index: 999;
  left: 0;
}

.main-header-mobile .main-logo-mobile .box-img {
  width: 92px;
}

.main-cnt-mobile .btn-connect {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  padding: 8px;
  font-size: 14px;
  margin-left: auto;

  color: #fff;

  border: 0px solid transparent;

  border-radius: 25px;

  display: flex;

  align-items: center;

  cursor: pointer;
}

.btn-open {
  background: #ffffff;

  box-shadow: 0px 6px 20px rgb(192 192 192 / 44%);

  border-radius: 6px;

  padding: 8px 10px;

  height: auto;

  border: 0px solid transparent;
}

.btn-open img {
  width: 18px;

  height: 20px;
}

.drawer-mobile .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: block;
}

.drawer-mobile.ant-drawer-right .ant-drawer-content-wrapper {
  width: 100% !important;
}

.drawer-mobile .ant-drawer-content {
  background: transparent;
}

.drawer-mobile .ant-drawer-header {
  background: transparent;

  padding: 0;
}

.drawer-mobile .ant-drawer-body {
  background: #fff;
  padding: 24px;
}

.drawer-mobile .ant-drawer-close {
  background: #ffffff;

  box-shadow: 0px 6px 20px rgb(192 192 192 / 44%);

  border-radius: 6px;

  padding: 11px 11px;

  height: auto;

  border: 0px solid transparent;

  right: -2px;

  top: 10px;
  z-index: 999;
  position: absolute;
}

.drawer-mobile .ant-drawer-header-no-title .ant-drawer-close {
  padding-right: 10px;
}

.main-logo-mobile-row img {
  width: 120px;
}

.drawer-mobile .ant-drawer-title {
  background: #fff;
  padding: 10px 24px;
}

.drawer-mobile .ant-drawer-header {
  border-bottom: 0px solid #f0f0f0;
}

.heeader-menu-collapse {
  display: flex;
}

.collapse-menu.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  position: absolute;

  right: 10px;

  top: 15px;
}

.heeader-menu-collapse .box-icon {
  position: absolute;

  left: 0;
}

.heeader-menu-collapse .title-menu {
  color: #687788;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 25px;
}

.heeader-menu-collapse .title-menu.bscs {
  margin-left: 0;
}

.collapse-menu.ant-collapse > .ant-collapse-item {
  border-bottom: 0px solid #d9d9d9;
}

.collapse-menu.ant-collapse {
  border: 0px solid #d9d9d9;

  background-color: #fff;
}

.drawer-mobile
  .collapse-menu.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  right: 10px;
}

.collapse-menu .ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);

  background-color: #fff;

  border-top: 0px solid #d9d9d9;
}

.sub-menu-collapse li {
  color: #687788;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  list-style-type: none;

  text-align: left;
  padding-bottom: 10px;
}

.sub-menu-collapse {
  padding-left: 0;

  margin-bottom: 0;

  text-align: center;
}

.collapse-menu .ant-collapse-content-box {
  padding: 5px;
}

.content-ft-menu .list-social .icon-sl {
  width: 20px;
}

.content-ft-menu .list-social {
  display: flex;

  justify-content: space-between;

  padding: 0 50px;
}

.content-ft-menu .list-social-footer {
  display: flex;
  justify-content: flex-start;
}

.price-ft.mobile {
  padding-bottom: 0;

  border-bottom: 0 solid #aab6cd;

  justify-content: center;

  margin-top: 20px;
}

.footer-menu-mobile {
  position: absolute;

  bottom: 30px;

  width: 100%;

  left: 0;
}

.main-footer.mobile .content-footer-r {
  padding-left: 0;
}

.main-footer.mobile .price-ft {
  width: 100%;

  justify-content: space-between;

  border-bottom: 0px solid #aab6cd;
}

.main-footer.mobile .ant-collapse-header {
  font-style: normal;

  font-weight: 600;

  font-size: 18px;

  line-height: 27px;

  color: #1e2329;

  text-align: initial;

  padding: 10px 0;
}

.main-footer.mobile .collapse-menu.ant-collapse {
  background: transparent;
}

.main-footer.mobile .collapse-menu .ant-collapse-content {
  background: transparent;
}

.main-footer.mobile .sub-menu-collapse li {
  text-align: left;

  font-style: normal;

  font-weight: 400;

  font-size: 14px;

  line-height: 21px;

  color: #1e2329;
}

.main-footer.mobile .collapse-menu .ant-collapse-content-box {
  padding: 5px 0;
}

.main-footer.mobile .copy-right {
  border-top: 0.5px solid #aab6cd;

  padding-top: 15px;
}

.copy-right .row-copy-right {
  padding: 0 5px 0 5px;
}

.copyright-icon {
  width: auto;
}

.sub-menu-collapse li a,
.list-menu-ft li a {
  color: #1e2329;
  display: block;
  transition: 0.3s ease;
}

.btn-buy-bscs:hover {
  cursor: pointer;
}

.list-menu-ft li a:hover {
  color: #707a8a;
}

.main-guide {
  display: flex;
}

.main-menu {
  margin-left: 40px;
}

.main-menu .ant-menu-inline .ant-menu-submenu {
  position: relative;
}

.main-menu .ant-menu.ant-menu-sub {
  position: absolute;

  top: 100%;

  left: 0;

  width: 180px;

  padding: 8px;

  background: #fff;

  box-shadow: 0px 10px 30px rgba(112, 122, 138, 0.05);

  border-radius: 0px 0px 6px 6px;
}

.main-menu .ant-menu-sub.ant-menu-inline > .ant-menu-item {
  width: 150px;

  padding-left: 0 !important;
}

.ant-menu-inline .ant-menu-item-selected:after {
  display: none;
}

.ant-menu-inline {
  border-right: 0px solid gray;
}

.menu-dropdown.ant-menu-light .ant-menu-submenu-arrow {
  transform: rotate(0deg) translateY(50%);

  color: #687688 !important;
}

.menu-dropdown.ant-menu-submenu-title:hover,
.menu-dropdown.ant-menu-submenu-title:focus {
  filter: brightness(0);
}

.menu-dropdown.ant-menu-light .ant-menu-item:hover,
.menu-dropdown.ant-menu-light .ant-menu-submenu-title:hover {
  color: #1e2329 !important;

  filter: none !important;
}

.img-copy {
  cursor: pointer;
}

.sub-menu-header {
  color: #687788 !important;
}

.sub-menu-header:hover {
  color: #1e2329 !important;
}

.bsc-stake_in_pool-modal {
  background: #fff !important;

  padding: 35px 30px 20px 30px;

  border-radius: 6px;
}

.bsc-stake_in_pool-modal-content {
  background: #fff;
}

.bsc-stake_in_pool-modal-header {
  text-align: center;

  border-bottom: 0.5px solid #aab6cd;

  padding-bottom: 10px;

  margin-bottom: 10px;
}

.bsc-stake_in_pool-modal-header > span {
  font-weight: 600;

  font-size: 20px;

  line-height: 30px;

  /* identical to box height */

  text-align: center;

  color: #1e2329;
}

.bsc-stake_in_pool-modal-header > span + span {
  font-weight: 400;

  font-size: 16px;

  line-height: 138.5%;

  /* or 22px */

  text-align: center;

  display: block;

  color: #616772;
}

.bsc-input {
  position: relative;
}

.bsc-input input {
  width: 400px;

  max-width: 100%;

  height: 56px;

  background: #eff2f5;

  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);

  border-radius: 6px;

  border: 0px solid transparent;

  font-weight: 600;

  font-size: 18px;

  line-height: 138.5%;

  padding-left: 20px;

  /* identical to box height, or 42px */

  text-align: left;

  color: #5a6776;
}

.bsc-input .bsc-input-right {
  position: absolute;

  right: 7px;

  top: 7px;

  display: flex;

  align-items: center;

  background: #eff2f5;

  padding-left: 10px;
}

.bsc-input .bsc-input-right > span {
  margin-right: 10px;

  font-weight: 400;

  font-size: 16px;

  line-height: 138.5%;

  /* or 22px */

  text-align: right;

  color: #616772;
}

.bsc-stake_in_pool-modal-content-bottom {
  display: flex;

  justify-content: center;

  margin-top: 30px;
}

.bsc-stake_in_pool-modal-content-bottom > div {
  margin: 0 10px;
}

.bsc-stake_in_pool-modal-content-top > span + span {
  font-weight: 600;

  font-size: 13px;

  line-height: 20px;

  /* identical to box height */

  text-align: right;

  letter-spacing: 0.01em;

  color: #1e2329;
}

.bsc-stake_in_pool-modal-content-top > span {
  font-weight: 600;

  font-size: 13px;

  line-height: 20px;

  /* identical to box height */

  text-align: right;

  letter-spacing: 0.01em;

  color: #000;
}

.bsc-stake_in_pool-modal-content-top {
  margin-bottom: 5px;

  text-align: right;
}

.menu-dropdown.ant-menu-light .ant-menu-item:hover,
.menu-dropdown.ant-menu-light .ant-menu-submenu-title:hover .box-icon-menu img {
  filter: brightness(0.5);
}

.ant-menu-light .ant-menu-item:hover .box-icon-menu img {
  filter: brightness(0.5) !important;
}

.linkv2 {
  padding-top: 13px;

  height: 50px;

  background: antiquewhite;
}

.linkv2-hide {
  display: none;
}

span.linkv2-text {
  padding: 10px 50px 10px 50px;

  background: #e7be8787;

  border-radius: 12px;

  font-weight: 600;
}

.linkv2:hover {
  opacity: 0.9;
}

.main-menu .ant-menu-sub.ant-menu-inline > .ant-menu-item {
  padding-right: 0;

  position: relative;

  transition: 0.3s ease;

  width: 100%;

  padding: 0 5px !important;

  padding-left: 5px !important;

  padding-right: 5px !important;

  color: #707a8a;
}

.main-menu .anticon-arrow-right {
  position: absolute;

  top: 50%;

  right: 5px;

  transform: translateY(-50%);

  opacity: 0;

  transition: 0.3s ease;
}

.main-menu .ant-menu-sub.ant-menu-inline > a > .ant-menu-item:hover {
  background: #f6f7fc;

  border-radius: 6px;
}

.main-menu .ant-menu-sub.ant-menu-inline > .ant-menu-item:hover {
  background: #f6f7fc;

  border-radius: 6px;
}

.main-menu .ant-menu-item .ant-menu-item-icon + span {
  margin-left: 0;
}

/* .main-menu .ant-menu-item {
  padding: 0 30px !important;
} */

.main-menu
  .ant-menu-sub.ant-menu-inline
  > .ant-menu-item:hover
  .anticon-arrow-right {
  opacity: 1;
}

.main-menu
  .ant-menu-sub.ant-menu-inline
  > a
  > .ant-menu-item:hover
  .anticon-arrow-right {
  opacity: 1;
}

button:focus {
  outline: 1px dotted;

  outline: 0px auto -webkit-focus-ring-color !important;
}

@media screen and (max-width: 1450px) {
  .main-menu {
    margin-left: 0 !important;
  }
}

.linkv2-mobie {
  display: flex;

  /* padding-top: 13px; */

  height: 83px;

  background: antiquewhite;

  align-items: center;
}

.linkv2-mobie-hide {
  display: none;
}

span.linkv2-mobie-text {
  padding: 10px 50px 10px 50px;

  background: #e7be8787;

  border-radius: 12px;

  font-weight: 600;
}

.linkv2-mobie:hover {
  opacity: 0.9;
}

#components-dropdown-demo-arrow .ant-btn {
  margin-right: 8px;

  margin-bottom: 8px;
}

.ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
  margin-right: 0;

  margin-bottom: 8px;

  margin-left: 8px;
}

.main-menu .ant-menu-horizontal {
  background: transparent;

  border-bottom: none;
}

/* .total-menu {

  position: relative;

} */

.iconMenu {
  position: absolute;

  right: 5px;
}

.ant-menu-vertical .ant-menu-item {
  padding: 0 5px !important;
}

span.textMenu {
  padding-left: 10px;
  font-weight: 400;
}

.ti-menu {
  position: relative;

  border-radius: 6px;

  font-weight: 400;

  font-size: 14px;
  width: 200px;
}

.ti-menu:hover {
  background-color: #f6f7fc;

  /* color: #1e2329; */
}

.main-menu .ant-menu-title-content {
  font-weight: 500;

  font-size: 15.5px;

  color: #687788;

  font-family: "Poppins";
}

.main-menu .ant-menu-title-content:hover {
  color: #1e2329;
}

.main-menu .ant-menu-item:hover,
.main-menu .ant-menu-submenu-title:hover .box-icon-menu img {
  -webkit-filter: brightness(0.5);

  filter: brightness(0.5);
}

.iconMenu {
  color: #707a8a;

  transition: 0.3s ease;
}

/* .iconMenu:hover {

  color: #1e2329;

} */

span.textMenu {
  color: #707a8a;

  transition: 0.3s ease;

  font-family: "Poppins";
}

/* span.textMenu:hover {

  color: #1e2329;

} */

.main-menu
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu-open:after {
  border-bottom: 2px solid transparent !important;
}

.ant-menu-submenu:hover::after {
  border-bottom: 2px solid transparent !important;
}

.ant-menu-item:hover .textMenu {
  color: #1e2329;
}

.ant-menu-item:hover .iconMenu {
  color: #1e2329;
}

.main-menu
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu-selected:after {
  border-bottom: 2px solid transparent !important;
}

.main-menu
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-submenu-active:after {
  border-bottom: 2px solid transparent !important;
}

.iconMenu img {
  width: 20px;
  height: 20px;
  opacity: 0;
}

.ti-menu:hover .iconMenu img {
  opacity: 1;
}

.ant-menu-submenu-title {
  position: relative;
}

.ant-menu-submenu-title::after {
  content: "";
  position: absolute;
  top: 46%;
  width: 0;
  right: -14px;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #687788;
  clear: both;
}

.ant-menu-submenu:hover .ant-menu-submenu-title:after {
  border-top: 5px solid #1e2329;
}

.main-menu .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0 24px;
}

.ant-drawer.ant-drawer-right.ant-drawer-open.drawer-mobile {
  z-index: 99999;
}

.main-menu.white .ant-menu-title-content {
  color: #fff;
}

.main-menu.white .ant-menu-title-content a {
  color: #fff;
}

.main-menu.white .ant-menu-submenu-title::after {
  border-top: 5px solid #fff;
}

.main-menu.white .ant-menu-item:hover {
  filter: brightness(5);
}

/* .main-menu.white .ant-menu-item:hover .box-icon-menu img {
  filter: brightness(5);
} */

.main-menu.white .box-icon-menu img {
  filter: brightness(3);
}

.white .ant-menu-title-content:hover {
  color: #fff !important;
}

.white .ant-menu-title-content a:hover {
  color: #fff !important;
}

.white .ant-menu-light .ant-menu-submenu:hover .ant-menu-submenu-title {
  color: #fff !important;
  filter: brightness(1);
}

.white .ant-menu-light .ant-menu-submenu:hover .box-icon-menu img {
  color: #fff !important;
  filter: brightness(3);
}

.title-menu {
  color: #687788;
}

/* .ant-menu-light .ant-menu-item.menu-item-nosub:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #000;
} */
.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item.menu-item-nosub:hover:after,
.ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item-selected.menu-item-nosub:after {
  border-bottom: none;
}

.social-footer {
  padding-bottom: 17px;
}

.icon-sl {
  padding-left: 30px;
}

.icon-sl-first {
  padding-left: 5px;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

.main-header-mobile.scroll {
  background: #fff;
  box-shadow: 0px 1px 20px #00000024;
}

.main-menu .ant-menu-title-content a {
  font-weight: 500;
  font-size: 15.5px;
  color: #687788;
  font-family: "Poppins";
}

.main-menu .ant-menu-title-content a:hover {
  color: #17191c;
}

.main-menu .ant-menu-item-active {
  border-bottom: none !important;
}

.main-menu
  .ant-menu-horizontal:not(.ant-menu-dark)
  > .ant-menu-item:hover:after {
  border-bottom: none;
}

.main-menu .ant-menu-horizontal > .ant-menu-item:after {
  border-bottom: none;
}

.play-mobie {
  font-weight: 500;
  font-size: 20px;
  line-height: 104%;
  text-align: center;
  color: #707a8a;
}

.box-icon-new {
  position: absolute;
  left: 34px;
}

.heeader-menu-collapse-new {
  padding: 12px 16px;
  display: flex;
  justify-content: center;
}

.sub-menu-collapse li a,
.list-menu-ft li a {
  list-style-type: none;
  padding-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: left;
  color: #1e2329;
}

.ant-menu-item a:hover {
  color: #17191c;
}

.img-price-ft:hover {
  cursor: pointer;
}

.filter-chain
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  color: #1e2329;
  display: flex;
  align-items: center;
}

.filter-chain
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 48px;
  width: 220px;
  border-radius: 99px;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(114, 113, 113, 0.17);
  border: 0px solid #d9d9d9;
}

.filter-chain
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  background: #eeeef3;
}

.filter-chain .ant-select-arrow {
  right: 15px;
}

.filter-chain .ant-select-arrow svg {
  fill: #687688;
}

.ant-select-dropdown-placement-bottomLeft {
  /* padding: 5px 10px; */
  border-radius: 6px;
}

.ant-select-item-option-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  padding: 10px 0;

  color: #687688;
}

.ant-select-item.ant-select-item-option {
  border-radius: 6px;
  transition: 0.3s ease;
}

.ant-select-item.ant-select-item-option:hover .ant-select-item-option-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #1e2329;
}

.filter-chain
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector:focus {
  border: 0px solid transparent;
}

.filter-chain .ant-select-focused .ant-select-selector {
  border-color: transparent !important;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0) !important;
}

.ant-select-item-option-content img {
  width: 26px;
  margin-right: 10px;
  border-radius: 50px;
}

.ant-select-selection-item img {
  width: 26px;
  margin-right: 10px;
  border-radius: 50px;
}

/* .rc-virtual-list-holder {
  max-height: 100% !important;
  overflow-y: inherit !important;
} */
.rc-virtual-list-scrollbar {
  display: none !important;
}

.filter-chain .ant-select-selection-item {
  position: relative;
}

.filter-chain .ant-select-selection-item:before {
  content: "";
  background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/f5d9f8792a4041f512d75c91ba99b133e6f50a670aaf9270f7.png);
  position: absolute;
  width: 26px;
  top: 0;
  left: 0;
}

.filter-chain.mobile .ant-select {
  width: 50px !important;
  margin-right: 0 !important;
}

.filter-chain.mobile .ant-select-selection-item img {
  width: 25px;
}

.filter-chain.mobile
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  font-size: 12px;
}

.filter-chain.mobile
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  width: 47px;
}

.filter-chain.mobile .ant-select-selection-item img {
  margin-right: 0;
}

.filter-chain.mobile .ant-select-arrow {
  display: none;
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .account-style img {
    width: 20px;
    margin-right: 0px;
  }

  .account-style {
    padding: 0 5px;
  }

  .btn-account .img-bnb .price {
    font-size: 11px;
    line-height: 12px;
  }

  .btn-account {
    padding: 0px 5px;
  }

  .main-connect .filter-chain .ant-select {
    width: 195px !important;
  }

  .filter-chain
    .ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    width: 195px;
    height: 42px;
  }

  .filter-chain
    .ant-select-single
    .ant-select-selector
    .ant-select-selection-item {
    font-size: 13px;
  }

  .account-style {
    font-size: 11px;
  }

  .main-menu {
    margin-left: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .ant-select-dropdown-placement-bottomLeft {
    padding: 0;
  }

  .ant-select-dropdown {
    width: 150px !important;
    left: 200px !important;
  }

  .net-soon {
    text-align: center;
    font-size: 12px;
    color: #faad14;
    border: 1.5px #faad14 solid;
    border-radius: 6px;
    margin-left: 5px;
    padding: 0px 2px;
  }

  .sub-menu-collapse li a img {
    width: 15px;
  }
}

.net-soon-apt {
  text-align: center;
  font-size: 12px;
  color: #faad14;
  border: 1.5px #faad14 solid;
  border-radius: 6px;
  margin-left: 5px;
  padding: 0px 3px 1px 3px;
  text-transform: uppercase;
}

/* .filter-chain.mobile {
  position: fixed;
  right: 0;
  top: 65px;
} */

.filter-chain .ant-select-selector {
  display: flex;
  align-items: center;
}

.ant-select-filter.ant-select-dropdown-placement-bottomLeft {
  overflow-y: scroll;
  height: 450px;
}

.cnt-right {
  display: flex;
}

.main-cnt-mobile {
  display: flex;
  flex-direction: row-reverse;
}

/* .box-icon-menu img {
  display: block;
} */
.txt-menu-v3 {
  position: relative;
}

.txt-menu-v3 img {
  width: 36px;
  height: 15px;
  position: absolute;
  right: -40px;
  top: 10px;
}

.ant-menu-item:nth-child(2) .box-icon-menu img {
  display: block;
}

.ant-menu-item:nth-child(3) .box-icon-menu img {
  display: block;
}

.content-ft-menu .list-menu-ft {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  margin: auto;
}

.drawer-mobile .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 0px;
}

.drawer-mobile
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  margin-right: 0;
}

.drawer-mobile .ant-collapse {
  height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

.disble-hihi {
  pointer-events: none;
}

.bscs-box {
  border: 1px solid rgba(255, 224, 85, 1);
  background: linear-gradient(270deg, #ff9b1b 0%, #fac668 100%);
  color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  padding: 2px 10px;
}

.icon-menu-prefix {
  img {
    width: 24px;
    height: 24px;
  }
}

.icon-menu-prefix.mobile {
  img {
    width: 20px !important;
    height: 20px;
  }
}

.defai {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 12px 0;

  img {
    width: 20px;
  }

  span {
    color: #687788;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.new-footer {
  background: #ffffff;
  border: 1px solid #0000000a;
  box-shadow: 0px 6px 24px 0px #787a9b1a;
  border-radius: 14px;
  padding: 16px !important;
  position: relative;
  left: 40px;
  bottom: 3px;
}

.new-menu-item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 6px;

  .new-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    width: 42px;

    img {
      width: 24px !important;
    }
  }

  &.mobile {
    text-align: start;
    padding: 8px 0;

    .new-logo {
      max-width: 30px;

      img {
        width: 20px !important;
      }
    }
  }

  .new-text {
    * {
      margin: 0;
      padding: 0;
    }

    line-height: normal;
    max-width: 254px;

    .new-title {
      font-family: Poppins;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0%;
      color: #1e2329;
    }

    .new-description {
      font-family: Poppins;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0%;
      color: #7a8195;
      word-wrap: break-word;
      overflow-wrap: break-word;
      white-space: normal;
    }
  }

  .new-arrow {
    opacity: 0;

    img {
      width: 20px;
    }
  }
}

.new-menu-item-link {
  &:hover {
    .new-menu-item {
      background: #f6f7fc;
    }

    .new-arrow {
      opacity: 1 !important;
    }
  }
}

.footer-bg {
  width: 1440px !important;
  max-width: 1440px !important;
  background: url("./images/banner.png");
  background-size: cover;
  padding: 82px 150px !important;

  @media (max-width: 1280px) {
    .columns {
      flex-wrap: unset;
    }
    .new-footer.colum.w-30 {
      left: 0;
    }
  }

  @media (max-width: 1024px) {
    max-width: 100% !important;
    padding: 60px !important;
    .columns {
      flex-wrap: unset;
    }
    .columns.new {
      flex-direction: column;
    }
    .new-footer.colum.w-30 {
      left: 0;
    }
    .w-30, .w-70 {
      width: 100% !important;
    }
    .w-30 {
      max-width: 500px;
    }
    .content-footer-1 .columns {
      justify-content: space-between;
    }
  }
}
