.title-sup-top {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 104%;
  text-align: center;
  color: #25273d;
  padding-top: 20px;
}

.titlesup-bot {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 138.5%;
  text-align: center;
  color: #787a9b;
  padding-top: 15px;
}

.sup-item {
  padding-top: 47px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: stretch;
}

.box-sub-item {
  padding: 10px;
  border-radius: 24px;
  position: relative;
  background: #fff;
  z-index: 9;
  height: 100%;
}

.wrap-box:hover .gradient-shadow {
  opacity: 1;
}

.gradient-shadow {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  background: linear-gradient(137.86deg, #DE9FFC 2.2%, #4FFFFF 55.58%, #F5CB11 96.67%);
  border-radius: 24px;
  opacity: 0;
  filter: blur(8px);
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.wrap-box {
  position: relative;
  flex: 1;
  border: 1px solid #E9EFF5;
  border-radius: 24px;
  cursor: pointer;
}

.icon-sup {
  width: 42px;
  height: 42px;
  padding: 10px;
  border: 1px solid #cff6f6;
  border-radius: 14px;
  margin: 15px;
}

.icon-sup.listing {
  border: 1px solid #cff6f6;
}

.icon-sup.fund {
  border: 1px solid #cff6f6;
}

.icon-sup.pr {
  border: 1px solid #cff6f6;
}

.sup-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.1em;
  color: #25273d;
  text-align: start;
  padding-left: 15px;
}

.sup-list-des {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.1em;
  color: #57597a;
  text-align: start;
}

.box-sub-item.prod:hover {}

.box-sub-item.listing:hover {}

.box-sub-item.fund:hover {}

.box-sub-item.pr:hover {}



@media screen and (max-width: 768px) {
  .title-sup-top {
    font-size: 26px;
  }

  .sup-item {
    padding-top: 30px;
    gap: 10px;
  }

  .icon-sup {
    margin: 0;
  }

  .sup-name {
    padding-left: 0;
  }

  .wrap-box {
    width: 100%;
    flex: none;
  }
}