.banner-apply {
  width: calc(50% - 10px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.banner-top {
  width: calc(50% - 10px);
}

.banner-text-first {
  padding-top: 50px;
}

.wrap-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 100px;
}

span.sec-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 138%;
  text-align: center;

  background: linear-gradient(270deg, #FF9B1B 0%, #FAC668 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

span.first-sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 138%;
  text-align: center;
  color: #25273d;
}

.banner-text-last {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 138.5%;
  text-align: left;
  color: #787a9b;
}

.banner-text-sec {
  padding-top: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 138.5%;
  text-align: left;
  color: #787a9b;
}

.banner-button {
  width: 210px;
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  box-shadow: 0px 10px 20px rgb(255 109 76 / 25%);
  border-radius: 6px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.06em;
  color: #ffffff;
}

.btn-all {
  padding-top: 30px;
  display: flex;
  justify-content: center;
}

.banner-button:hover {
  background: linear-gradient(93.41deg,
      rgba(240, 185, 11, 0.7) -40.87%,
      rgba(235, 0, 41, 0.7) 170.93%);
  box-shadow: 0px 4px 20px rgba(114, 113, 113, 0.17);
  cursor: pointer;
}

.item-dare {
  padding: 7px 1px 7px 4px;
  width: 125px;
  height: 38px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #eef0f6;
  box-shadow: 0px 6px 20px rgb(120 122 155 / 18%);
  border-radius: 35px;
  position: absolute;
  top: 64%;
  left: 4%;
}

.item-9d {
  padding: 7px 1px 7px 4px;
  width: 120px;
  height: 47px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #eef0f6;
  box-shadow: 0px 6px 20px rgb(120 122 155 / 18%);
  border-radius: 35px;
  position: absolute;
  top: 80%;
  left: 15%;
}

.item-mst {
  padding: 7px 1px 7px 4px;
  width: 130px;
  height: 47px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #eef0f6;
  box-shadow: 0px 6px 20px rgb(120 122 155 / 18%);
  border-radius: 35px;
  position: absolute;
  top: 80%;
  left: 51%;
}

.item-runt {
  padding: 7px 1px 7px 4px;
  width: 155px;
  height: 47px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #eef0f6;
  box-shadow: 0px 6px 20px rgb(120 122 155 / 18%);
  border-radius: 35px;
  position: absolute;
  top: 68%;
  left: 73%;
}

.item-dragon {
  padding: 7px 14px 7px 10px;
  width: 150px;
  height: 38px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #eef0f6;
  box-shadow: 0px 6px 20px rgb(120 122 155 / 18%);
  border-radius: 35px;

  position: absolute;
  top: 13%;
  left: 46%;
}

.item-text {
  padding-left: 6px;
}

.item-icon {
  width: 34px;
  height: 34px;
}

.item-lfw {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 14px 7px 10px;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #eef0f6;
  box-shadow: 0px 6px 20px rgb(120 122 155 / 18%);
  border-radius: 35px;
  width: 235px;
  height: 48px;
  position: absolute;
  top: 24%;
  left: 17%;
}

.item-icon.zuki {
  position: absolute;
  top: 24%;
  left: 64%;
  width: 68px;
  height: 54px;
}

.item-icon.zwz {
  position: absolute;
  top: 38%;
  left: 7%;
  width: 70px;
  height: 54px;
}

.item-icon.run {
  position: absolute;
  top: 38%;
  left: 94%;
  width: 70px;
  height: 54px;
}

.item-icon.hug {
  position: absolute;
  top: 55%;
  left: 89%;
  width: 68px;
  height: 54px;
}

.item-icon.mcity {
  position: absolute;
  top: 77%;
  left: 31%;
  width: 80px;
  height: 54px;
}

.item-icon.real {
  position: absolute;
  top: 65%;
  left: 63%;
  width: 80px;
  height: 54px;
}

.item-icon.d {
  position: absolute;
  top: 58%;
  left: 14%;
  width: 80px;
  height: 54px;
}

.item-grbe {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 30px 7px 10px;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid #eef0f6;
  box-shadow: 0px 6px 20px rgb(120 122 155 / 18%);
  border-radius: 35px;
  width: 150px;
  height: 48px;
  position: absolute;
  top: 24%;
  left: 80%;
}


@-webkit-keyframes snowflakes-fall {
  0% {
    top: 90px;
  }

  100% {
    top: 100%;
  }
}

@-webkit-keyframes snowflakes-shake {

  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
}

@keyframes snowflakes-fall {
  0% {
    top: 90px;
  }

  100% {
    top: 100%;
  }
}

@keyframes snowflakes-shake {

  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(80px);
  }
}

.snowflake {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}

.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}

.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
}

.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 1s, 0.5s;
  animation-delay: 1s, 0.5s;
}

.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}

.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
}

.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s;
}

.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s;
}

.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}

.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
}

.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}

.snowflake:nth-of-type(10) {
  left: 25%;
  -webkit-animation-delay: 5s, 0s;
  animation-delay: 5s, 0s;
}

.snowflake:nth-of-type(11) {
  left: 65%;
  -webkit-animation-delay: 4s, 2.5s;
  animation-delay: 4s, 2.5s;
}

.rotate-animation-banner {
  animation: leaves 1s ease-in-out infinite alternate;
  -webkit-animation: leaves 1s ease-in-out infinite alternate;
}

@keyframes leaves {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 768px) {
  .snowflake:nth-of-type(5) {
    left: 10%;
    animation-delay: 8s, 3s;
  }

  .snowflake:nth-of-type(8) {
    left: 50%;
    animation-delay: 1s, 0s;
  }

  .snowflake:nth-of-type(7) {
    left: -10%;
    animation-delay: 2.5s, 1s;
  }

  span.first-sub {
    font-size: 32px;
  }

  span.sec-sub {
    font-size: 32px;
  }

  .banner-text-sec {
    font-size: 18px;
  }

  .item-dragon {
    top: 20%;
    left: 46%;
  }

  .item-9d {
    top: 15%;
    left: 6%;
  }

  .item-mst {
    top: 80%;
    left: 65%;
  }

  .item-dare {
    top: 85%;
    left: 18%;
  }

  .item-icon.mcity {
    top: 75%;
    left: 45%;
  }

  .item-icon.real {
    top: 65%;
    left: 80%;
  }

  .item-icon.d {
    top: 63%;
    left: 0%;
  }

  .item-grbe {
    top: 24%;
    left: 55%;
  }

  .item-icon.zwz {
    top: 23%;
    left: 0%;
  }

  .item-runt {
    top: 75%;
    left: -6%;
  }

  .banner-text-last,
  .banner-text-sec {
    text-align: center;
  }

  .banner-top {
    width: 100%;
  }

  .wrap-banner {
    padding-bottom: 30px;
  }
}