.main-edit-profile {
    padding-top: 130px;
    background-size: contain !important;
    background-position: top !important;
    padding-bottom: 50px;
}
.main-edit-profile .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 138%;
    /* or 55px */

    text-align: center;
    margin-bottom: 7px;
    color: #1E2329;
}
.main-edit-profile .desc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138.5%;
    /* or 22px */
    margin-bottom: 20px;
    text-align: center;

    color: #707A8A;
}
.item-info-edit {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
}
.item-info-edit .round-l {
    width: 50%;
}
.item-info-edit .round-l .title-round {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */
    color: #1E2329;
}
.item-info-edit .round-r {
    width: 50%;
    display: block;
    margin-top: 5px;
}
.item-info-edit .round-r .labelName {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    letter-spacing: 0.01em;
    display: flex;

    color: #1E2329;
}
.item-info-edit .round-r .labelName .label-cus {
    margin-left: auto;
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
/* identical to box height */

letter-spacing: 0.01em;

color: #707A8A;
}
.item-info-edit .round-r .form-group {
    margin-bottom: 0;
}
.item-info-edit {
    margin-bottom: 38px;
}
.item-info-edit .round-l .desc-round {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 145.5%;
    /* or 23px */
    color: #707A8A;
    margin-top: 8px;
}
.item-info-edit .round-l .desc-round img {
    width: 20px;
}
.item-info-edit .round-l .desc-round.cus {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */
    margin-top: 20px;

    color: #1E2329;
}
.item-info-edit .round-r .form-group.cus {
    margin-top: 70px;
}
    
@media screen and (max-width: 768px ) {
    .back-mint.create-info {
        position: fixed;
        left: 10px !important
    }
}
