.btn-connectTon button {
    background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
    padding: 8px;
    font-size: 16px;
    color: #fff;
    border: 0px solid transparent;
    border-radius: 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease;
    height: 42px;
    white-space: nowrap;
}

.btn-connectTon button div {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 0px !important;
}

@media screen and (max-width: 768px) {
    .main-cnt-mobile .filter-chain .ant-select-selector {
        width: 48px !important;
    }

    .main-cnt-mobile .filter-chain {
        width: 48px;
        margin-left: 8px;
    }
}