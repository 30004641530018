.info:hover {
  border: 2px solid #eef0f660;
}

.info {
  width: 1160px;
  padding: 0 10px;
  margin: 0 auto;
  position: relative;
  height: 122px;
  background: #ffffff;
  border: 2px solid #eef0f6;
  box-shadow: 0px 10px 40px rgb(120 122 155 / 15%);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.info-num {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: #25273d;
}

.info-des {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #787a9b;
}

@media screen and (max-width: 768px) {
  .info {
    width: auto;
    padding: 15px 0px 0px 0px;
    margin: 0 15px;
    position: relative;
    background: #ffffff;
    border: 2px solid #eef0f6;
    box-shadow: 0px 10px 40px rgb(120 122 155 / 15%);
    border-radius: 20px;
    display: flex;
    height: auto;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .info-box {
    width: 50%;
    padding-bottom: 25px;
  }

  .info-num {
    font-size: 25px;
    line-height: 45px;
  }
}