.main-megamarket-launch {
  padding-top: 75px;
  /* background-size: contain !important;
  background-position: top !important; */
  padding-bottom: 50px;
  
}
.main-market-launch-banner{
  padding-top: 30px;
  padding-bottom: 0px;
  background: linear-gradient(180deg, #2EDAFE 0%, #FFF 100%);
}
.main-market-launch-banner img{
  max-width: 745px;
  width: 100%;
}
.btn-get-owner{
  border-radius: 6px;
  background: var(--main, linear-gradient(93deg, #FFD75A -40.87%, #EB0029 170.93%));
  color: #fff
}
.btn-get-owner:hover{
  opacity: 0.8;
}
.main-content-market .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 138%;
  /* or 55px */

  text-align: center;

  color: #1e2329;
  margin-bottom: 17px;
}
.main-content-market .desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 138.5%;
  /* or 22px */

  text-align: center;
  margin-bottom: 10px;
  color: #707a8a;
}
.main-content-market .w-140 {
  width: 140px !important;
}
.slider-market-content {
  margin: 0 -10px;
  margin-top: 90px;
}
.slider-market-content .item {
  padding: 10px;
}
.info-megamarket-content {
  margin-top: 80px;
}
.info-megamarket-content .content-info .list-info {
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  background: #f6f7fc;
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */
  position: relative;
  border-radius: 6px;
}

.info-megamarket-content .content-info .list-info li {
  width: calc(2 / 10 * 100%);
  list-style-type: none;
  text-align: center;
  border-left: 1px solid #AAB6CD;
}
.info-megamarket-content .content-info .list-info li .txt-top {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  margin-bottom: 8px;
  letter-spacing: 0.02em;
  text-transform: uppercase;

  color: #7a8195;
}

.info-megamarket-content .content-info .list-info li .txt-bot {
  text-align: center;
}
.info-megamarket-content .content-info .list-info li .txt-bot .number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: #1e2329;
}
.info-megamarket-content .content-info .list-info li:nth-child(1) {
  border-left: none !important;
}
.explore-market-content {
  margin-top: 40px;
  text-align: left;
}
.explore-market-content .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 104%;
  /* or 31px */
  margin-bottom: 40px;
  text-align: left;
  color: #1e2329;
}
.tabs-market .ant-tabs-tab-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #1e2329;
}
.tabs-market .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1e2329;
}
.tabs-market .ant-tabs-ink-bar {
  background: #1e2329;
}
.tabs-market .ant-tabs-tab {
  padding-right: 50px;
}
.tabs-market .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 20px;
}
.w-3 {
  width: calc(3 / 12 * 100%);
}
.tabs-market .round-tabs .guide-market {
  background: #ffffff;
  border: 0.9px solid #e9eff5;
  border-radius: 6px;
  padding: 7px 7px 15px 7px;
  transition: 0.3s ease;
  overflow: hidden;
}
.tabs-market .round-tabs .guide-market .box-img {
  margin-bottom: 15px;
  overflow: hidden;
}
.tabs-market .round-tabs .guide-market .box-img .imgNft-market {
  transform: scale(1);
  transition: 0.3s ease;
  border-radius: 6px;
}
.tabs-market .round-tabs .guide-market .box-img .imgNft-market:hover {
  transform: scale(1.2);
}
.tabs-market .round-tabs .guide-market .name-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  /* identical to box height */
  margin-bottom: 7px;
  transition: 0.3s ease;
  color: #1e2329;
  cursor: pointer;
}
.tabs-market .round-tabs .guide-market .artis-item {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  margin-bottom: 20px;

  color: #1e2329;
}
.tabs-market .round-tabs .guide-market .price-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabs-market .round-tabs .guide-market .price-item .t-right {
  display: flex;
  align-items: center;
}
.tabs-market .round-tabs .guide-market .price-item .t-right .icon {
  margin-right: 5px;
}
.tabs-market .round-tabs .guide-market .price-item .t-right .number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #1e2329;
}
.tabs-market .round-tabs .guide-market .current-price {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  color: #707a8a;
  text-align: right;
}
.load-more-round .btn-loadmore.cus {
  width: 180px !important;
}
.load-more-round {
  text-align: center;
  margin-top: 30px;
}
.main-market-launch-details {
  padding-top: 130px;
  background-size: contain !important;
  background-position: top !important;
  padding-bottom: 50px;
}
.w-6 {
  width: calc(6 / 12 * 100%);
  padding: 10px;
}
.main-market-launch-details .round-details .columns {
  margin: 0 -10px;
}
.main-market-launch-details .round-details .content-l .box-img {
  margin-bottom: 40px;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .info-user-top {
  display: flex;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .info-user-top
  .img-user {
  width: 100px;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .info-user-top
  .text-user
  .social-user {
  display: flex;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .info-user-top
  .text-user
  .social-user
  img {
  width: 20px;
  margin-right: 15px;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .info-user-top
  .text-user {
  text-align: left;
  margin-left: 20px;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .info-user-top
  .text-user
  .social-user {
  margin-bottom: 10px;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .info-user-top
  .text-user
  .name-user {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  letter-spacing: 0.03em;
  margin-bottom: 10px;
  color: #1e2329;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .wallet-user
  .address {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  background: #f6f7fc;
  border-radius: 6px;
  color: #1e2329;
  padding: 5px 10px;
  margin-left: 10px;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .wallet-user
  .address
  .img-copy
  img {
  width: 16px;
}
.main-market-launch-details .round-details .content-l .info-user .wallet-user {
  display: flex;
  align-items: center;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .info-user-top {
  margin-bottom: 20px;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .info-user-bot
  .desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #707a8a;
  text-align: left;
}
.box-info-nft .nft-name {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
}
.box-info-nft .nft-name .icon {
  width: 30px;
  margin-right: 8px;
  height: 30px;
}
.box-info-nft .nft-name .name {
  margin-right: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  /* line-height: 24px; */
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #1e2329;
}
.box-info-nft .nft-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  padding-bottom: 8px;
  text-align: left;
  color: #1e2329;
  border-bottom: 0.5px solid #d7d7d7;
  margin-bottom: 8px;
}
.box-info-nft {
  text-align: left;
}
.box-info-nft .nft-artis {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;
  margin-bottom: 13px;
  color: #707a8a;
}
.box-info-nft .nft-artis .approve-artis {
  display: block;
  margin-top: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  width: 70px;
  text-align: center;
  letter-spacing: 0.01em;
  background: rgba(27, 224, 104, 0.2);
  border-radius: 37px;
  color: #0cb74f;
  padding: 5px 10px;
}
.nft-description {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  margin-bottom: 50px;
  letter-spacing: 0.01em;

  color: #707a8a;
}
.nft-description .desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  margin-top: 5px;
  color: #707a8a;
  display: block;
}
.nft-current-price .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  margin-bottom: 8px;
  letter-spacing: 0.01em;

  color: #707a8a;
}
.price-line {
  display: flex;
  align-items: center;
}
.price-line .icon {
  width: 28px;
  margin-right: 10px;
}
.price-line .number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 138.5%;
  /* identical to box height, or 36px */

  color: #1e2329;
}
.price-line .number .name-token {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #707a8a;
  margin-left: 2px;
}
.price-now {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  margin-top: 5px;
  color: #707a8a;
}
.btn.btn-approve {
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.06em;

  color: #ffffff;
}
.nft-button {
  margin-top: 17px;
  margin-left: -10px;
}
.box-nft-swap {
  text-align: left;
  margin-top: 70px;
}
.box-nft-swap .item-swap .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.03em;
  color: #1e2329;
  border-bottom: 0.5px solid #d7d7d7;
  margin-bottom: 16px;
  padding-bottom: 11px;
}
.box-nft-swap .item-swap .list-swap {
  margin-bottom: 0;
  padding-left: 0;
}
.box-nft-swap .item-swap .list-swap li {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}
.box-nft-swap .item-swap .list-swap li:last-child {
  padding-bottom: 0;
}
.box-nft-swap .item-swap .list-swap li .txt-l {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #707a8a;
}
.box-nft-swap .item-swap .list-swap li .txt-r {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 0.03em;

  color: #1e2329;
}
.box-nft-swap .item-swap .list-swap li .txt-r img {
  width: 15px;
}
.box-nft-swap .item-swap {
  margin-bottom: 35px;
}
.box-nft-swap .item-swap .list-media {
  margin-bottom: 0;
  padding-left: 0;
}
.box-nft-swap .item-swap .list-media li {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;

  color: #707a8a;
  list-style-type: none;
  padding-bottom: 5px;
}
.box-nft-swap .item-swap .list-media li:last-child {
  padding-bottom: 0px;
}
.round-details .content-r {
  padding-left: 30px;
}
.font-12 {
  font-size: 12px !important;
}
.imgNft-market {
  height: 235px;
}
.imgType {
  padding: 3px;
  border-radius: 6px;
  position: absolute;
  z-index: 3;
  top: 5px;
  left: 5px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 5px;
}
/* .guide-market :hover {
  cursor: pointer;
} */
.icon-smail {
  width: 18px;
  height: 18px;
}
.nft-name :hover {
  cursor: pointer;
  text-decoration: underline;
}
.explore-market-content .tabs-market {
  position: relative;
}
.swap-filter {
  display: flex;
  align-items: center;
}
.explore-market-content .tabs-market .search-project {
  margin-bottom: 0;
}
.explore-market-content .tabs-market .swap-filter {
  display: flex;
  align-items: center;
  width: 43%;
  margin-left: auto;
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 999;
  justify-content: flex-end;
}
.buy-au {
  display: flex;
}
.info-megamarket-content .content-info .list-info li .txt-bot .number .anticon svg {
  display: inline-block;
  fill: #f77c45;
}
button.btn-bidding {
  border-radius: 12px;
  border: none;
  background: #20dfa8;
  width: 73px;
  color: #fff;
  font-size: 12px;
}
.btn-bidding:hover {
  cursor: pointer;
}
button.btn-bidding:disabled {
  cursor: not-allowed;
  background: #707a8a;
}
.btn-approve:disabled {
  cursor: not-allowed;
  background: #707a8a;
}

.main-market-launch-details .round-details .content-l .box-img img {
  height: 453px !important;
  object-fit: scale-down;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .info-user-top
  .img-user
  img {
  height: 90px;
  object-fit: cover;
  border-radius: 6px;
  display: block;
}
.main-market-launch-details
  .round-details
  .content-l
  .info-user
  .info-user-top
  .img-user {
  padding: 5px;
  height: 100px;
  width: 100px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.5);
}


@media screen and (max-width: 768px) {
  .item-fill .ant-collapse-borderless{
  background: #fff;
  height: auto;
  max-height: 400px !important;
}
  .tabs-market .ant-tabs-content-holder {
    padding-top: 90px;
  }
  .target-project {
    margin-left: 5px;
  }
  .main-content-market .title {
    font-size: 26px;
    margin-bottom: 0px !important;
  }
  .main-content-market .desc {
    font-size: 14px;
  }
  .button-ino {
    margin: 20px 0;
    justify-content: center;
  }
  .slider-market-content {
    margin-top: 30px;
  }
  .info-megamarket-content{
    margin-top: 30px !important;
  }
  .info-megamarket-content .content-info .list-info li {
    width: 47%;
    margin: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    border-left: none !important;
    background: #f6f7fc;
  }
  
  .info-megamarket-content .content-info .list-info:before {
    display: none;
  }
  
  .info-megamarket-content .content-info .list-info li .txt-bot .number {
    font-size: 16px;
  }
  .info-megamarket-content .content-info .list-info {
    background: transparent;
    padding: 0px;
    justify-content: center;
  }
  .explore-market-content .tabs-market .swap-filter {
    position: relative;
    right: 0;
    /* top: 110px !important; */
    width: 100%;
  }
  .search-project .ant-input-group .ant-input {
    width: 100% !important;
  }
  .explore-market-content .tabs-market .search-project {
    margin-bottom: 0;
    width: 100% !important;
  }
  .w-3 {
    width: 100% !important;
  }
  .imgNft-market {
    height: auto;
    max-height: 350px !important;
    object-fit: cover;
  }
  .tabs-market .round-tabs .guide-market .name-item {
    font-size: 14px;
    margin-bottom: 3px;
    line-height: 1.6;
  }
  .tabs-market .round-tabs .guide-market .artis-item {
    font-size: 12px;
    margin-bottom: 3px;
  }
  .tabs-market .round-tabs .guide-market .price-item {
    display: block;
  }
  .tabs-market .round-tabs .guide-market .price-item .t-left {
    font-size: 12px;
  }
  .tabs-market .round-tabs .guide-market .price-item .t-top {
    font-size: 12px;
    margin-top: 10px;
  }
  .tabs-market .round-tabs .guide-market .price-item .t-right .icon img {
    width: 18px;
  }
  .tabs-market .round-tabs .guide-market .price-item .t-right .number {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: -5px;
  }
  .tabs-market .round-tabs .guide-market .price-item .t-right {
    margin-top: 5px;
  }
  .w-6 {
    width: 100%;
  }
  .main-market-launch-details
    .round-details
    .content-l
    .info-user
    .info-user-top
    .text-user
    .name-user {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .main-market-launch-details
    .round-details
    .content-l
    .info-user
    .wallet-user {
    display: block;
  }
  .main-market-launch-details
    .round-details
    .content-l
    .info-user
    .wallet-user
    .address {
    margin-left: 0;
    margin-top: 5px;
  }
  .round-details .content-r {
    padding-left: 0;
    padding-top: 10px;
  }
  .box-nft-swap {
    margin-top: 40px;
  }
}
/* .tabs-market .round-tabs .guide-market:hover .name-item {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
} */
.main-content-market .slick-dots li.slick-active button:before {
  color: #eb0029;
  font-size: 10px;
}
.main-content-market .slick-dots li button:before {
  font-size: 10px;
}
.main-content-market .slick-dots li button:hover:before {
  color: #eb0029;
  font-size: 10px;
}
.main-content-market .slick-dots {
  bottom: -25px;
}
.breadcrum-swap .list-breadcrum {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
.breadcrum-swap .list-breadcrum li a {
  list-style-type: none;
  padding-right: 10px;
  color: #707a8a;
}
.breadcrum-swap .list-breadcrum li:last-child {
  color: #1e2329;
}
.breadcrum-swap.cus {
  margin-bottom: 10px;
}
.nft-empty {
  width: 100%;
  padding-top: 40px;
}
.nft-loading .ant-spin-spinning {
  display: flex !important;
  justify-content: center;
}
.nft-loading {
  width: 100%;
}
.spin-detail {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip-nft .ant-tooltip-inner {
  display: flex;
  justify-content: center;
  background: #1e2329;
  /* color: #fff; */
  /* -webkit-background-clip: padding-box !important; */
  -webkit-text-fill-color: #faad14 !important;
  border-radius: 12px;
}
.marquee-nft {
  height: 25px;
  /* width: 420px; */

  overflow: hidden;
  position: relative;
}

.marquee-nft div {
  display: block;
  width: 100%;
  height: 30px;

  position: absolute;
  overflow: hidden;
}

/* .marquee-nft span {
  float: left;
  width: 50%;
} */
.tabs-market .round-tabs .guide-market:hover .marquee-nft div {
  animation: marquee-nft 5s linear infinite;
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
/* @keyframes marquee-nft {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
} */

.mobie-nft.swap-filter {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between !important;
  position: absolute !important;
  right: 0px !important;
  top: 60px !important;
  width: 100% !important;
}
.mobie-nft .search-project {
  padding-bottom: 5px;
  margin-left: 0px !important;
}
.mobie-nft .target-project {
  margin-left: 0px !important;
  width: 30%;
}
.mobie-nft .target-project .btn-filter {
  width: 100%;
}
.mobie-nft .filter-project {
  width: 65%;
}
.mobie-nft .filter-project .ant-select {
  width: 100% !important;
}
.mobie-nft .filter-project .ant-select .ant-select-selector {
  width: 100%;
}
.box-img:hover {
  cursor: pointer;
}
/* .artis-item:hover {
  cursor: pointer;
} */
/* .price-item:hover {
  cursor: pointer;
} */
.current-price:hover {
  cursor: pointer;
}
.nft-skeleton {
  border-radius: 6px;
  padding-top: 10px;
}
.slider-market-content .box-img {
  overflow: hidden;
}
.slider-market-content .box-img:hover img {
  transform: scale(1.1);
}
.slider-market-content .box-img img {
  transition: 0.3s ease;
}
.modal-fillter {
    background: #ffffff;
    height: 216px;
    width: 25%;
    text-align: left;
    position: absolute;
    z-index: 1;
    left: 75%;
}
.round-tabs {
    position: relative;
}
.item-fill .ant-collapse-header-text{
  color: #1E2329;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.item-fill .ant-collapse-header{
flex-direction: row-reverse;
}
.item-fill .anticon.anticon-right.ant-collapse-arrow{
  background: black;
    border-radius: 99px;
    padding: 3px;
    margin-right: 0px
}
.item-fill .anticon svg {
    display: inline-block;
    font-size: 11px;
    color: #fff;
}
.item-fill .ant-collapse-borderless{
  background: #fff;
  height: auto;
  max-height: 600px;
}
.item-fill{
  overflow-y: scroll;
}
.item-fill .ant-collapse-item{
  border-bottom: none !important
}
.title-fill {
    color: #707A8A;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.content-fillter {
    padding: 0 10px;
    background: #fff;
}
.item-fill .ant-checkbox-group{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.item-fill .ant-checkbox-inner{
  border-radius: 4px;
}
.item-fill .ant-checkbox-group-item{
    color: #1E2329;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.item-fill .ant-checkbox-checked .ant-checkbox-inner{
background-color: #1E2329;
border-color:#1E2329;
}
.ant-checkbox-checked:after{
  border: 1px solid #1E2329;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner{
  border-color:#1E2329;
}
.drawer-mobile.filter-nft .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex;
}
.drawer-mobile.filter-nft .ant-collapse {
height: 600px;
}