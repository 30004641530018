.top-title {
  font-weight: 500;
  font-size: 40px;
  line-height: 104%;
  text-align: center;
  color: #25273d;
}

.top-ath {
  padding-top: 60px;
}

.icon-chart {
  width: 40px;
}

.chart1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 31px;
}

.chart-name {
  letter-spacing: 0.1em;
  color: #25273d;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: start;
  padding-left: 30px;
  width: 20%;
}

.chart-process {
  /* background: #d0cde4;*/
  background: linear-gradient(90deg, #FFD75A 0%, #FCEFB6 100%);
  border-radius: 20px;
  height: 30px;
  padding-left: 30px;
}

.chart-process.item1 {
  width: 60%;
}

.chart-process.item2 {
  width: 45%;
}

.chart-process.item3 {
  width: 43%;
}

.chart-process.item4 {
  width: 33%;
}

.chart-process.item5 {
  width: 30%;
}

.chart-process.item6 {
  width: 25%;
}

.chart-process.item7 {
  width: 15%;
}

.chart-process.item8 {
  width: 13%;
}

.chart-process.item9 {
  width: 8%;
}

.chart-process.item10 {
  width: 6%;
}

.num-ath {
  padding-left: 25px;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.1em;
  color: #25273d;
}

.chart-ath {
  padding-top: 30px;
  padding-left: 30px;
  position: relative;
}

.bet-icon {
  width: 374px;
  height: 430px;
  position: absolute;
  top: 43%;
  left: 60%;
}

@media screen and (max-width: 768px) {
  .chart1 {
    padding-top: 15px;
  }

  .top-ath {
    padding-top: 50px;
  }

  .top-title {
    font-size: 26px;
    padding: 0 30px 0 30px;
  }

  .chart-ath {
    padding-top: 5px;
    padding-left: 15px;
  }

  .bet-icon {
    display: none;
  }

  .chart-name {
    font-size: 12px;
    padding-left: 10px;
  }

  .num-ath {
    display: none;
  }

  .chart-name {
    width: 35%;
  }

  .chart-process {
    height: 20px;
  }

  .icon-chart {
    width: 9%;
  }

  .chart-process.item9 {
    width: 10%;
  }
}