.partners {
  margin-top: 100px;
  /* background: #f9fafe; */
  background: #FFFFFF;
  padding-top: 100px;
  padding-bottom: 50px;
}

.part-title {
  font-weight: 500;
  font-size: 40px;
  line-height: 104%;
  /* identical to box height, or 42px */

  text-align: center;
  padding-bottom: 20px;
  color: #25273d;
}

.part-des {
  font-weight: 400;
  font-size: 20px;
  line-height: 138.5%;
  /* identical to box height, or 28px */

  text-align: center;

  color: #787a9b;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
}

.game-list-item {
  display: flex;
  justify-content: center;
  grid-gap: 24px;
  gap: 24px;
  padding-bottom: 24px;
  flex-wrap: wrap;
  width: 1160px;
  padding: 0 10px;
  margin: 0 auto;
}

/* .game-list-item {
  display: flex;
  justify-content: center;
  gap: 24px;
  padding-bottom: 24px;
} */
.game-item {
  background: #ffffff;
  box-shadow: 10px 20px 40px rgb(208 208 208 / 25%);
  border-radius: 6px;
  /* width: 146.11px; */
  width: 12.85%;
  height: 61.66px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}

.bscs-gamefi {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #7a8195;
  padding-top: 20px;
  padding-bottom: 15px;
}

.custom-pa-icon {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .partners {
    margin-top: 40px;
    padding-top: 40px;
  }

  .part-title {
    font-size: 26px;
  }

  .part-des {
    font-size: 16px;
  }

  .game-list-item {
    gap: 10px;
    flex-wrap: wrap;
    width: 100%;
  }

  .game-item {
    width: 25%;
    height: 40px;
    padding: 5px 20px;
  }

  .custom-pa-icon {
    width: 30px;
    height: 30px;
  }
}

.launchpad-container {}

.launchpad-marquee-container {
  * {
    margin: 0;
    padding: 0;
  }

  padding: 16px;
  border-radius: 16px;
  background: #f8f9fb;
  max-width: 1166px;
  margin: 64px auto 0 auto;

  @media (max-width: 768px) {
    margin: 16px;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .new-title {
      font-family: Poppins;
      font-weight: 600;
      font-size: 18px;
      line-height: 35px;
      letter-spacing: 10%;
      text-transform: uppercase;
      color: #25273d;
    }

    .new-value {
      font-family: Poppins;
      font-weight: 600;
      font-size: 20px;
      line-height: 138%;
      letter-spacing: 0%;
      background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .custom-marquee-partner {
    margin-top: 14px;

    .marquee-item {
      margin: 0 12px;
      border-radius: 6px;
      background: #ffffff;
      width: 146px;
      height: 61px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 10px 20px 40px 0px #d0d0d040;
      cursor: pointer;
      transition: 0.3s ease;

      &:hover {
        background: gray;
      }

      img {
        width: auto;
        height: auto;
      }
    }
  }
}

.launchpad-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 1166px;
  margin: 0 auto;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0;
    padding: 0 16px;
  }

  .launchpad-marquee-container.new-item {
    margin-top: 20px;
    max-width: calc(50% - 10px);
    flex: 1;

    @media (max-width: 768px) {
      max-width: 100%;
      margin-top: 12px;
    }
  }
}