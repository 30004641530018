.out-com {
  padding-bottom: 90px;
  height: auto;
  background: linear-gradient(179.53deg,
      #f9fafe 0.41%,
      rgba(249, 250, 254, 0.631875) 26.85%,
      rgba(249, 250, 254, 0.7) 70.77%,
      #ffffff 97.6%);
}

.title-des {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 104%;
  padding-bottom: 10px;
  text-align: start;
  color: #25273d;
}

.content-des {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 138.5%;
  text-align: start;

  color: #787a9b;
}

.com-inf {
  /* width: calc(6 / 12 * 100%);
  display: flex;
  flex-direction: column;
  justify-content: center; */
  width: calc(50% - 0px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.com-all {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
}

.icon-social {
  width: 60px;
  height: 60px;
  background: rgba(3, 169, 244, 0.1);
  border-radius: 6px;
  padding: 8px;
}

.item-social {
  display: flex;
  align-items: center;
}

.inf-social {
  padding-left: 15px;
  padding: 10px;
}

.inf-social .num {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  display: flex;
  align-items: center;

  color: #25273d;
}

.inf-social .des-num {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: start;
  color: #787a9b;
}

.social-left {

  width: calc(50% - 10px);
  display: flex;
  /* padding-right: 30px; */
}

.item-social {
  /* display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 6px 20px rgb(120 122 155 / 18%);
  border-radius: 6px;
  height: 87px;
  width: 80%;
  padding: 15px;
  margin-top: 20px; */
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 6px 20px rgb(120 122 155 / 18%);
  border-radius: 6px;
  /* padding-left: 5px; */
  height: 87px;
  /* width: 95%; */
  padding: 15px;
  margin-top: 20px;
  margin-right: 20px;
}

.icon-social.yt {
  background: rgba(244, 79, 90, 0.1);
  border-radius: 6px;
}

.icon-social .fb {
  background: rgba(9, 131, 223, 0.1);
  border-radius: 6px;
}

.item-social:hover {
  background: rgb(255 255 255 / 0%);
}

@media screen and (max-width: 768px) {
  .title-des {
    font-size: 26px;
  }

  .com-all {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .com-inf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .com-inf {
    align-items: center;
  }

  .out-com {
    padding-bottom: 50px;
    height: auto;
  }

  .content-des {
    text-align: center;
    font-size: 16px;
  }

  .inf-social {
    padding: 10px 10px 10px 25px;
  }

  .item-social {
    margin-right: 0px;
    width: 90%;
  }
}