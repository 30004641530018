.main-banner {
  text-align: center;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 82px;
}
.container {
  width: 1160px;
  padding: 0 10px;
  margin: 0 auto;
  position: relative;
}
.content-banner {
  padding: 88px 0 10px 0;
}
.content-banner .title {
  color: #1e2329;
  font-size: 40px;
  line-height: 55px;
  font-weight: 500;
  margin-bottom: 15px;
  letter-spacing: 1px;
}
.content-banner .desc {
  color: #616772;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  margin-bottom: 50px;
}
.chain-swap.new {
  display: flex;
  justify-content: center !important;
}
.btn {
  border: 0px solid transparent;
  font-size: 15px;
  padding: 13px 30px;
  border-radius: 6px;
  margin: 0 10px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
}
.btn-upcoming {
  background: #1e2329;
  color: #fff;
  transition: 0.3s ease;
}
.btn-upcoming:hover {
  background: #3f4a57;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
}
.btn-trade {
  background: #ffffff;
  color: #1e2329;
  transition: 0.3s ease;
}
.btn-trade:hover {
  color: #707a8a;
}
.get-button {
  margin-bottom: 58px;
}
.type {
  color: #7a8195;
  font-size: 12px;
}
.main-setion {
  padding: 40px 0;
}
.main-chain .chain-swap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}
.main-chain .chain-swap .box-img {
  /* width: 140px; */
  height: auto;
}
.columns {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.colum {
  padding: 8px;
}
.w-25 {
  width: 25%;
  height: 100%;
}
.w-20 {
  width: 20%;
  height: 100%;
}
.content-raise .title {
  color: #7a8195;
  font-size: 12px;
  margin-bottom: 10px;
}
.content-raise .number-raise {
  color: #3f4a57;
  font-size: 30px;
  font-weight: 600;
}
.content-raise {
  padding: 27px 0;
  border-radius: 6px;
}
.table-raise {
  padding: 60px 0 80px 0;
}
.table-raise .w-25:nth-child(1) .content-raise {
  background: linear-gradient(206.08deg, #fdf0f7 -4.04%, #fff6f1 106.7%);
}
.table-raise .w-25:nth-child(2) .content-raise {
  background: linear-gradient(200.25deg, #f7ffff -2.56%, #e0feff 104.96%);
}
.table-raise .w-25:nth-child(3) .content-raise {
  background: linear-gradient(203.97deg, #fff7ec 10.1%, #fdf2d2 106.19%);
}
.table-raise .w-25:nth-child(4) .content-raise {
  background: linear-gradient(0deg, #eff3f8, #eff3f8),
    linear-gradient(206.63deg, #eff3fe -0.03%, #fbeffb 118.82%);
}
.item-slider {
  margin-top: 36px;
  width: 100%;
  padding: 0 10px;
}
.slider-chain {
  width: 100%;
}
.slider-chain .slick-slider {
  margin: 0 -10px;
}
.slider-chain .slick-dots li.slick-active button:before {
  color: #eb0029;
  font-size: 10px;
}
.slider-chain .slick-dots li button:before {
  font-size: 10px;
}
.slider-chain .slick-dots li button:hover:before {
  color: #eb0029;
  font-size: 10px;
}
.slider-chain .slick-dots {
  bottom: -35px;
}
.main-title .title {
  font-size: 30px;
  color: #1e2329;
  font-weight: 600;
}
.main-title .desc {
  color: #7a8195;
  font-size: 12px;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.main-title {
  margin-bottom: 25px;
}
.w-100-5 {
  width: 20%;
}
.content-stack {
  padding: 15px;
  border: 1px solid #eff3f8;
  border-radius: 6px;
  text-align: left;
  height: 100%;
  position: relative;
}
.content-stack .box-icon {
  width: 50px;
  height: auto;
  margin-bottom: 20px;
}
.content-stack .title {
  color: #1e2329;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-weight: 600;
}
.content-stack .desc {
  color: #474d57;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 60px;
  font-weight: 300;
}
.btn-getstarted {
  background: transparent;
  border: 0px solid transparent;
  color: #1e2329;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease;
}
.content-stack .btn-getstarted {
  background: transparent;
  border: 0px solid transparent;
  color: #1e2329;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s ease;
  position: absolute;
  bottom: 15px;
}
.btn-getstarted:hover {
  text-decoration: underline;
}
.content-upcoming {
  padding: 8px;
  border: 1px solid #eff3f8;
  border-radius: 6px;
  text-align: left;
  overflow: hidden;
}
.content-upcoming .box-img .img-big {
  margin-bottom: 12px;
  position: relative;
  /* max-width: 324px; */
}
.content-upcoming .box-img .img-big .icon-img {
  position: absolute;
  top: 12px;
  left: 12px;
  width: 28px;
  height: 28px;
}
.content-upcoming .box-img .img-big .allow-open {
  position: absolute;
  top: 12px;
  right: 12px;
  color: #fff;
  font-size: 12px;
  background: #07e0e0;
  padding: 4px 8px;
  border-radius: 4px;
}
.content-upcoming .box-img .img-big img {
  display: block;
}
.content-upcoming .box-img .img-small {
  /* width: 122px; */
  /* height: auto; */
  z-index: 10;
  position: absolute;
  bottom: -40px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  padding: 5px;
  background: #fff;
  border-radius: 99px;
  /* margin: 0 auto 10px; */
  box-shadow: 0px 0px 5px rgb(0 0 0 / 10%);
  width: 80px;
}
.content-upcoming .box-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 45px;
}
.content-upcoming .box-title .title {
  color: #1e2329;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.content-upcoming .box-title .number {
  color: #fff;
  background: #1a96a0;
  padding: 4px 8px;
  border-radius: 4px;
}
.content-upcoming .desc {
  color: #707a8a;
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  max-width: 100%;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.content-upcoming .list-raised {
  margin-bottom: 18px;
  padding-left: 0;
}
.content-upcoming .list-raised li {
  list-style-type: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-upcoming .list-raised li .left {
  color: #707a8a;
  font-size: 12px;
  font-weight: 600;
}
.content-upcoming .list-raised li .right {
  color: #1a96a0;
  font-weight: 600;
  font-size: 14px;
}
.content-upcoming .title-upcoming-sale {
  font-size: 14px;
  color: #1a96a0;
  font-weight: 600;
  text-align: center;
  padding: 9px 0;
  background: rgba(26, 150, 160, 0.1);
  margin: -8px;
  border-radius: 0 0 6px 6px;
}
.table-upcoming .columns {
  justify-content: center;
}
.table-upcoming .colum:nth-child(2) .content-upcoming .box-title .number {
  background: #627eea;
}
.table-upcoming .colum:nth-child(3) .content-upcoming .box-title .number {
  background: #627eea;
}
.table-upcoming .colum:nth-child(2) .content-upcoming .list-raised li .right {
  color: #627eea;
}
.table-upcoming .colum:nth-child(3) .content-upcoming .list-raised li .right {
  color: #627eea;
}
.table-upcoming .colum:nth-child(2) .content-upcoming .title-upcoming-sale {
  color: #627eea;
  background: rgba(98, 126, 234, 0.1);
}
.table-upcoming .colum:nth-child(3) .content-upcoming .title-upcoming-sale {
  color: #627eea;
  background: rgba(98, 126, 234, 0.1);
}
.btn-show-more {
  width: 210px;
  margin: auto;
  margin-top: 40px;
  background: #f3f3f4;
  font-size: 15px;
  transition: 0.3s ease;
}
.btn-show-more:hover {
  background: #e7e7e9;
  border-radius: 6px;
  color: #707a8a;
}
.table-project-raise .w-10 {
  /* width: 12.5%; */
  padding: 10px;
  margin: auto;
}
.table-project-raise .columns {
  margin: 0;
}
.box-ido-raise {
  display: flex;
  align-items: center;
  /* width: fit-content; */

  padding: 10px;
  border-radius: 99px;
  background: #fff;
  /* box-shadow: 0px 4px 20px #000; */
  box-shadow: 0px 4px 20px rgba(71, 77, 87, 0.1);
}
.table-project-raise .columns .marquee-container {
  overflow-x: inherit !important;
}
.box-ido-raise .icon-raise {
  margin-right: 10px;
  width: 28px;
}
.box-ido-raise .name-raise {
  color: #1e2329;
  font-size: 12px;
  margin-right: 10px;
}
.box-ido-raise .number-raise {
  color: #0cb74f;
  padding: 4px 8px;
  background: rgba(27, 224, 104, 0.2);
  border-radius: 25px;
}
.table-project-raise .columns .colum:nth-child(9) .box-ido-raise {
  margin-left: 25px;
}
.table-project-raise .columns .colum:nth-child(10) .box-ido-raise {
  margin-left: 25px;
}
.table-project-raise .columns .colum:nth-child(11) .box-ido-raise {
  margin-left: 25px;
}
.table-project-raise .columns .colum:nth-child(12) .box-ido-raise {
  margin-left: 25px;
}
.table-project-raise .columns .colum:nth-child(13) .box-ido-raise {
  margin-left: 25px;
}
.table-project-raise .columns .colum:nth-child(14) .box-ido-raise {
  margin-left: 25px;
}
.table-project-raise .columns .colum:nth-child(15) .box-ido-raise {
  margin-left: 25px;
}
.table-project-raise .columns .colum:nth-child(16) .box-ido-raise {
  margin-left: 25px;
}
.row-funded .columns {
  max-width: 660px;
  width: 100%;
  margin: 0 auto;
}
.w-33 {
  width: calc(4 / 12 * 100%);
}
.content-funded-pj {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #eff3f8;
  padding: 10px;
  border-radius: 6px;
}
.content-funded-pj .content-left .box-icon {
  width: fit-content;
  padding: 13px;
  border-radius: 6px;
  background: linear-gradient(
    132.84deg,
    rgba(255, 226, 151, 0.2) -9.96%,
    rgba(193, 148, 31, 0.2) 117.42%
  );
}
.content-funded-pj .content-left .box-icon img {
  width: 24px;
}
.content-funded-pj .content-right {
  width: calc(100% - 58px);
  margin-left: auto;
  text-align: left;
}
.content-funded-pj .content-right .title {
  margin-bottom: 0;
  color: #787a9b;
  font-weight: 500;
  font-size: 12px;
}
.content-funded-pj .content-right .number-funded {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600;
  color: #25273d !important;
}
.row-funded .colum:nth-child(2) .content-funded-pj .content-left .box-icon {
  background: linear-gradient(
    132.84deg,
    rgba(138, 233, 235, 0.2) -9.96%,
    rgba(30, 173, 174, 0.2) 117.42%
  );
}
.row-funded .colum:nth-child(3) .content-funded-pj .content-left .box-icon {
  background: linear-gradient(
    132.84deg,
    rgba(250, 159, 252, 0.2) -9.96%,
    rgba(237, 19, 241, 0.2) 117.42%
  );
}
.row-funded
  .colum:nth-child(2)
  .content-funded-pj
  .content-right
  .number-funded {
  color: #66d8db;
}
.row-funded
  .colum:nth-child(3)
  .content-funded-pj
  .content-right
  .number-funded {
  color: #de9ffc;
}

.content-row-funded {
  padding: 8px;
  border: 1px solid #eff3f8;
  border-radius: 6px;
  text-align: left;
  overflow: hidden;
}
.content-row-funded .box-img .img-big {
  margin-bottom: 12px;
  position: relative;
  margin: -8px;
}
.content-row-funded .box-img .img-big .icon-img {
  position: absolute;
  top: 14px;
  left: 23px;
  width: 28px;
  height: 28px;
}
.content-row-funded .box-img .img-big .allow-open {
  position: absolute;
  top: 12px;
  right: 12px;
  color: #fff;
  font-size: 12px;
  background: #1be068;
  padding: 4px 8px;
  border-radius: 4px;
}
.content-row-funded .box-img .img-big img {
  display: block;
  height: 175px;
}
.content-row-funded .box-img .img-big .icon-img img {
  height: auto;
}
.content-row-funded .box-img .img-small {
  width: 70px;
  height: 70px;
  /* height: auto; */
  margin: 0 auto;
}
.content-row-funded .box-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.content-row-funded .box-title .title {
  color: #1e2329;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}
.content-row-funded .box-title .number {
  color: #fff;
  background: #1a96a0;
  padding: 4px 8px;
  border-radius: 4px;
}
.content-row-funded .desc {
  color: #707a8a;
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  max-width: 100%;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
  height: 60px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.content-row-funded .list-raised {
  margin-bottom: 18px;
  padding-left: 0;
}
.content-row-funded .list-raised li {
  list-style-type: none;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-row-funded .list-raised li .left {
  color: #707a8a;
  font-size: 12px;
  font-weight: 600;
}
.content-row-funded .list-raised li .right {
  color: #1a96a0;
  font-weight: 600;
  font-size: 14px;
}
.content-row-funded .title-upcoming-sale {
  font-size: 14px;
  color: #1a96a0;
  font-weight: 600;
  text-align: center;
  padding: 9px 0;
  background: rgba(26, 150, 160, 0.1);
  /* margin: -8px; */
  border-radius: 6px;
}
.table-upcoming .colum:nth-child(2) .content-row-funded .box-title .number {
  background: #627eea;
}
.table-upcoming .colum:nth-child(3) .content-row-funded .box-title .number {
  background: #627eea;
}
.table-upcoming .colum:nth-child(2) .content-row-funded .list-raised li .right {
  color: #627eea;
}
.table-upcoming .colum:nth-child(3) .content-row-funded .list-raised li .right {
  color: #627eea;
}
.table-upcoming .colum:nth-child(2) .content-row-funded .title-upcoming-sale {
  color: #627eea;
  background: rgba(98, 126, 234, 0.1);
}
.table-upcoming .colum:nth-child(3) .content-row-funded .title-upcoming-sale {
  color: #627eea;
  background: rgba(98, 126, 234, 0.1);
}
.slide-row-funded {
  /* margin-top: 40px; */
  position: relative;
}
.content-row-funded .box-img {
  position: relative;
  margin-bottom: 40px;
}
.content-row-funded .box-img .img-small {
  z-index: 10;
  position: absolute;
  bottom: -35px;
  left: 50%;
  transform: translateX(-50%);
  padding: 3px;
  background: #fff;
  border-radius: 99px;
  display: flex;
  align-items: center;
  box-shadow: 0px 6px 20px rgb(198 198 198 / 79%);
}
.slide-row-funded
  .slick-slide:nth-child(4)
  .item-slider
  .content-row-funded
  .box-title
  .number {
  background: #627eea;
}
.slide-row-funded
  .slick-slide:nth-child(4)
  .item-slider
  .content-row-funded
  .list-raised
  li
  .right {
  color: #627eea;
}
.slide-row-funded
  .slick-slide:nth-child(4)
  .item-slider
  .content-row-funded
  .title-upcoming-sale {
  color: #627eea;
  background: rgba(98, 126, 234, 0.1);
}
.slide-row-funded
  .slick-slide:nth-child(5)
  .item-slider
  .content-row-funded
  .box-title
  .number {
  background: #e2b100;
}
.slide-row-funded
  .slick-slide:nth-child(5)
  .item-slider
  .content-row-funded
  .list-raised
  li
  .right {
  color: #e2b100;
}
.slide-row-funded
  .slick-slide:nth-child(5)
  .item-slider
  .content-row-funded
  .title-upcoming-sale {
  color: #e2b100;
  background: rgba(240, 185, 11, 0.1);
}
.slide-row-funded
  .slick-slide:nth-child(6)
  .item-slider
  .content-row-funded
  .box-title
  .number {
  /* background: #8f5ae8; */
}
.slide-row-funded
  .slick-slide:nth-child(6)
  .item-slider
  .content-row-funded
  .list-raised
  li
  .right {
  /* color: #8f5ae8; */
}
.slide-row-funded
  .slick-slide:nth-child(6)
  .item-slider
  .content-row-funded
  .title-upcoming-sale {
  /* color: #8f5ae8;
  background: rgba(143, 90, 232, 0.1); */
}
/* render color */

.slide-row-funded
  .slick-slide:nth-child(7)
  .item-slider
  .content-row-funded
  .box-title
  .number {
  background: #627eea;
}
.slide-row-funded
  .slick-slide:nth-child(7)
  .item-slider
  .content-row-funded
  .list-raised
  li
  .right {
  color: #627eea;
}
.slide-row-funded
  .slick-slide:nth-child(7)
  .item-slider
  .content-row-funded
  .title-upcoming-sale {
  color: #627eea;
  background: rgba(98, 126, 234, 0.1);
}
.slide-row-funded
  .slick-slide:nth-child(8)
  .item-slider
  .content-row-funded
  .box-title
  .number {
  background: #e2b100;
}
.slide-row-funded
  .slick-slide:nth-child(8)
  .item-slider
  .content-row-funded
  .list-raised
  li
  .right {
  color: #e2b100;
}
.slide-row-funded
  .slick-slide:nth-child(8)
  .item-slider
  .content-row-funded
  .title-upcoming-sale {
  color: #e2b100;
  background: rgba(240, 185, 11, 0.1);
}
.slide-row-funded
  .slick-slide:nth-child(9)
  .item-slider
  .content-row-funded
  .box-title
  .number {
  /* background: #8f5ae8; */
}
.slide-row-funded
  .slick-slide:nth-child(9)
  .item-slider
  .content-row-funded
  .list-raised
  li
  .right {
  /* color: #8f5ae8; */
}
.slide-row-funded
  .slick-slide:nth-child(9)
  .item-slider
  .content-row-funded
  .title-upcoming-sale {
  color: #8f5ae8;
  background: rgba(143, 90, 232, 0.1);
}

.slide-row-funded
  .slick-slide:nth-child(1)
  .item-slider
  .content-row-funded
  .box-title
  .number {
  background: #627eea;
}
.slide-row-funded
  .slick-slide:nth-child(1)
  .item-slider
  .content-row-funded
  .list-raised
  li
  .right {
  color: #627eea;
}
.slide-row-funded
  .slick-slide:nth-child(1)
  .item-slider
  .content-row-funded
  .title-upcoming-sale {
  color: #627eea;
  background: rgba(98, 126, 234, 0.1);
}
.slide-row-funded
  .slick-slide:nth-child(2)
  .item-slider
  .content-row-funded
  .box-title
  .number {
  background: #e2b100;
}
.slide-row-funded
  .slick-slide:nth-child(2)
  .item-slider
  .content-row-funded
  .list-raised
  li
  .right {
  color: #e2b100;
}
.slide-row-funded
  .slick-slide:nth-child(2)
  .item-slider
  .content-row-funded
  .title-upcoming-sale {
  color: #e2b100;
  background: rgba(240, 185, 11, 0.1);
}
.slide-row-funded
  .slick-slide:nth-child(3)
  .item-slider
  .content-row-funded
  .box-title
  .number {
  /* background: #8f5ae8; */
}
.slide-row-funded
  .slick-slide:nth-child(3)
  .item-slider
  .content-row-funded
  .list-raised
  li
  .right {
  /* color: #8f5ae8; */
}
.slide-row-funded
  .slick-slide:nth-child(3)
  .item-slider
  .content-row-funded
  .title-upcoming-sale {
  /* color: #8f5ae8;
  background: rgba(143, 90, 232, 0.1); */
}
/* ket thuc reder color */
.slide-row-funded .slick-prev,
.slide-row-funded .slick-next {
  background: red;
}
.style-ar-r {
  width: 36px;
  height: auto;
  position: absolute;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.style-ar-l {
  width: 36px;
  height: auto;
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.container-ecosystem {
  width: 100%;
  max-width: 1315px;
  padding: 0 20px 0 20px;
  margin: 0 auto;
  position: relative;
}
.bg-ecosystem {
  background: rgba(246, 247, 251, 0.88);
}
.banner-img {
  width: fit-content;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.w-50 {
  width: 50%;
}
.content-ecosystem {
  text-align: left;
  border: 1px solid #eff3f8;
  padding: 10px 10px 10px 20px;
  border-radius: 6px;
  width: 370px;
  position: relative;
  margin-bottom: 90px;
  background: #fff;
  box-shadow: 5px 10px 16px #aea4a44f;
}
.content-ecosystem .title-icon .icon-img {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.content-ecosystem .title-icon .icon-img img {
  width: 16px;
}
.content-ecosystem .title-icon .icon-img .text {
  font-size: 16px;
  color: #000;
  margin-left: 10px;
}
.content-ecosystem .desc {
  color: #707a8a;
  font-size: 14px;
  margin-bottom: 0;
}
.content-ecosystem::before {
  content: "";
  background: #70e5c2;
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 6px 0 0 6px;
}
.table-ecosystem .colum:nth-child(2) .content-ecosystem::before {
  background: #6d98fa;
}
.table-ecosystem .colum:nth-child(3) .content-ecosystem::before {
  background: #4a5ef3;
}
.table-ecosystem .colum:nth-child(4) .content-ecosystem::before {
  background: #b350ff;
}
.table-ecosystem .colum:nth-child(5) .content-ecosystem::before {
  background: #b5e2fd;
}
.table-ecosystem .colum:nth-child(6) .content-ecosystem::before {
  background: #ff8da2;
}
.table-ecosystem .colum:nth-child(7) .content-ecosystem::before {
  background: #9d95ff;
}
.table-ecosystem .colum:nth-child(8) .content-ecosystem::before {
  background: #ffdc98;
}
.table-ecosystem .colum:nth-child(1) .content-ecosystem {
  margin-left: 60px;
}
.table-ecosystem .colum:nth-child(7) .content-ecosystem {
  margin-left: 60px;
  margin-bottom: 0;
}
.table-ecosystem .colum:nth-child(2) .content-ecosystem {
  margin-left: auto;
}
.table-ecosystem .colum:nth-child(4) .content-ecosystem {
  margin-left: auto;
}
.table-ecosystem .colum:nth-child(6) .content-ecosystem {
  margin-left: auto;
}
.table-ecosystem .colum:nth-child(8) .content-ecosystem {
  margin-left: auto;
}
.table-ecosystem .colum:nth-child(2) .content-ecosystem {
  margin-right: 60px;
}
.table-ecosystem .colum:nth-child(8) .content-ecosystem {
  margin-right: 60px;
  margin-bottom: 0;
}
.content-makeour {
  text-align: left;
  padding-right: 70px;
}
.content-makeour .title-makeour {
  color: rgba(63, 74, 87, 1);
  font-size: 40px;
  font-weight: 600;
}
.main-color {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.content-makeour .desc {
  color: #616772;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 47px;
}
.btn-mar {
  margin: 0;
}
.mar-l-20 {
  margin-left: 20px;
}
.list-make {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 92px;
  margin-bottom: 0;
  padding-left: 0;
}
.list-make li {
  list-style-type: none;
}
.list-make li .text-top {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #707a8a;
}
.list-make li .text-bottom {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #3f4a57;
}
.align-item {
  align-items: center;
}
.table-makeour {
  padding-bottom: 50px;
}
.align-item {
  align-items: center;
}
.down-price {
  color: red;
}
.up-price {
  color: #0cb74f;
}

/* responsive 1200px */

@media screen and (max-width: 1200px) {
  .container {
    width: 100%;
  }
  .main-setion {
    padding: 50px 0 20px 0;
  }
  .content-banner {
    padding: 10px 0 10px 0;
  }
  .content-banner .title {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 138%;
    text-align: center;
    color: #1e2329;
  }
  .content-banner .desc {
    margin-bottom: 35px;
  }
  .main-chain .chain-swap {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -10px;
  }
  .main-chain .chain-swap .box-img {
    width: 33%;
    padding: 10px;
  }
  .w-25,
  .w-20 {
    width: 50% !important;
  }
  .slider-chain {
    display: none;
  }
  .table-raise {
    padding: 60px 0 0 0;
  }
  .w-100-5 {
    width: 100%;
  }
  .flex-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .flex-body .flex-l {
    display: flex;
    align-items: center;
  }
  .content-stack .box-icon {
    margin-bottom: 0;
  }
  .content-stack .title {
    margin-bottom: 0;
    margin-left: 13px;
  }
  .content-stack .desc {
    margin-bottom: 0;
  }
  .main-title .title {
    font-size: 24px;
  }
  .slider-chain.mobile {
    display: block;
  }
  .style-ar-l {
    width: 36px;
    height: auto;
    position: absolute;
    left: 42%;
    top: 102%;
    cursor: pointer;
    transform: translateX(-50%);
  }
  .style-ar-r {
    width: 36px;
    height: auto;
    position: absolute;
    left: 58%;
    top: 102%;
    cursor: pointer;
    transform: translateX(-50%);
  }
  .btn-show-more {
    margin-top: 60px;
  }
  .w-33 {
    width: 100% !important;
  }
  .table-project-raise .w-10 {
    width: auto;
  }
  .row-funded {
    padding: 0 50px;
  }
  .w-50 {
    width: 100% !important;
  }
  .table-ecosystem .colum:nth-child(1) .content-ecosystem {
    margin-left: 0;
  }
  .table-ecosystem .colum:nth-child(7) .content-ecosystem {
    margin-left: 0;
    margin-bottom: 20px;
  }
  .content-ecosystem {
    width: 100%;
    margin-bottom: 20px;
  }
  .table-makeour .columns {
    flex-direction: column-reverse;
  }
  .content-makeour .title-makeour {
    font-size: 30px;
  }
  .content-makeour .desc {
    margin-bottom: 30px;
  }
  .list-make {
    margin-top: 40px;
    flex-wrap: wrap;
  }
  .table-makeour {
    padding-bottom: 0;
  }
  .list-make li {
    width: 50%;
    margin-bottom: 15px;
  }
  .list-make li:last-child {
    margin-bottom: 0;
  }
  .content-makeour {
    padding-right: 0;
  }
  .main-banner {
    padding-top: 100px;
    background-size: cover;
  }
  /* .dis-mobile-max1200 {
    display: none;
  } */
  .content-raise .number-raise {
    font-size: 20px;
  }
  .content-stack .btn-getstarted {
    position: relative;
    bottom: 0;
  }
  .box-img.chain-custom:hover .hight-light {
    padding: 10px;
  }
}
@media screen and (max-width: 768px) {
  .main-title .title {
    font-size: 16px;
  }
}
/* responsive 375px */

@media screen and (max-width: 375px) {
  .content-banner .title {
    font-size: 25px;
  }
  .content-banner .desc {
    font-size: 14px;
  }
  .btn {
    padding: 10px 20px;
    font-size: 14px;
  }
  .main-title .title {
    font-size: 20px;
  }
  .content-stack .title {
    font-size: 16px;
  }
  .main-footer {
    padding-top: 20px;
  }
}
.App .ant-layout {
  background: none;
}

.round-one {
  grid-area: round-one1;
  width: 373px;
}
.round-two {
  grid-area: round-two2;
}
.round-three {
  grid-area: round-three3;
}
.round-four {
  grid-area: round-four4;
}

.table-stack-round .grid-row {
  display: grid;
  grid-template-areas:
    "round-one1 round-one1 round-two2 round-two2 round-three3 round-three3"
    "round-one1 round-one1 round-four4 round-four4 round-four4 round-four4";
  gap: 20px;
  background-color: #2196f3;
  padding: 10px;
}

.table-stack-round .grid-row {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}
.content-round-stack {
  background: linear-gradient(100.89deg, #edf5fe 12.35%, #dfecff 96.98%);
  border-radius: 8px;
  padding: 33px;
  text-align: left;
  position: relative;
  height: 100%;
}
.content-round-stack .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #1e2329;
  margin-bottom: 18px;
}
.content-round-stack .desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  color: #787a9b;
}
.button-link {
  margin-left: -5px;
  margin-top: 25px;
  margin-bottom: 30px;
  z-index: 999;
  /* position: relative; */
}
.content-round-stack .img-round {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
}
.round-one .content-round-stack .img-round {
  width: 70%;
}
.round-two .content-round-stack .img-round {
  width: 50%;
}
.round-three .content-round-stack .img-round {
  width: 50%;
}
.round-four .content-round-stack .img-round {
  width: 100%;
}
.round-two .content-round-stack {
  background: linear-gradient(200deg, #f6ffff -2.56%, #e0feff 104.96%);
  border-radius: 8px;
}
.round-three .content-round-stack {
  background: linear-gradient(101deg, #fcf6fd 11.59%, #fbf0f6 103.08%);
  border-radius: 8px;
}
.round-four .content-round-stack {
  background: linear-gradient(95deg, #edf0ff 3.76%, #d8deff 113.52%);

  border-radius: 8px;
}
.round-four .content-round-stack .img-round {
  right: 20px;
  max-width: 280px;
}
@media screen and (max-width: 1200px) {
  .table-stack-round .grid-row {
    display: grid;
    grid-template-areas:
      "round-one1 "
      "round-two2"
      "round-three3"
      "round-four4";
    gap: 10px;
    padding: 10px;
  }
  .round-one {
    width: 100%;
  }
  .round-one .img-round img {
    width: 60%;
  }
  .round-one .img-round {
    text-align: right;
  }
  .round-one .content-round-stack {
    padding-bottom: 80px;
  }
  .round-four .img-round img {
    width: 60%;
  }
  .round-four .img-round {
    text-align: right;
  }
  .round-four .content-round-stack .img-round {
    right: 0;
  }
}
.eco-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;
  padding-bottom: 10px;
}
.eco-text {
  font-size: 16px;
  line-height: 168%;
  color: #000000;
  padding-left: 15px;
}
.eco-img {
  position: relative;
}
.eco-title {
  position: absolute;
  width: 70%;
  left: 15%;
}
.title-upcoming-sale :hover {
  cursor: pointer;
}
.custom-widget {
  padding-top: 10px;
}
.table-project-raise .slick-slider {
  width: 100%;
}
.box-ido-raise:hover {
  cursor: pointer;
  background: #e7e7e9;
}
.fun {
  background: linear-gradient(206.08deg, #fdf0f7 -4.04%, #fff6f1 106.7%);
}
.uni {
  background: linear-gradient(200.25deg, #f7ffff -2.56%, #e0feff 104.96%);
}
.raise {
  background: linear-gradient(203.97deg, #fff7ec 10.1%, #fdf2d2 106.19%);
}
.marquee-custom .overlay {
  /* --gradient-width: 50px !important; */
}
.columns.funded .item-slider {
  width: 33%;
}
@media screen and (max-width: 1200px) {
  .columns.funded .item-slider {
    width: 33%;
  }
}
@media screen and (max-width: 991px) {
  .columns.funded .item-slider {
    width: 33%;
  }
}
@media screen and (max-width: 768px) {
  .slick-slide img {
    height: auto !important;
  }
  .columns.funded .item-slider {
    width: 50%;
  }
}
.img-small img {
  border-radius: 99px !important;
}
.box-icon.partner-custom {
  position: relative;
  display: inline-block;
}
.box-icon.partner-custom .img-top {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.box-icon.partner-custom:hover .img-top {
  display: inline;
  cursor: pointer;
}
iframe {
  display: none;
}

.btn.btn-trade:hover {
  background: #1e2329;
  color: #fff;
  transition: 0.3s ease;
}
.content-row-funded .box-img .img-big img {
  transform: scale(1);
  transition: 0.3s ease;
}
.content-row-funded:hover .box-img .img-big img {
  transform: scale(1.2);
  transition: 0.3s ease;
}
.item-slider img {
  transform: scale(1);
  transition: 0.3s ease;
}
.item-slider:hover img {
  transform: scale(1.1);
  transition: 0.3s ease;
  cursor: pointer;
}
.colum.w-25 .content-upcoming .box-img .img-big img {
  transform: scale(1);
  transition: 0.3s ease;
}
.colum.w-25 .content-upcoming:hover .box-img .img-big img {
  transform: scale(1.1);
  transition: 0.3s ease;
}

.colum.w-20 .content-upcoming .box-img .img-big img {
  transform: scale(1);
  transition: 0.3s ease;
}
.colum.w-20 .content-upcoming:hover .box-img .img-big img {
  transform: scale(1.1);
  transition: 0.3s ease;
}

.title-upcoming-sale:hover {
  background: #e7e7e9;
  color: #707a8a;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 15%);
}
.content-round-stack {
  overflow: hidden;
}
.content-round-stack .img-round img {
  transform: scale(1);
  transition: 0.3s ease;
}
.content-round-stack:hover .img-round img {
  transform: scale(1.1);
  transition: 0.3s ease;
  cursor: pointer;
}

.table-raise .columns .colum.w-25:hover {
  box-shadow: 0px 2px 2px rgb(71 77 87 / 10%);
  border-radius: 8px;
}

.content-banner .title:hover {
  background: linear-gradient(to right, #4d4d4d 0, white 10%, #4d4d4d 20%);
  background-position: 0;
  -webkit-text-fill-color: transparent;
  animation: shine 1.8s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

@keyframes shine {
  0% {
    background-position: 0;
  }

  100% {
    background-position: 800px;
  }
}
.slick-slide img {
  height: 181px;
}
.main-chain .chain-swap.cus {
  margin: 0 -100px;
}
@media screen and (max-width: 1200px) {
  .main-chain .chain-swap.cus {
    margin: 0 -10px;
  }
}
.colum.w-25 .box-icon.partner-custom img:hover {
  transform: scale(1.3);
  transition: transform 0.2s;
}
.colum.w-20 .box-icon.partner-custom img:hover {
  transform: scale(1.3);
  transition: transform 0.2s;
}
.box-img.chain-custom .hight-light {
  display: none;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 99;
}
.box-img.chain-custom:hover .hight-light {
  display: inline;
  cursor: pointer;
}
.box-img.chain-custom .hight-light1 {
  display: none;
  position: absolute !important;
  top: 0px;
  left: 0;
  z-index: 99;
}
.box-img.chain-custom:hover .hight-light1 {
  display: inline;
  cursor: pointer;
}
.box-img.chain-custom .hight-light2 {
  display: none;
  position: absolute !important;
  top: 0px;
  left: 0px;
  z-index: 99;
}
.box-img.chain-custom:hover .hight-light2 {
  display: inline;
  cursor: pointer;
}
.eco-content img {
  width: 38px;
}

.homepage {
  background: #fbfbfc;
  margin: 0 10px;
  @media (max-width: 1024px) {
    margin: unset;
  }
}
