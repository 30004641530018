.flex-box.full-research {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 16px;
  border: 1px solid #ececec;
  gap: 8px;
  border-radius: 12px;
  margin-top: 14.5px;
  justify-content: space-between;

  * {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 1024px) {
    margin: 16px 0 0 0;
    flex-direction: column;
  }

  .new-box {
    display: flex;
    align-items: center;
    gap: 4px;

    .logo {
      width: 40px;
      height: 40px;
    }

    .text {
      .name {
        font-family: Poppins;
        font-weight: 600;
        font-size: 18px;
        letter-spacing: 0%;
        color: #1e2329;
      }

      .time {
        color: #787a9b;
        font-family: Poppins;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0%;
      }
    }
  }

  .arrow {
    width: 24px;
    height: 24px;

    @media (max-width: 1024px) {
      transform: rotate(90deg);
    }
  }
}

.container.full-research {
  max-width: 1240px;
  width: 100%;
  padding: 0;

  @media (max-width: 1024px) {
    padding: 0 16px;
  }
}

.container.full-research.new {
  max-width: 1240px;
  padding-bottom: 40px;
  .launchpad {
    margin-bottom: 16px;
    margin-top: 20px;
  }
}

.w-30,
.w-70 {
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.new-intro {
  .title-name {
    font-family: Poppins;
    font-weight: 500;
    font-size: 32px;
    line-height: 138%;
    letter-spacing: 0%;
    color: #1e2329;
  }

  .new-desc {
    margin-top: 4px;
    font-family: Poppins;
    font-weight: 300;
    font-size: 16px;

    letter-spacing: 0%;
    color: #707a8a;
  }

  .banner {
    width: 100%;
    height: auto;
    margin-top: 24px;
  }
}

.tabs {
  display: flex;
  padding: 5px;
  background: #f6f7fc;
  box-shadow: 0px 0px 6px 0px #0000000f inset;
  border-radius: 6px;
  width: fit-content;
  margin-top: 24px;

  .tab {
    padding: 8px 23px;
    font-family: Poppins;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0%;
    text-align: center;
    color: #707a8a;
    border-radius: 6px;
    cursor: pointer;
    transition: 0.3s ease;

    @media (max-width: 1024px) {
      padding: 8px;
      font-size: 14px;
    }

    &.active {
      background: #1e2329;
      color: #ffffff;
    }
  }
}

.content-desc.new {
  * {
    font-family: Poppins;
    font-weight: 300;
    font-size: 16px;
    line-height: normal;
    letter-spacing: 0%;
    color: #707a8a;
  }

  a>strong {
    color: unset;
  }

  h3>strong {
    font-weight: 500;
    color: #1e2329;
  }

  a {
    color: #1890ff;
  }

  /* strong, */
  h2 strong {
    font-family: Poppins;
    font-weight: 500;
    font-size: 26px;
    letter-spacing: 0%;
    color: #1e2329;
  }

  margin-top: 0 !important;
}

.new-flex {
  margin-top: 24px;
  gap: 16px;
  display: flex;
  align-items: flex-start;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
  }

  .head {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    gap: 10px;

    p {
      font-family: Poppins;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 1%;
      /* text-align: center; */
      margin: 0;
    }

    .arrow {
      width: 24px;
      height: 24px;
      opacity: 0;
    }

    transition: 0.3s ease;

    &:hover,
    &.active {
      .arrow {
        opacity: 1;
      }

      p {
        color: #f0c213;
      }
    }
  }
}
.w-30.new-menu-list {
  position: sticky;
  top: 75px;
  background: #fff;

  @media (max-width: 768px) {
    display: flex;
    overflow: auto;
    top: 68px;
    background: #dcd6d0;
    border-radius: 16px;
    .head {
      white-space: nowrap;
      padding: 5px;
      padding: 10px 5px;
      font-size: 12px !important;
    }
  }
}
.tab-2 {
  margin-top: 24px;

  .tab-2-flex {
    padding: 16px;
    background: #ffffff;
    border: 1px solid #e9edf3;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;

    .tab-2-box {
      flex: 1;
      max-width: 156px;

      .name {
        font-family: Poppins;
        font-weight: 400;
        font-size: 14px;

        letter-spacing: 0%;
        color: #707a8a;
        margin: 0;
      }

      .value {
        font-family: Poppins;
        font-weight: 600;
        font-size: 16px;

        letter-spacing: 0%;
        color: #3f4a57;
        margin: 0;
      }
    }
  }

  .tab-2-flex-bottom {
    margin-top: 16px;
    display: flex;
    gap: 16px;

    .tab-2-box-left {
      flex: 1;
      padding: 24px;
      background: #ffffff;
      border: 1px solid #e9edf3;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .flex {
        display: flex;
        justify-content: space-between;
      }

      .name {
        font-family: Poppins;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        letter-spacing: 0%;
        color: #707a8a;
        margin: 0;
      }

      .value {
        font-family: Poppins;
        font-weight: 600;
        font-size: 16px;
        text-align: right;
        letter-spacing: 0%;
        color: #3f4a57;
        margin: 0;
      }
    }

    .tab-2-box-right {
      flex: 1;
      padding: 24px;
      background: #ffffff;
      border: 1px solid #e9edf3;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name {
        font-family: Poppins;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0%;
        color: #707a8a;
        margin: 0;
      }

      .value {
        font-family: Poppins;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0%;
        color: #3f4a57;
        margin: 0;
      }
    }
  }
}

.tab-3 {
  margin-top: 24px;

  .tab-3-flex-bottom {
    margin-top: 24px;

    .tab-3-box-left {
      flex: 1;
      padding: 16px;
      background: #ffffff;
      border: 1px solid #e9edf3;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;

      .flex {
        display: flex;
        justify-content: space-between;
      }

      .name {
        font-family: Poppins;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        letter-spacing: 0%;
        color: #707a8a;
        margin: 0;
      }

      .value {
        font-family: Poppins;
        font-weight: 600;
        font-size: 16px;
        text-align: right;
        letter-spacing: 0%;
        color: #3f4a57;
        margin: 0;
      }
    }
  }
}

.content-swap-search-ido {
  position: sticky;
  top: 75px;
}

@media (max-width: 768px) {
  .flex-box.full-research {
    align-items: flex-start;
  }
  .arrow {
    display: none;
  }
  .tab-2-flex {
    flex-wrap: wrap;
    gap: 5px;
  }

  .tab-2-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    max-width: none !important;
    flex: auto !important;
  }
  .tabs {
    margin: 20px auto 0;
  }
  .content-swap-search-ido {
    position: unset;
    margin-top: 10px;
  }
  .main-full-project .name {
    font-size: 16px !important;
    padding-left: 10px;
  }
  .new-desc {
    font-size: 14px !important;
  }
  .new-intro .title-name {
    font-size: 26px !important;
  }
}

.flex-title {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 10px;

  img {
    width: 50px;
    height: 50px;
  }

  .bage-s,
  .bage-v,
  .bage-d,
  .bage-r {
    position: unset;
  }
}

.end-pool-new {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin: 16px 0;
  img {
    width: 60px;
    height: 60px;
  }
  .title-new {
    font-family: Poppins;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0%;
    line-height: normal;
    margin: 0;
    color: #1e2329;
  }
  .content-new {
    font-family: Poppins;
    font-weight: 300;
    font-size: 14px;
    color: #707a8a;
    line-height: normal;
    letter-spacing: 0%;
    margin: 0;
    text-align: center;
  }
}
