.name-address {
  display: flex;
  justify-content: space-between;
}
.name-address {
  display: flex;
  justify-content: space-between;
  background: #f0f0f0;
  margin: 5px 0;
  padding: 10px;
  border-radius: 4px;
}
.name-address:hover {
  cursor: pointer;
  background: #d9d9d9;
}
.name-address.active {
  background: #ffd75a;
}
.title-modal-sub {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.icon-sub {
  width: 50px;
  height: 50px;
}
.titleModalPK {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #1e2329;
}
.ant-modal-wrap.modal-pk-select {
  z-index: 9999999;
}
