.all-report {
  padding-top: 192px;
  /* background: url("https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/892421bfdfd6f28761be3ab53b4921bec30a9641502b0107aa.jpg"); */
  text-align: center;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}
.content-rp {
  display: flex;
  justify-content: center;
  background: #f5f5f56e;
  padding: 20px 0 10px 20px;
  position: relative;
  border: 1px solid #f5f5f5;
}
.content-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.top-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.content-bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.col-rp {
  width: calc(100% / 3);
}
.line-rp {
  position: absolute;
  width: 1px;
  height: 100%;
  background: #6c757d4f;
  left: 33%;
  top: 0;
}
.line-rp-next {
  position: absolute;
  width: 1px;
  height: 100%;
  background: #6c757d4f;
  left: 66%;
  top: 0;
}
.title-top {
  color: #878a99;
  font-size: 16px;
  text-transform: uppercase;
}
.num-top {
  display: flex;
  font-weight: 500;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
  align-items: center;
}
.text-num-top {
  padding-left: 10px;
}
.num-top .anticon svg {
  width: 25px;
  height: 25px;
}
.select-ido {
  padding-bottom: 10px;
  text-align: start;
  width: 300px;
}
.select-ido .ant-select-selector {
  width: 100% !important;
}
.select-ido-mb .ant-select-selector {
  width: 100% !important;
}
.table-rp {
  padding-top: 50px;
  padding-bottom: 150px;
}
.title-report {
  font-size: 26px;
  font-weight: 600;
  padding-bottom: 30px;
  letter-spacing: 0.5px;
  font-family: "Poppins", "sans-serif";
}
.select-ido .ant-select-selector .ant-select-selection-item {
  color: #878a99;
  font-size: 14px;
}
.select-ido-mb .ant-select-selector .ant-select-selection-item {
  color: #878a99;
  font-size: 14px;
}
.mb-content12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0 20px 20px;
  gap: 8px;
}
.mb-content1 {
  background: #fafafa;
  /* margin-bottom: 3px; */
  /* border-radius: 8px; */
  border-bottom: 1px solid #d9d9d9b5;
}
.mb-title {
  font-size: 16px;
  font-weight: 500;
  color: #6c757d;
  text-transform: uppercase;
}
.mb-num {
  display: flex;
  align-items: center;
  gap: 10px;
}
.mb-num span {
  color: rgba(0, 0, 0, 0.85);
  font-size: 22px;
  font-weight: 600;
}
.mb-num svg {
  width: 22px;
  height: 22px;
}
.all-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  justify-content: center;
}
.round-content {
  padding: 20px 0 20px 0;
  width: 48%;
  background: #fafafa;
}
.round-name {
  font-size: 16px;
  font-weight: 500;
  color: #6c757d;
  text-transform: uppercase;
  padding-bottom: 5px;
}
.mb-detail-title {
  font-size: 16px;
  color: #6c757d;
}
.mb-detail-num {
  font-size: 20px;
  font-weight: 500;
}
.round-detail {
  padding: 20px 0 20px 0;
}
.mb-rount {
  padding-bottom: 40px;
}
.table-rp-mb {
  padding-top: 50px;
  padding-bottom: 50px;
}
.select-ido-mb {
  padding-bottom: 10px;
  text-align: start;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .all-report {
    padding-top: 120px;
  }
}

/* @media screen and (max-width: 768px) {
  .all-report {
    padding-top: 120px;
  }
  .title-top {
    height: 35px;
    font-size: 13px;
    text-align: left;
    padding-left: 3px;
  }
  .text-num-top {
    padding-left: 3px;
  }
  .content-rp {
    padding: 20px 0 20px 5px;
  }
  .num-top {
    font-size: 16px;
  }
  .top-content {
    gap: 15px;
  }
  .content-bottom {
    gap: 15px;
  }
  .num-top .anticon svg {
    width: 15px;
    height: 15px;
  }
  .table-rp .ant-table-content {
    overflow-x: scroll;
  }
} */
