.bg-new {
  background: url('../../../public/images/new/poolbg.svg');
  background-size: contain;
  background-position: top right;
  background-repeat: no-repeat;
  @media (max-width: 1024px) {
    background: unset;
  }
}

.new-launch-pool {
  * {
    margin: 0;
    padding: 0;
    text-align: start;
  }

  margin: 0 auto;
  padding-bottom: 98px;
  padding-top: 113px;
  max-width: 1166px;
  /* background: #FFFFFF; */

  @media (max-width: 1024px) {
    max-width: 100%;
    padding-top: 70px;
    margin: 0px auto 20px auto;
  }

  .pool-banner {
    /* background: url('../../../public/images/new/poolbg.svg');
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat; */
    padding-bottom: 66px;

    @media (max-width: 1024px) {
      padding: 16px;
      background-size: cover;
      background-position: left;
      background-repeat: no-repeat;
    }

    .new-title {
      font-family: Poppins;
      font-weight: 500;
      font-size: 40px;
      line-height: 138%;
      letter-spacing: 0%;
      color: #1E2329;

      span {
        font-weight: 600;
        background: linear-gradient(90deg, #F0B90B 0%, #FFE17E 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .new-desc {
      margin-top: 16px;
      font-family: Poppins;
      font-weight: 400;
      font-size: 18px;
      line-height: 138%;
      letter-spacing: 0%;
      color: #1E2329;
    }

    .dashboard {
      margin-top: 24px;
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 24px;
      /* background: #FFFFFF; */
      border-radius: 6px;
      box-shadow: 0px 6px 20px 0px #787A9B2E;
      width: fit-content;

      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: start;
        width: 100%;
      }

      .line {
        height: 49.5px;
        border-left: 1px solid #707A8A52;

        @media (max-width: 1024px) {
          display: none;
        }
      }

      .new-box {
        .value {
          font-family: Poppins;
          font-weight: 600;
          font-size: 20px;
          color: #030303;
          letter-spacing: 0%;

          span {
            color: #707A8A;
          }
        }

        .name {
          margin-top: 4px;
          font-family: Poppins;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0%;
          color: #707A8A;
        }
      }
    }
  }

  .flex {
    display: flex;
    justify-content: center;
  }

  .tabs {
    margin-top: 29px;
    padding: 5px;

    @media (max-width: 1024px) {
      margin: 0 auto;

      .tab {
        padding: 8px 14px;
      }
    }
  }

  .projects {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 24px;
    min-height: 441px;
    height: auto;

    @media (max-width: 1024px) {
      margin-left: 0;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .project {
      flex: 1 1 calc(33.33% - 15px);
      max-width: calc(33.33% - 15px);
      @media (max-width: 1024px) {
        flex: unset;
        max-width: 100%;
      }
      padding: 16px;
      background: #FFFFFF;
      border: 1px solid #E9EFF5;
      box-shadow: 0px 6px 20px 0px #787A9B2E;
      border-radius: 6px;
      cursor: pointer;
      transition: 0.3s ease;

      &:hover {
        border: 1px solid #F0B90B;
        background: #F6F7FC;
      }

      .top {
        display: flex;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          gap: 6px;

          img {
            width: 36px;
            height: 36px;
            box-shadow: 0px 6px 60px 0px #50505061;
            border-radius: 50%;
          }

          p {
            font-family: Poppins;
            font-weight: 500;
            font-size: 20px;
            letter-spacing: 0%;
            color: #1E2329;
          }
        }

        .right {
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 4px 8px;

          img {
            width: 16px;
            height: 16px;
          }

          p {
            font-family: Poppins;
            font-weight: 500;
            font-size: 15px;
            letter-spacing: 0%;
            color: #40D070;

            &.up {
              color: #4479D7;
            }

            &.end {
              color: #707A8A;
            }
          }
        }
      }

      .project-banner {
        margin-top: 16px;

        img {
          width: 100%;
          height: auto;
        }
      }

      .project-time {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        padding: 6px;
        background: #40D0701A;
        border-radius: 6px;

        p {
          color: #40D070;
          font-family: Poppins;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0%;
        }

        &.up,
        &.end {
          background: #F8F9FB;

          p {
            color: #707A8A;
          }
        }
      }

      .project-info {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .info-pair {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .name {
            font-family: Poppins;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0%;
            color: #707A8A;
          }

          .value {
            font-family: Poppins;
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0%;
            color: #030303;
          }

          &.apy .value {
            color: #E1A919;
          }
        }
      }

      .info-cap {
        margin-top: 8px;
        background: #F8F9FB;
        border-radius: 8px;
        padding: 8px;

        .name {
          display: flex;
          justify-content: space-between;

          p {
            font-family: Poppins;
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 0%;
            color: #707A8A;
          }
        }

        .value {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;

          p {
            font-family: Poppins;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0%;
            color: #030303;
          }
        }

        .new-progress {
          margin: 12px 0 0 0;

          .ant-progress-outer {
            max-width: 212px;
            height: 26px;
            padding: 0;
          }



          .ant-progress-line {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .ant-progress-text {
            margin-left: auto;
            font-family: Poppins;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0%;
            color: #F5CB11;
          }
        }
      }
    }
  }
}

.new-nodata {
  width: 433px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 441px;
    justify-content: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
}

.btn-new-lp {
  margin-top: 20px;
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
  letter-spacing: 6%;
  text-align: center;
  color: #FFFFFF !important;
  background: #1E2329;
  padding: 10px 34px;
  box-shadow: none;
  border-radius: 6px;
  transition: 0.3s ease;
}

@media (max-width: 768px) {
.new-title {
            line-height: 110% !important;
        }
        .pool-banner .dashboard {
          gap: 15px !important;
        }
        .new-nodata{
          height: 300px !important;
          justify-content: flex-start !important;
        }
        .projects{
          padding: 16px !important;
        }
}
