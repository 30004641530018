.process {
  padding-top: 100px;
  position: relative;
  z-index: 2;
}
.process-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 104%;
  text-align: center;
  color: #25273d;
}
.img-step {
  width: 95px;
  position: relative;
}
.step {
  display: flex;
  justify-content: space-around;
  margin: 190px 0;
}
.line-process {
  position: absolute;
  bottom: 9%;
  width: 100%;
  left: 0;
}
.line-process img {
  opacity: 0.6;
}
.step-des {
  position: absolute;
}
.step1 .step-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #606060;
}
.step2 .step-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #606060;
}
.step3 .step-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #606060;
}
.step4 .step-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #606060;
}
.step5 .step-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #606060;
}
.step6 .step-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1em;
  color: #606060;
}

.step-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 161% */

  text-align: center;
  letter-spacing: 0.1em;
  width: 150px;
  color: #787a9b;
}
.step1 .step-des {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.step3 .step-des {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.step5 .step-des {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.step2 .step-des {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.step4 .step-des {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.step6 .step-des {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.btn.btn-trade.process {
  background: #1e2329;
  color: #ffffff;
}
.btn.btn-trade.process:hover {
  background: #ffff;
  color: #1e2329;
}
.get-button.process {
  padding-top: 0px;
}
@media screen and (max-width: 768px) {
  .img-step {
    width: 56px;
  }
  .process-title {
    font-size: 26px;
  }
  .step {
    display: flex;
    justify-content: space-around;
    margin: 30px 0;
    flex-direction: column;
    gap: 30px;
    padding-left: 20px;
  }
  .step1 .step-des {
    display: flex;
    padding-left: 20px;
    top: 0%;
    left: 100%;
    transform: translateX(0%);
    flex-direction: column;
    align-items: flex-start;
  }
  .step2 .step-des {
    display: flex;
    padding-left: 20px;
    top: 0%;
    left: 100%;
    transform: translateX(0%);
    flex-direction: column;
    align-items: flex-start;
  }
  .step3 .step-des {
    display: flex;
    padding-left: 20px;
    top: 0%;
    left: 100%;
    transform: translateX(0%);
    flex-direction: column;
    align-items: flex-start;
  }
  .step4 .step-des {
    display: flex;
    padding-left: 20px;
    top: 0%;
    left: 100%;
    transform: translateX(0%);
    flex-direction: column;
    align-items: flex-start;
  }
  .step5 .step-des {
    display: flex;
    padding-left: 20px;
    top: 0%;
    left: 100%;
    transform: translateX(0%);
    flex-direction: column;
    align-items: flex-start;
  }
  .step6 .step-des {
    display: flex;
    padding-left: 20px;
    top: 0%;
    left: 100%;
    transform: translateX(0%);
    flex-direction: column;
    align-items: flex-start;
  }
  .step-title {
    text-align: start;
    width: 100%;
  }
  .line-process {
    display: none;
  }
  .process {
    padding-top: 100px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
    padding-bottom: 40px;
    gap: 20px;
  }
  .btn.btn-trade.pro {
    width: 50%;
  }
  .step-des {
    width: 250px;
  }
  .wrap-process{
    padding-top: 30px;
  }
  .wrap-process .step{
    gap: 25px;
  }
}
