.modal-claimed-after {
  width: 100% !important;
  max-width: 980px !important;
}
.modal-claimed-after svg {
  fill: #38f2bc;
}
.modal-claimed-after .ant-modal-content {
  background: #ffffff;
  border-radius: 8px;
}
.modal-claimed-after .ant-modal-content .ant-modal-header {
  text-align: center;
  background: #ffffff;
  border-radius: 8px 8px 0 0;
}
.modal-claimed-after .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #1e2329;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  font-weight: bolder;
}
.modal-claimed-after .ant-modal-content .ant-modal-footer {
  display: none;
}
.modal-claimed-after .bsc-p-launchpad_detail-allocations {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  min-width: fit-content;
}
.modal-claimed-after
  .bsc-p-launchpad_detail-allocations
  .bsc-p-launchpad_detail-allocations-empty {
  background-color: #1a1916;
  height: 80px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-claimed-after
  .bsc-p-launchpad_detail-allocations
  .bsc-p-launchpad_detail-allocations-empty
  span {
  font-size: 15px;
  color: #bebcba;
}
.modal-claimed-after .bsc-p-launchpad_detail-allocations table {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.modal-claimed-after .bsc-p-launchpad_detail-allocations table tr {
  display: flex;
  height: auto;
  border-top: none;
  padding-bottom: 10px;
}
.modal-claimed-after .bsc-p-launchpad_detail-allocations table tr td {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #c99400;
  word-break: break-all;
  flex-wrap: wrap;
}

.modal-claimed-after
  .bsc-p-launchpad_detail-allocations
  table
  tr
  > td:nth-child(1) {
  padding-left: 15px;
  color: #c99400;
}
.modal-claimed-after
  .bsc-p-launchpad_detail-allocations
  table
  > tr:nth-child(1) {
  border-top: unset !important;
  height: 48px;
  background-color: #e9edf3;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  justify-content: flex-start;
  align-items: center;
}
.modal-claimed-after
  .bsc-p-launchpad_detail-allocations
  table
  > tr:nth-child(1)
  td {
  color: #efebe5;
  font-size: 15px;
  word-break: break-word;
}
.modal-claimed-after .bsc-p-launchpad_detail-allocations table > tr th {
  width: 25%;
  color: #000000;
  text-align: left;
  padding-left: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
@media screen and (max-width: 768px) {
  .modal-claimed-after .bsc-p-launchpad_detail-allocations table > tr th {
    width: 30%;
    font-size: 12px;
    color: #000000;
  }
  .modal-claimed-after .bsc-p-launchpad_detail-allocations table tr td {
    font-size: 12px;
  }
  .modal-claimed-after
    .bsc-p-launchpad_detail-mobile
    .bsc-p-launchpad_detail-allocations
    table
    tr
    > td:last-child {
    display: inline-grid;
  }
  .modal-claimed-after
    .bsc-p-launchpad_detail-allocations
    table
    > tr
    th:last-child {
    width: 25%;
    padding-left: 34px;
  }
  .modal-claimed-after
    .bsc-p-launchpad_detail-allocations
    table
    > tr
    th:nth-child(3) {
    width: 15%;
    padding-left: 30px;
  }
  .modal-claimed-after
    .bsc-p-launchpad_detail-allocations
    table
    > tr
    td:last-child {
    width: 20%;
  }
  .modal-claimed-after
    .bsc-p-launchpad_detail-allocations
    table
    > tr
    td:nth-child(3) {
    width: 15%;
  }
  .modal-claimed-after
    .bsc-p-launchpad_detail-allocations
    table
    > tr
    td:nth-child(2) {
    width: 30%;
    text-align: justify;
    margin: auto;
    justify-content: center;
  }
  .modal-claimed-after
    .bsc-p-launchpad_detail-allocations
    table
    > tr
    td:nth-child(1) {
    width: 25%;
  }
  .modal-claimed-after
    .bsc-p-launchpad_detail-allocations
    table
    tr
    > td:nth-child(1) {
    color: #c99400;
  }
  .modal-claimed-after .bsc-p-launchpad_detail-allocations table tr td {
    flex: auto;
  }
  .modal-claimed-after
    .bsc-p-launchpad_detail-mobile
    .table-bsc-pool-detail
    .table-bsc-pool-detail-bottom
    table
    th {
    font-size: 12px !important;
  }
  .modal-claimed-after
    .bsc-p-launchpad_detail-mobile
    .bsc-p-launchpad_detail-schedule
    table
    tr
    td {
    font-size: 12px !important;
    padding: 0 15px;
  }
  .modal-claimed-after .bsc-p-launchpad_detail-schedule table {
    width: 100%;
  }
  .modal-claimed-after .bsc-p-launchpad_detail-allocations table {
    width: auto !important;
  }
}
@media screen and (max-width: 375px) {
  .modal-claimed-after
    .bsc-p-launchpad_detail-mobile
    .bsc-p-launchpad_detail-schedule
    table
    tr
    td {
    font-size: 12px !important;
    padding: 0 5px;
  }
  .modal-claimed-after
    .bsc-p-launchpad_detail-mobile
    .bsc-p-launchpad_detail-allocations
    table
    tr
    > td:nth-child(1) {
    padding-left: 5px !important;
  }
}
.modal-claimed-after .btn-waiting-cus {
  background: #66d8db;
  border-radius: 3px;
  color: #fff;
  padding: 7px 11px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid transparent;
  font-size: 12px;
}
.modal-claimed-after .btn-claimed-cus {
  background-color: #2f54eb;
  color: #ffffff;
  padding: 7px 7px;
  border-radius: 9px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid transparent;
  font-size: 12px;
}
.modal-claimed-after .btn-refunded-cus {
  background-color: #2f54eb;
  color: #ffffff;
  padding: 7px 3px;
  border-radius: 9px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid transparent;
  font-size: 12px;
}
.modal-claimed-after .btn-bsc-common {
  color: #fff;
}
.modal-claimed-after .btn-waiting-cus {
  font-size: 15px;
}
.modal-claimed-after .btn-claimed-cus {
  font-size: 15px;
}
.modal-claimed-after .btn-refunded-cus {
  font-size: 15px;
}
@media screen and (max-width: 500px) {
  .modal-claimed-after
    .bsc-p-launchpad_detail-allocations
    table
    > tr
    td:nth-child(3) {
    padding: 0 25px;
  }
  .modal-claimed-after .btn-waiting-cus {
    font-size: 12px;
    border-radius: 10px;
  }
  .modal-claimed-after .btn-claimed-cus {
    font-size: 12px;
    border-radius: 10px;
    padding: 7px 9px;
  }
  .modal-claimed-after .btn-refunded-cus {
    font-size: 12px;
    border-radius: 10px;
    padding: 7px 5px;
  }
}
@media screen and (max-width: 414px) {
  .modal-claimed-after
    .bsc-p-launchpad_detail-allocations
    table
    > tr
    td:nth-child(3) {
    padding: 0 12px;
  }
}
@media screen and (max-width: 375px) {
  .modal-claimed-after
    .bsc-p-launchpad_detail-allocations
    table
    > tr
    td:nth-child(3) {
    /* padding: 0 15px; */
    padding-left: 15px;
  }
}
.modal-claimed-after .btn-disible-cus {
  color: #fff;
  padding: 7px 11px;
  border-radius: 3px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #64748b;
  border: 0px solid transparent;
}
.modal-claimed-after .ant-modal-body {
  padding: 0 24px 24px 24px;
}
.timeClaimable {
  color: #1e2329 !important ;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 21px;
  letter-spacing: 0.01em;
}
button.claim-modal {
  border-radius: 6px;
  border: 1px solid #c99400;
  width: 70px;
}
.claim-modal:hover {
  cursor: pointer;
}
.claim-modal:disabled {
  color: #64748b;
  cursor: no-drop;
}
.modal-claimed-after .btn-claim-after {
  background: #66d8db;
  border-radius: 3px;
  color: #fff;
  padding: 7px 11px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid transparent;
  font-size: 15px;
}
.modal-claimed-after .btn-claim-after:hover {
  cursor: pointer;
}
