.average .chart {
    text-align: left;
}

.text-chart1 {
    font-family: Poppins;
    font-weight: 400;
    font-size: 20px;
    line-height: 22.5px;
    letter-spacing: 10%;
    color: #787A9B;
}

.text-chart1.platform {
    width: 30%;
}

.text-chart1.chart-ido {
    width: 10%;
}

.text-chart1.chart-price {
    width: 60%;
}

.top-chart {
    display: flex;
    text-align: left;
}

.average .chart-num {
    letter-spacing: 0.1em;
    color: #25273d;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-align: start;
    width: 10%;
}

.average .chart-name {
    padding-left: 0px;
    width: 22%;
    word-break: break-word;
}

.average .num-ath {
    padding-left: 10px;
    width: 10%;
    font-weight: 600;
}

.average .chart2 .chart-process {
    padding-left: 0px;
    width: 50%;
}

.average .chart3 .chart-process {
    padding-left: 0px;
    width: 10%;
}

.average .chart4 .chart-process {
    padding-left: 0px;
    width: 11%;
}

.average .chart5 .chart-process {
    padding-left: 0px;
    width: 6%;
}

.average .chart6 .chart-process {
    padding-left: 0px;
    width: 2%;
}

.average .chart7 .chart-process {
    padding-left: 0px;
    width: 2.5%;
}

.average .chart8 .chart-process {
    padding-left: 0px;
    width: 4%;
}

.average .chart9 .chart-process {
    padding-left: 0px;
    width: 4.8%;
}

.average .chart10 .chart-process {
    padding-left: 0px;
    width: 5.5%;
}

.average .chart11 .chart-process {
    padding-left: 0px;
    width: 6%;
}

.average .icon-chart {
    width: 8%;
}

.average .icon-chart img {
    width: 40px;
}

.cus-chart-process {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.chart-blu {
    background: linear-gradient(90deg, #64D781 0%, #5DFFA9 100%);
}

.chart-red {
    background: linear-gradient(90deg, #FFA5A5 0%, #ED2424 100%);
}

.average.item2 {
    width: 15%;
}

@media screen and (max-width: 768px) {
    .average {
        padding-top: 25px;
    }

    .average .top-title {
        line-height: 140%;
        padding: 0;
        padding-bottom: 20px;
    }

    .average .chart-ath {
        padding-left: 0px;
    }

    .average .text-chart1 {
        font-size: 14px;
    }

    .average .chart2 {
        padding-top: 15px;
    }

    .average .icon-chart img {
        width: 100%;
    }

    .average .chart-num {
        font-size: 12px;
    }

    .average .num-ath {
        display: none;
        font-size: 12px;
        padding-left: 3px;
    }

    .average .chart2 .chart-process {
        width: 50%;
    }

    .average .chart1 {
        padding-top: 15px;
    }

    .average .icon-chart {
        display: none;
    }

    .average .chart-name {
        width: 40%;
    }

    .text-chart1.platform {
        width: 40%;
    }

    .text-chart1.chart-price {
        padding-left: 5px;
        width: 50%;
    }

}