.main-stake-pool {
  padding-top: 130px;
  background-size: contain !important;
  background-position: top !important;
  padding-bottom: 50px;
}
.banner-stake-pool {
  position: relative;
  margin-top: 100px;
}
.banner-stake-pool .content-banner-pool {
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.banner-stake-pool .content-banner-pool .content-left {
  width: 65%;
}
.banner-stake-pool .content-banner-pool .content-left .title-pool {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 138%;
  /* or 55px */
  color: #1e2329;
  margin-bottom: 8px;
  text-align: left;
}
.banner-stake-pool .content-banner-pool .content-left .desc-pool {
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 151.5%;
  /* or 24px */
  color: #787a9b;
}
.banner-stake-pool .content-banner-pool .content-right {
  width: 35%;
}
.banner-stake-pool .content-banner-pool .content-right .total-value {
  background: linear-gradient(89.64deg, #fffceb -12.7%, #ffe8d8 112.66%);
  border-radius: 6px;
  color: #fff;
  padding: 20px;
}
.banner-stake-pool .content-banner-pool .content-right .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #707a8a;
}
.banner-stake-pool .content-banner-pool .content-right .item:last-child {
  margin-bottom: 0;
}
.banner-stake-pool .content-banner-pool .content-right .item .text {
  display: block;
  color: #1e2329;
  font-weight: 600;
  margin-top: 10px;
  font-size: 16px;
}
.banner-stake-pool .content-banner-pool .content-right .item .btn-buy-bscs {
  background: transparent;
  padding: 5px 10px;
  border: 1px solid #858585;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.banner-stake-pool .content-banner-pool .content-right .item .btn-buy-bscs img {
  margin-right: 5px;
}
.banner-stake-pool .content-banner-pool .content-right .item.cus {
  display: block;
}

.tabs-stake-pool .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  margin: 0 auto !important;
  background: #f6f7fc;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding: 5px;
}
.tabs-stake-pool .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  display: none;
}
.tabs-stake-pool .ant-tabs-top > .ant-tabs-nav:before {
  border-bottom: 0px solid gray;
}
.tabs-stake-pool .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs
  > div
  > .ant-tabs-nav
  .ant-tabs-nav-list
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin: 0;
}
.tabs-stake-pool .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab-btn {
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
}

.tabs-stake-pool .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs
  > div
  > .ant-tabs-nav
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff !important;
}
.tabs-stake-pool .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs
  > div
  > .ant-tabs-nav
  .ant-tabs-nav-list
  .ant-tabs-tab
  .ant-tabs-tab-btn {
  color: #707a8a !important;
}
.tabs-stake-pool .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:hover {
  color: #fac668;
}
/* .tabs-stake-pool .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-ink-bar {
    display: none;
} */
.tabs-stake-pool .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs
  > div
  > .ant-tabs-nav
  .ant-tabs-nav-list
  .ant-tabs-top
  > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0px solid #f0f0f0 !important;
}
.tabs-stake-pool {
  /* margin-top: 20px; */
  margin-bottom: 40px;
}
.tabs-item-pool {
  margin-top: 30px;
}
.tabs-item-pool .columns {
  display: flex;
  flex-wrap: wrap;
  margin: -15px;
}
.tabs-item-pool .columns .colum {
  padding: 15px;
  width: calc(3 / 12 * 100%);
}
.tabs-item-pool .columns .colum:nth-child(1) .content-item {
  background: linear-gradient(206.08deg, #fdf0f7 -4.04%, #fff6f1 106.7%);
  border-radius: 6px;
}
.tabs-item-pool .columns .colum:nth-child(3) .content-item {
  background: linear-gradient(206.08deg, #fdf0f7 -4.04%, #fff6f1 106.7%);
  border-radius: 6px;
}
.tabs-item-pool .columns .colum:nth-child(2) .content-item {
  background: linear-gradient(206.63deg, #eff3fe -0.03%, #fbeffb 118.82%);
  border-radius: 6px;
}
.tabs-item-pool .columns .colum:nth-child(4) .content-item {
  background: linear-gradient(206.63deg, #eff3fe -0.03%, #fbeffb 118.82%);
  border-radius: 6px;
}
.tabs-item-pool .columns .colum:nth-child(2) .content-item .surget-item {
  background: #ead6ff;
  border-radius: 8px;
}
.tabs-item-pool .columns .colum:nth-child(4) .content-item .surget-item {
  background: #ead6ff;
  border-radius: 8px;
}
.tabs-item-pool .columns .colum .content-item .header-item {
  padding: 25px 20px 10px 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabs-item-pool
  .columns
  .colum
  .content-item
  .header-item
  .header-l
  .name-token {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  color: #1e2329;
}
.tabs-item-pool
  .columns
  .colum
  .content-item
  .header-item
  .header-l
  .days-token {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #707a8a;
  text-align: left;
  margin-top: 5px;
}
.tabs-item-pool .columns .colum .content-item .header-item .header-r .box-img {
  position: relative;
}
.tabs-item-pool
  .columns
  .colum
  .content-item
  .header-item
  .header-r
  .box-img
  img {
  position: relative;
  z-index: 2;
}
.tabs-item-pool
  .columns
  .colum
  .content-item
  .header-item
  .header-r
  .box-img
  .img-small {
  position: absolute;
  top: -12px;
  left: -12px;
  z-index: 1;
}
.tabs-item-pool .columns .colum .body-item {
  padding: 20px 20px;
}
.tabs-item-pool .columns .colum .body-item .surget-item .item-txt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
.tabs-item-pool .columns .colum .body-item .surget-item .item-txt .txt-l {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #707a8a;
}
.tabs-item-pool .columns .colum .body-item .surget-item .item-txt .txt-r {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 0.01em;

  color: #1e2329;
}
.tabs-item-pool .columns .colum .body-item .button-select .btn-select {
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  height: 46px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.06em;

  color: #ffffff;
  border: transparent;
  width: 100%;
  margin-top: 27px;
}
.banner-stake-pool .content-banner-pool .content-right .item .txt-right {
  margin-right: -10px;
}
.banner-stake-pool .content-banner-pool .content-right .item .main-colors {
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
  line-height: 27px;
  /* identical to box height */

  text-align: left;

  color: #1e2329 !important;
}
.d-block {
  display: block;
}
.tabs-stake-pool .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}
.tabs-stake-pool .ant-tabs-tab {
  padding: 8px 25px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #707a8a;
}
.tabs-stake-pool .ant-tabs-tab.ant-tabs-tab-active {
  background: #1e2329;
  /* shadow tab */

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  
}
.tabs-stake-pool .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
  margin: 0 auto;
}
.tabs-item-pool .columns .colum .body-item .surget-item {
  background: #f8dddd;
  border-radius: 8px;
  padding: 10px 15px;
}
@media screen and (max-width: 768px) {
  .container-full {
    width: 100%;
    padding: 0;
  }
  .banner-stake-pool .box-img img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
  }
  .banner-stake-pool .content-banner-pool .content-left .title-pool {
    font-size: 18px;
  }
  .banner-stake-pool .content-banner-pool {
    width: 100%;
    right: 0;
    padding: 10px;
    top: 48%;
  }
  .banner-stake-pool .content-banner-pool .content-left {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .banner-stake-pool .content-banner-pool .content-right {
    width: 100%;
  }
  .banner-stake-pool .content-banner-pool .content-left .desc-pool {
    display: flex;
    font-size: 13px;
    text-align: center;
    margin-bottom: 10px;
  }
  .tabs-item-pool .columns .colum {
    width: 100%;
  }
}
.banner-stake-pool
  .content-banner-pool
  .content-right
  .total-value
  .item
  .btn-buy-bscs:hover {
  border: 1.5px solid #24232396 !important;
}
.icon-lp {
  height: 50px;
  left: 12px;
  top: 4px;
}
.banner-stake-pool .box-img {
  position: relative !important;
}
.banner-stake-pool .box-img img {
  position: relative !important;
  z-index: 0 !important;
}
.banner-stake-pool .box-img .img-small-bot {
  position: absolute !important;
  top: 0px !important;
  left: 70px !important;
  z-index: 1 !important;
}
.tooltip-textfee .ant-tooltip-content {
  width: 230px;
}
.tooltip-textfee .ant-tooltip-inner {
  display: flex;
  justify-content: center;
  background: #1e2329;
  /* color: #fff; */
  /* -webkit-background-clip: padding-box !important; */
  -webkit-text-fill-color: #fff !important;
  border-radius: 12px;
}
/* .tooltip-textfee .ant-tooltip-arrow {
  background: #1e2329;
} */
.link-buy-bscs {
  padding-bottom: 10px;
  display: flex;
  color: #1e2329;
}
.link-buy-bscs:hover {
  color: #fdd284 !important;
  cursor: pointer;
}
.img-link {
  margin-right: 5px;
  height: 21px;
  width: 21px;
}
