.guide-action .btn-approve-mk {
  margin: 0;
  width: 100% !important;
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.06em;

  color: #ffffff;
}

.guide-action {
  margin-top: 15px;
}

.video-mo {
  width: 235px !important;
}

.mar-b-10 {
  margin-bottom: 10px !important;
}

.guide-action .btn-bidding {
  margin: 0 !important;
  width: 100% !important;
}

.full-with-btn {
  width: 100%;
}

.btn-copied:active {
  transform: translateY(4px);
}

.content-profile-user .swap-right .tabs-market.display-seach .ant-tabs-content {
  margin-top: 10px !important;
}

.main-body-modal .input-amount .name-token-staking.price {
  right: 30px;
}

.row-btn-content {
  justify-content: space-evenly;
}

.modal-seting-price {
  max-width: 350px;
}

.modal-seting-price .ant-modal-footer {
  display: none;
}

.modal-seting-price .ant-modal-header {
  background: #fff;
  border-radius: 10px 10px 0 0;
  border-bottom: 0.5px solid #aab6cd !important;
}

.modal-seting-price .ant-modal-body {
  background: #fff;
  border-radius: 0 0 10px 10px;
}

.modal-seting-price .ant-modal-content svg {
  fill: #0dba88;
}

.modal-seting-price .ant-modal-content {
  border-radius: 10px;
  background-color: #1c1b1b;
}

.mg-top-10 {
  margin-top: 10px;
}

.modal-eidt-profile {
  width: 700px !important;
  top: 50px;
}

.modal-eidt-profile .ant-modal-footer {
  display: none;
}

.content-profile-user.res.tabs-artwork .swap-right .tabs-market .ant-tabs-content-holder {
  margin-top: 5px;
  padding-top: 5px;
}

/* .new-list-profile{
  padding-top: 150px;
} */

.item-info-edit .round-l .desc-round.social-cus {
  margin-top: 14px;
}

.item-info-edit.row-social {
  margin-bottom: 10px;
}

.swap-filter-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.swap-filter-new .search-project {
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 0px;
}

.new-mint-btn:hover {
  cursor: pointer;
}

.new-mint-btn {
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.9px;
  width: 170px;
  height: 50px;
  border-radius: 6px;
  background: #1E2329;
  border: 0px solid transparent;
  margin-bottom: 20px;
}

.content-profile-user .swap-right .tabs-market.res-new {
  margin-top: 30px;
}

.img-nft-his {
  width: 45px;
  height: 45px;
}

.td-his1 {
  display: flex;
  gap: 5px;
  align-items: center;
}

.name-nft-his {
  color: #1E2329;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.table-offer-his {
  width: 100%;
  background: #fff;
}

td.td-his2 {
  color: #000;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
}

td.td-his3 {
  color: #000;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

td.td-his4 {
  color: #000;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

.offer-his {
  color: #1E2329;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 15px 10px;
}

.your-offer {
  border-radius: 6px;
  border: 1px solid #E9EFF5;
  background: #F3F4F6;
  padding: 10px 20px;
  /* margin-top: 40px; */
}

.tr-head-offer {
  border-radius: 6px;
  border: 0.9px solid #E9EDF3;
  background: #F6F7FC;
}

.tr-head-offer th {
  padding: 15px 10px;
}

.wrap-table {
  padding: 10px;
  background: #fff;
  border-radius: 6px;
}

.td-offer {
  padding: 15px 10px;
}

button.btn-cancel-offer {
  border-radius: 6px;
  border: 1px solid #1E2329;
  color: #1E2329;
  text-align: center;
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.9px;
  padding: 8px 10px;
  background: #fff;
}

.img-nft-his img {
  border-radius: 6px;
}

@media screen and (max-width: 768px) {
  .video-mo {
    width: -webkit-fill-available !important
  }

  .full-dis {
    height: 310px;
    object-fit: contain;
  }

  .content-profile-user.res.tabs-artwork .swap-right .tabs-market .ant-tabs-content-holder {
    margin-top: 0px;
    padding-top: 0px;
  }

  .profile-new {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  .profile-new .colum.w-25 {
    width: 100% !important;
  }

  .profile-new .swap-left {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  .profile-new .swap-left .box-img {
    margin-top: -90px;
  }

  .profile-new .swap-left .name-rl {
    margin-bottom: 0px;
  }

  .profile-new .w-75 {
    width: 100% !important;
  }

  .profile-new .new-mint-btn {
    width: 100%;
  }

  .profile-new .tabs-market .ant-tabs-tab-btn {
    font-size: 14px;
  }

  .profile-new .tabs-market .ant-tabs-tab {
    padding-right: 5px;
  }

  .profile-new .target-project {
    margin-left: 5px;
    margin-right: 5px;
  }

  .profile-new .swap-filter-new {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .profile-new .target-project {
    margin-left: 0px;
  }

  .profile-new .swap-filter-new .search-project {
    margin-right: 0px;
  }

  .profile-new .swap-filter-new .filter-project {
    margin-left: 0px;
  }

  .item-new.colum.w-2 {
    width: 50%
  }

  .item-new .full-dis {
    height: auto;
  }

  .item-new .t-right {
    margin-top: 0px !important;
  }

  .wrap-table {
    overflow-x: scroll !important;
  }

  .table-offer-his {
    width: 800px;
  }
}

@media screen and (max-width: 500px) {
  .dis-500 {
    display: none;
  }
}