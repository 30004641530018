.features {
  * {
    margin: 0;
    padding: 0;
  }

  max-width: 1166px;
  margin: 40px auto 0 auto;

  @media (max-width: 1024px) {
    padding: 0 32px;
    margin-top: 20px;
  }

  .title-fea {
    font-family: Poppins;
    font-weight: 600;
    font-size: 48px;
    line-height: 49.92px;
    letter-spacing: 0%;
    text-align: center;
    margin-bottom: 46px;
    color: #25273D;

    @media (max-width: 1024px) {
      font-size: 28px;
      line-height: normal;
      margin-bottom: 20px;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 22px;

    @media (max-width: 1024px) {
      flex-direction: column;
    }

    .feature-box {
      flex: 1;
      padding: 16px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      border-radius: 24px;
      transition: 0.3s ease;
      cursor: pointer;

      @media (max-width: 1024px) {
        width: 100%;
        gap: 8px;
      }

      .amount {
        transition: 0.3s ease;
        font-family: Poppins;
        font-weight: 500;
        font-size: 60px;
        line-height: 90px;
        letter-spacing: 0%;
        text-align: center;
        color: #F3BA2F;

        @media (max-width: 1024px) {
          font-size: 48px;
          font-weight: normal;
        }

      }

      &:hover {
        background: #e7e7e9;

        .amount {
          color: #000000;
        }
      }

      img {
        width: 342px;
        display: block;
        max-width: 100%;


        @media (max-width: 1024px) {
          width: 100%;
        }
      }

      .title {
        font-family: Poppins;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0%;
        text-align: center;
        margin-bottom: 0;
        color: #1E2329;

        @media (max-width: 1024px) {
          font-size: 20px;
          font-weight: normal;
        }
      }

      .content {
        font-family: Poppins;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0%;
        text-align: center;
        color: #787A9B;

        @media (max-width: 1024px) {
          font-size: 16px;
          font-weight: normal;
        }
      }

      &.end {
        margin-top: 22px;
        flex: unset;

        @media (max-width: 1024px) {
          margin-top: 0;
        }
      }

      &.big {
        flex: unset;
        flex-direction: row;
        gap: 8px;
        margin-top: 22px;


        @media (max-width: 1024px) {
          flex-direction: column-reverse;

          .amount,
          .title,
          .content {
            text-align: center !important;
            margin-left: unset !important;
          }
        }

        .left {
          width: 388px;
          display: flex;
          flex-direction: column;
          gap: 8px;

          @media (max-width: 1024px) {
            width: 100%;
          }

          .amount,
          .title,
          .content {
            text-align: end;
            margin-left: auto;
          }

          .content {
            max-width: 350px;

            @media (max-width: 1024px) {
              max-width: unset;
            }
          }

          .title {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}