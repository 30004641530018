.btn-apt {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  border: 0px solid transparent;
  border-radius: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.img-wallet {
  width: 50px;
  height: 50px;
}

.modal-apt-connect .ant-modal-body {
  display: flex;
  justify-content: space-evenly;
}

.modal-apt-connect .ant-modal-header {
  border-radius: 12px 12px 0 0;
}
.modal-apt-connect .ant-modal-content {
  border-radius: 12px;
}
.petra,
.pontem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.apt-wallet {
  border: 1px solid red;
  border: 0.9px solid #c9d6e6;
  padding: 20px 40px 10px 40px;

  border-radius: 5px;
}
.apt-wallet:hover {
  background: #f9f9fb;
  cursor: pointer;
}
.apt-wallet:hover .text-wallet {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.text-wallet {
  transition: 0.5s ease;
  color: #1e2329;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}
.titleModalAPT {
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  margin-top: 40px;
  color: #1e2329;
}
