.section-ignite {
    margin-top: 78px;
    background-position: top;
    background-repeat: no-repeat;
}

.section-ignite .main-banner {
    padding: 100px 0;
}

.section-ignite .main-banner .title {
    color: #25273D;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
    margin-bottom: 30px;
}

.section-ignite .main-banner .gr-button-apply .btn-apply-ignite {
    width: 191px;
    height: 50px;
    border-radius: 6px;
    background: #1E2329;
    box-shadow: 0px 6px 20px 0px rgba(198, 198, 198, 0.44);
    border: 0px solid transparent;
    color: #FFF;
    transition: 0.3s ease;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
    cursor: pointer;
}
.section-ignite .main-banner .gr-button-apply .btn-apply-ignite:hover {
    background: var(--main, linear-gradient(93deg, #FFD75A -40.87%, #EB0029 170.93%));
/* game1 */
box-shadow: 0px 10px 20px 0px rgba(255, 109, 76, 0.25);

}
.main-program .content .gr-button-apply .btn-apply-ignite:hover {
    background: var(--main, linear-gradient(93deg, #FFD75A -40.87%, #EB0029 170.93%));
    /* game1 */
    box-shadow: 0px 10px 20px 0px rgba(255, 109, 76, 0.25);
}
.section-ignite .main-banner .gr-button-apply {
    margin-bottom: 80px;
}

.section-ignite .main-banner .txt {
    color: #7A8195;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.68px;
    text-transform: uppercase;
    margin-bottom: 40px;
}

.main-program {
    padding: 20px;
}

.main-program .content {
    text-align: left;
}

.main-program .content .title {
    color: #25273D;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 104%;
    margin-bottom: 30px;
}

.main-program .content .desc {
    color: #787A9B;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 149%;
    margin-bottom: 20px;
}

.fw-700 {
    font-weight: 700;
}

.main-program .content .gr-button-apply .btn-apply-ignite {
    width: 160px;
    height: 50px;
    border-radius: 6px;
    background: #1E2329;
    box-shadow: 0px 6px 20px 0px rgba(198, 198, 198, 0.44);
    border: 0px solid transparent;
    color: #FFF;
    transition: 0.3s ease;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
    cursor: pointer;
    margin-right: 10px;
}

.main-program .content .gr-button-apply .btn-apply-becom {
    width: 220px;
    height: 50px;
    border-radius: 6px;
    border-radius: 6px;
    border: 1px solid #1E2329;
    background: #FFF;
    color: #1E2329;
    transition: 0.3s ease;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
    cursor: pointer;
    margin-right: 10px;
}

.main-program .columns {
    align-items: center;
}

.main-out-partner {
    padding: 60px 0;
    background: #F9FAFE;
}

.main-out-partner .title {
    color: #25273D;

    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 104%;
    margin-bottom: 25px;
}

.main-out-partner .desc {
    color: #787A9B;

    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.5%;
    margin-bottom: 25px;
}

.main-out-partner .txt {
    color: #7A8195;

    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.68px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.row-path {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.row-path .item {
    width: 14.2%;
}

.main-feature-key {
    padding: 60px;
    background-repeat: no-repeat;
    background-size: cover;
}

.row-feature-key .title {
    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 104%;
    margin-bottom: 30px;
}

.row-feature-key .item-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    transition: 0.3s ease;
    border: 2px solid transparent;
    cursor: pointer;
}
.row-feature-key .item-row:hover {
    border: 2px solid #FB8F8F;
}
.row-feature-key .item-row .box-icon {
    width: 150px;
}

.row-feature-key .item-row:hover .text .txt {
    color: #FB8F8F;
}

.row-feature-key .item-row .text {
    width: calc(100% - 170px);
}

.row-feature-key .item-row .text .txt {
    color: #FFF;

    text-align: left;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 104%;
    margin-bottom: 10px;
    transition: 0.3s ease;
}

.row-feature-key .item-row .text .desc {
    color: #BABCDB;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 149%;
}

.fw-600 {
    color: #F1F2FF;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 149%;
}

.row-feature-key .item-row {
    border-radius: 20px;
    background: rgba(83, 91, 112, 0.20);
    padding: 20px;
    backdrop-filter: blur(5px);
    margin-bottom: 15px;
    text-align: left;
}

.row-feature-key .item-row:last-child {
    margin-bottom: 0;
}

.main-choose {
    padding: 60px;
    background: linear-gradient(180deg, #FFF 0.41%, rgba(249, 250, 254, 0.63) 26.85%, rgba(249, 250, 254, 0.70) 70.77%, #FFF 97.6%);
}

.row-choose .title {
    color: #25273D;

    text-align: center;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 104%;
    margin-bottom: 40px;
}

.row-choose .content {
    text-align: left;
    border-radius: 20px;
    border: 2px solid #FB8F8F;
    padding: 20px 30px;
    background: rgba(255, 255, 255, 0.90);
    transition: 0.3s ease;
    /* chính */
    box-shadow: 0px 6px 20px 0px rgba(120, 122, 155, 0.18);
    cursor: pointer;
    height: 100%;
}
.row-choose .content:hover {
    transform: translateY(-10px);
}
.row-choose .content .icon img {
    width: 30px;
}

.row-choose .content .icon {
    margin-bottom: 15px;
}

.row-choose .content .txt {
    color: #FB8F8F;

    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 168%;
    /* 33.6px */
    letter-spacing: 0.4px;
    margin-bottom: 15px;
}

.row-choose .content .desc {
    color: #787A9B;

    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 168%;
}

.row-choose .colum:nth-child(2) .content .txt {
    color: #C58DFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 168%;
    /* 33.6px */
    letter-spacing: 0.4px;
}

.row-choose .colum:nth-child(3) .content .txt {
    color: #F2B005;

    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 168%;
    /* 33.6px */
    letter-spacing: 0.4px;
}

.row-choose .colum:nth-child(4) .content .txt {
    color: #379EFE;

    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 168%;
    /* 33.6px */
    letter-spacing: 0.4px;
}

.row-choose .colum:nth-child(5) .content .txt {
    color: #13E4A6;

    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 168%;
    /* 33.6px */
    letter-spacing: 0.4px;
}

.row-choose .colum:nth-child(2) .content {
    border-radius: 20px;
    border: 2px solid #C58DFF;
    background: rgba(255, 255, 255, 0.90);

    /* chính */
    box-shadow: 0px 6px 20px 0px rgba(120, 122, 155, 0.18);
}

.row-choose .colum:nth-child(3) .content {
    border-radius: 20px;
    border: 2px solid #F2B005;
    background: rgba(255, 255, 255, 0.90);

    /* chính */
    box-shadow: 0px 6px 20px 0px rgba(120, 122, 155, 0.18);
}

.row-choose .colum:nth-child(4) .content {
    border-radius: 20px;
    border: 2px solid #379EFE;
    background: rgba(255, 255, 255, 0.90);

    /* chính */
    box-shadow: 0px 6px 20px 0px rgba(120, 122, 155, 0.18);
}

.row-choose .colum:nth-child(5) .content {
    border-radius: 20px;
    border: 2px solid #13E4A6;
    background: rgba(255, 255, 255, 0.90);

    /* chính */
    box-shadow: 0px 6px 20px 0px rgba(120, 122, 155, 0.18);
}

.row-choose .columns {
    justify-content: center;
}

.table-choose-bottom {
    margin-top: 140px;
}

.gr-button-apply .btn-apply-ignite.now {
    width: 191px;
    height: 50px;
    border: 0px solid transparent;
    border-radius: 6px;
    background: var(--main, linear-gradient(93deg, #FFD75A -40.87%, #EB0029 170.93%));
    /* game1 */
    box-shadow: 0px 10px 20px 0px rgba(255, 109, 76, 0.25);
    color: #FFF;
    transition: 0.3s ease;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
    cursor: pointer;
}

.content-key .desc {
    color: #787A9B;
    margin-top: 10px;
    margin-bottom: 30px;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 149%;
}

.content-key {
    text-align: left;
    padding-left: 80px;
}

.fw-700 {
    color: #787A9B;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 149%;
}

.content-key .title {
    text-align: left;
    margin-bottom: 10px;
    line-height: 1.3;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;

}

.table-choose-bottom .columns {
    align-items: center;
}
@media screen and (max-width:768px) {
    .section-ignite .main-banner .title {
        font-size: 30px;
    }
    .section-ignite .main-banner {
        padding: 50px 0;
    }
    .main-program .content .title {
        font-size: 28px;
    }
    .main-program .content .desc {
        font-size: 14px;
    }
    .fw-700 {
        font-size: 14px;
    }
    .main-program .content .gr-button-apply .btn-apply-ignite {
        width: 140px;
        height: 44px;
    }
    .section-ignite .main-banner .gr-button-apply .btn-apply-ignite {
        width: 140px;
        height: 44px;
    }
    .main-out-partner .title {
        font-size: 28px;
    }
    .main-out-partner .desc {
        font-size: 14px;
    }
    .row-path .item {
        width: 33%;
    }
    .main-feature-key {
        padding: 50px 0;
    }
    .row-feature-key .title {
        font-size: 28px;
    }
    .row-feature-key .item-row {
        padding: 20x;
    }
    .row-feature-key .item-row .box-icon {
        width: 100%;
        margin-bottom: 10px;
    }
    .row-feature-key .item-row .text {
        width: 100%;
    }
    .row-feature-key .item-row .box-icon img {
        width: 100px;
        display: block;
        margin:  0 auto;
    }
    .row-feature-key .item-row .text .txt {
        font-size: 18px;
    }
    .row-feature-key .item-row .text .desc {
        font-size: 14px;
    }
    .main-choose {
        padding: 50px 0;
    }
    .row-choose .title {
        font-size: 28px;
    }
    .table-choose-bottom {
        margin-top: 40px;
        padding: 10px;
    }
    .content-key {
        padding-left: 0;
    }
    .content-key .desc {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .gr-button-apply .btn-apply-ignite.now {
        width: 150px;
        height: 44px;
    }
}