.content-profile-user .w-25 {
  width: 25% !important;
}
.content-profile-user .w-75 {
  width: 75% !important;
}
.content-profile-user .swap-left {
  text-align: left;
}
.content-profile-user .swap-left .box-img {
  width: 180px;
  margin-bottom: 10px;
  margin-top: -90px;
  background: #fff;
  border-radius: 6px;
}
.content-profile-user .swap-left .box-img img {
  width: 180px;
  height: 180px;
  object-fit: cover;
  padding: 5px;
  background: #fff;
  border-radius: 6px;
}
.content-profile-user .swap-left .name-rl {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.03em;
  color: #707a8a;
  margin-bottom: 15px;
}
.content-profile-user .swap-left .name-rl .rl {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  margin-left: 20px;
  letter-spacing: 0.03em;
  color: #1e2329;
}
.content-profile-user .swap-left .social-rl {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.content-profile-user .swap-left .social-rl img {
  width: 20px;
  margin-right: 15px;
}
.content-profile-user .swap-left .address-rl {
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.03em;
  margin-bottom: 15px;
  color: #707a8a;
}
.content-profile-user .swap-left .address-rl .address {
  display: flex;
  align-items: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;

  color: #1e2329;
  background: #f6f7fc;
  border-radius: 6px;
  padding: 5px 10px;
  margin-left: 10px;
}
.content-profile-user .swap-left .desc-rl {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;

  color: #707a8a;
}
.content-profile-user .swap-right {
  text-align: left;
}
.w-4 {
  width: calc(4 / 12 * 100%);
}
.content-profile-user .swap-right .tabs-market {
  margin-top: 90px;
  position: relative;
}
.content-profile-user .swap-right .tabs-market .ant-tabs-content-holder {
  margin-top: 60px;
}
@media screen and (max-width: 768px) {
  .content-profile-user .swap-right .tabs-market .ant-tabs-content-holder {
    margin-top: 40px;
  }
}
.content-profile-user .swap-right .tabs-market .swap-filter {
  position: absolute;
  left: 0;
  top: 70px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.filter-project {
  margin-left: 5px;
}
.filter-project
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #e9edf3;
  border-radius: 6px;
  background: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #1e2329;
}
.filter-project
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 14px;
}
.btn-filter {
  background: #eff2f5;
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  cursor: pointer;
  text-align: center;
  border: 0px solid transparent;
  color: #707a8a;
  width: 90px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activeFillter{
  background: #1E2329;
  color: #fff;
}
.btn-filter img {
  width: 20px;
  margin-right: 10px;
}
.swap-filter .search-project {
  margin-left: 5px;
}
.ant-popover {
  width: 400px !important;
}
.ant-popover-title {
  font-size: 18px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.ant-radio-wrapper {
  display: block;
}
.round-popover .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  margin-bottom: 7px;
  color: #1e2329;
}
.ant-popover .ant-popover-inner-content {
  padding: 0px 16px;
}
.ant-popover span.ant-radio + * {
  color: #707a8a;
}
.round-popover .ant-radio-checked:after {
  border: 1px solid #f2c611;
}
.round-popover .ant-radio-inner:after {
  background-color: #f2c611;
}
.round-popover .ant-radio-checked .ant-radio-inner {
  border-color: #f2c611;
}
.swap-filter .search-project {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .main-profile-user .content-profile-user .w-25 {
    width: 100% !important;
  }
  .content-profile-user .swap-left .box-img {
    margin-top: 0;
  }
  .content-profile-user .swap-right .tabs-market {
    margin-top: 10px;
  }
  .content-profile-user .swap-right .tabs-market .swap-filter {
    position: relative;
    top: 0;
    left: 0;
  }
  .main-profile-user .content-profile-user .w-75 {
    width: 100% !important;
  }
  .swap-filter .search-project {
    width: 130px;
  }
  .w-4 {
    width: 50%;
  }
  .content-profile-user .swap-right .tabs-market .ant-tabs-content {
    margin-top: 0;
  }
  .baanner-user img {
    height: 200px;
    object-fit: cover;
  }
}
