.nft-name.new .name{
padding-left: 8px;
}
.nft-name.new .icon-smail img{
    padding-top: 2px;
}
.img-copy-new img{
    width: 16px;
    height: 16px;
}
.owner-nft-add {
    border-radius: 6px;
    background: #F3F4F6;
    width: max-content;
    padding: 4px 10px;
}
span.img-copy-new {
    padding-left: 5px;
}
.num-id {
    color: #1E2329;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 4px;
}
.id-nft {
    padding-top: 15px;
}
.logo-price-token {
    width: 30px;
    height: 30px;
    background: #fff;
    padding: 3px;
    border-radius: 99px;
}
.title-price-nft {
    color: #707A8A;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-bottom: 5px;
}
.num-price-token {
    color: #1E2329;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 12px;
    padding-right: 6px;

}
.num-price-usd {
    color: #707A8A;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-top: 5px;
}
.price-new-nft {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
}
.box-buy {
    border-radius: 6px;
    background: #F3F4F6;
    padding: 20px;
    margin-top: 20px;
}
.btn-buy-nft-new {
    width: 100%;
    border-radius: 6px;
    background: #1E2329;
    border: none;
    height: 42px;
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.9px;
}
.btn-buy-nft-new:hover{
    cursor: pointer;
}
.btn-buy-nft-new.dis{
    background: #bfbfbf;
    cursor: no-drop;
}
.btn-buy-nft-new:disabled {
    background: #bfbfbf;
    cursor: no-drop;
}
.nft-attribute .ant-collapse-header{
flex-direction: row-reverse;
}
.nft-attribute .anticon.anticon-right.ant-collapse-arrow{
  background: black;
    border-radius: 99px;
    padding: 3px;
    margin-right: 0px
}
.nft-attribute .anticon svg {
    display: inline-block;
    font-size: 11px;
    color: #fff;
}
.nft-attribute .ant-collapse-header-text{
color: #1E2329;
font-family: 'Poppins';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.nft-attribute .ant-collapse-borderless{
background: #F3F4F6;
}

.nft-attribute {
    border-radius: 6px;
    margin-top: 12px;
    border-radius: 6px;
    border: 1px solid #E9EFF5;
    background: #F3F4F6;
}
.wrap-item-att{
        display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
}
.att-col {
    width: calc(4/12 *100%);
    padding: 5px;
}
.att-item {
    background: #fff;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
}
.att-title1 {
    color: #7A8195;
    text-align: center;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
}
.att-title2 {
    color: #1E2329;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.att-title2 span{
    background: var(--main, linear-gradient(93deg, #FFD75A -40.87%, #EB0029 170.93%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.att-title3 {
    color: #7A8195;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
}
.detai-new {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}
.title-left {
    color: #707A8A;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.content-right-nft {
    color: #1E2329;
    text-align: right;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.content-detail-nft-new {
    background: #fff;
    border-radius: 6px;
    padding: 18px;
}
img.chain-logo-nft-new {
    width: 20px;
    height: 20px;
    margin-right: 3px;
}
.w-12{
    width: calc(12 / 12 * 100%);
    padding: 0 10px;
}
table.table-active-nft {
    width: 100%;
}
.table-nft-header{
    border-radius: 6px;
    border: 0.9px solid #E9EDF3;
    background: #F6F7FC;
    height: 50px;
}
tr.table-nft-body {
    height: 50px;
}
.title-sugg {
    color: #1E2329;
    font-family: 'Poppins';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 104%;
    text-align: start;
    padding-top: 30px;
    padding-bottom: 30px;
}
.marquee-nft-detail {
    color: #1E2329;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: start;
    padding-top: 8px;
}
.artis-item-detail {
    color: #1E2329;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    padding-top: 8px;
}
span.t-left-detail {
    color: #707A8A;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}
.number-detail.d-flex {
    color: #1E2329;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 4px;
}
.icon-detail {
    width: 20px;
    height: 20px;
}
.price-item-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
}
.t-right-detail {
    display: flex;
    align-items: center;
    gap: 3px;
}
.guide-market {
    background: #ffffff;
    border: 0.9px solid #e9eff5;
    border-radius: 6px;
    padding: 7px 7px 15px 7px;
    transition: 0.3s ease;
    overflow: hidden;
}
.columns-new {
    width: calc(12 / 12 * 100%);
    display: flex;
    flex-wrap: wrap;
}
.btn-view-co {
    width: 100%;
    padding-top: 10px;
}
button.view-collec {
    border-radius: 6px;
    background: #F3F3F4;
    border: none;
    color: #1E2329;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
    padding: 10px 20px;
}
.view-collec:hover{
    cursor: pointer;
}
.btn-offer {
    height: 35px;
    border: 1px solid rgba(30, 35, 41, 1);
    border-radius: 6px;
    background: #fff;
    cursor: pointer;
    padding: 0 20px;
    font-size: 13px;
    color: #1E2329;
}
.btn-offer:disabled {
    background: #bfbfbf;
    color: #fff;
    cursor: no-drop;
    border: none;
}
.btn-open-link, .btn-open-link a {
    color: #bfbfbf;
}
.btn-open-link a :hover{
    color: #1890ff;
    box-shadow: 0 1px #1890ff;
}
.set-price-input.duration{
padding: 20px 0;
}
input.input-set-price {
    padding:0 10px;
}
.pb-10 {
    padding-bottom: 10px;
}
@media screen and (max-width: 768px) {
    .content-detail-nft-new{
        overflow-x: scroll;
    }
    table.table-active-nft{
        width: 800px;
    }
    .title-sugg{
        margin: 0 auto;
    }
    .att-item{
        height: 100px;
    }
    .att-title2{
        font-size: 13px;
    }
    .att-title2 span{
        font-size: 13px;
    }
}