.marquee-custom {
  overflow-y: hidden;

  .marquee-item {
    transition: 0.3 ease;
  }
}

@media (max-width: 1024px) {
  .new-raise {
    @media (max-width: 1024px) {
      padding: 20px 0 !important;
    }
  }
}