.bsc-upload select,
.bsc-upload option {
  -webkit-appearance: none;
}
.bsc-upload .labelChannel,
.bsc-upload .labelCate {
  margin-top: 10px;
}
.c-upload-image {
  /* background-color: #323131; */
  border: 0.75px dashed #687788;
  /* max-width: 330px; */
  width: 100%;
  height: 194px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}
.c-upload-image img {
  width: auto;
}
.c-upload-image > p {
  color: #494848;
  font-size: 16px;
  margin-top: 15px;
}
.c-upload-image > .fileSelected {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}
.inputFile {
  display: none;
}
.c-checkbox-bscs-wrapper {
  display: block;
  position: relative !important;
  cursor: pointer;
  padding-left: 35px !important;
  margin-bottom: 12px !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input.c-checkbox-bscs-hiden {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
input[type="checkbox"].c-checkbox-bscs-hiden:checked + .c-checkbox-bscs:after {
  display: block;
}
.c-checkbox-bscs {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  border-radius: 5px;
  border: 1px solid #5e584b;
  background-color: #323131;
}
.c-checkbox-bscs::after {
  content: "";
  position: absolute;
  display: none;
}

.c-checkbox-bscs::after {
  left: 7px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.heading {
  font-size: 40px;
  line-height: 138%;
  font-weight: 500;
  color: #1e2329;
}
.upload-des {
  font-size: 16px;
  line-height: 138.5%;

  color: #707a8a;
}
.bsc-upload {
  background: #ffffff;
  border: 0.9px solid #eef0f7;
  box-shadow: 0px 10px 30px rgb(145 153 162 / 16%);
  border-radius: 6px;
  padding: 40px;
}
.rowNumber {
  display: flex;
  padding-bottom: 15px;
}
.rowLeft {
  text-align: start;
  width: 40%;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1e2329;
  padding-left: -17px;
}
.rowright {
  width: 60%;
}
.lableAll {
  display: flex;
  justify-content: space-between;
}
label.labelLeft {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1e2329;
}
label.labelRight {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #707a8a;
}
.rowLeftSmall {
  font-weight: 400;
  font-size: 16px;
  line-height: 145.5%;
  width: 50%;

  color: #707a8a;
}
.textUpload {
  font-size: 14px !important;
  line-height: 145.5%;
  text-align: center;
  color: #707a8a !important;
}
.form-group .form-control.f-control {
  background: #eff2f5;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 14px;
  padding: 10px;
}
.form-group .form-control.f-control.cus {
  height: 105px;
}
.form-group .form-control {
  font-size: 14px;
}
.row-line {
  position: relative;
}
.row-line img {
  position: absolute;
  right: 15%;
  top: 50%;
  width: 10px;
  transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  .bsc-upload {
    padding: 15px;
  }
  .rowNumber {
    flex-wrap: wrap;
  }
  .rowLeft {
    width: 100%;
    padding-bottom: 10px;
  }
  .rowright {
    width: 100%;
  }
  .row-line {
    margin-bottom: 20px;
  }
  .row-line:nth-child(2) {
    margin-bottom: 0;
  }
  .rowLeftSmall {
    width: 100%;
    line-height: 1.5;
    margin-bottom: 5px;
  }
  .heading {
    font-size: 26px;
  }
}
.form-custom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.upload-disable {
  opacity: 0.6;
  pointer-events: none;
}
.gr-btn-mint {
  display: flex;
  justify-content: center;
}
.cancel-mint {
  width: 180px;
}
.bodyUpload {
  position: relative;
}
@media screen and (max-width: 768px) {
  button.back-mint {
    border: none;
    position: absolute;
    top: 110px !important;
    left: 0 !important;
    display: flex;
    background: none;
    align-items: center;
  }
}

button.back-mint {
  border: none;
  position: absolute;
  left: -150px;
  top: 4px;
  display: flex;
  background: none;
  align-items: center;
}
.text-back {
  padding-left: 5px;
  color: #1e2329;

  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
}
.icon-mint {
  box-shadow: 0px 6px 20px rgb(158 158 158 / 44%);
  background: #fff;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 6px;
  /* padding: 2px; */
}
button.back-mint:hover {
  cursor: pointer;
}
