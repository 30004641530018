.raise-apply {
  * {
    padding: 0;
    margin: 0;
  }

  margin-top: 80px;

  .container {
    margin: 100px auto 40px auto;
    width: 1166px !important;
    max-width: 1166px;

    @media (max-width: 768px) {
      width: 100% !important;
      padding: 16px;
      margin-top: 40px;
    }
  }

  .flex {
    display: flex;
    gap: 60px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 20px;
    }

    .raise-left {
      .raise-text {
        flex: 1;
        font-family: Poppins;
        font-weight: 600;
        font-size: 50px;
        line-height: 123%;
        letter-spacing: 0%;
        text-align: left;
        color: #25273d;

        span {
          background: linear-gradient(93.41deg,
              #ffd75a -40.87%,
              #ffb800 170.93%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        @media (max-width: 768px) {
          font-size: 28px;
        }
      }

      .btn-apply {
        font-family: Poppins;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        letter-spacing: 6%;
        text-align: center;
        color: #ffffff;
        background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
        box-shadow: 0px 10px 20px 0px #ff6d4c40;
        border-radius: 6px;
        padding: 13px 21px 14px 21px;
        text-align: center;
        width: fit-content;
        margin-top: 24px;
      }

      img {
        margin-top: 83px;
      }
    }

    .raise-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .step-new {
        padding: 24px;
        border: 1px solid #e9eff5;
        backdrop-filter: blur(10px);
        background: #f8f9fb;
        display: flex;
        gap: 14px;
        border-radius: 16px;
        transition: background 0.3s ease, transform 0.3s ease;
        cursor: pointer;
        position: relative;
        overflow: hidden;

        /* Ensure children (including text) stay above */
        >* {
          position: relative;
          z-index: 2;
        }

        /* Background image for hover effect */
        &::after {
          content: "";
          position: absolute;
          inset: 0;
          background: url("../../../../public/images/new/apply/hover_bg.svg") no-repeat right / cover;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
          z-index: 1;
          /* Behind text */
        }

        .left {
          font-family: Poppins;
          font-weight: 700;
          font-size: 30px;
          line-height: 168%;
          letter-spacing: 2%;
          text-align: center;
          color: #1e2329;
          transition: color 0.3s ease, background 0.3s ease;

          @media (max-width: 768px) {
            font-size: 24px;
          }
        }

        &:hover {
          &::after {
            opacity: 1;
          }

          .left {
            background: linear-gradient(93.41deg,
                #ffd75a -40.87%,
                #ffb800 170.93%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .right {
          .title {
            font-family: Poppins;
            font-weight: 600;
            font-size: 24px;
            line-height: 168%;
            letter-spacing: 0%;
            color: #25273d;
            text-align: start;

            @media (max-width: 768px) {
              font-size: 16px;
            }
          }

          .content {
            color: #787a9b;
            font-family: Poppins;
            font-weight: 400;
            font-size: 16px;
            line-height: 168%;
            text-align: start;
            letter-spacing: 0%;

            @media (max-width: 768px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.btn-apply:hover {
  cursor: pointer;
}