.title-new {
  font-family: Poppins;
  font-weight: 600;
  font-size: 48px;
  line-height: 49.92px;
  letter-spacing: 0%;
  text-align: center;

  color: #25273D;
  margin-bottom: 36px;

  @media (max-width: 1024px) {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    font-size: 28px;
    line-height: normal;
  }
}

div.custom-marquee-box {
  background: url('../../../../public/images/new/bg.png');
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 33px;
  padding-bottom: 33px;

  .custom-marquee-p {
    &.top {
      margin-bottom: 40px;
    }

    @media (max-width: 1024px) {
      &.top {
        margin-bottom: 24px;
      }
    }
  }

  .marquee-item {
    img {
      width: 239px;
      height: 100px;

      @media (max-width: 1024px) {
        width: 120px;
        height: 50px;
        /* border-radius: 6px; */
      }
    }
    margin: 0 20.5px;
    transition: 0.3s ease;
    border-radius: 16px;
    border: 1px solid transparent;

    @media (max-width: 1024px) {
      margin: 0 12px;
      border-radius: 6px;
    }

    &:hover {
      cursor: pointer;
      border: 1px solid #FF9B1B;
    }
  }
}

.new-partner {
  @media (max-width: 1024px) {
    padding-top: 20px !important;
  }
}