.mar-t-10 {
  margin-top: 15px;
}
.nft-boots .title-boots {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 0.5px solid #c9cbcd;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: 0.03em;
  position: relative;
  color: #1e2329;
}
.nft-boots .title-boots:before {
  content: "";
  background: #1e2329;
  width: 40%;
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 0;
}
.nft-boots .item-boots {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #707a8a;
}
.nft-boots .item-boots .number {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 0.03em;

  color: #1e2329;
}
.content-profile-user {
  position: relative;
}

.content-profile-user .create-atwork.res {
  top: 10%;
}
.pd-top-10 {
  padding-top: 10px;
}
.btn-unstake:active,
.btn-bsc-primary:active {
  transform: translateY(4px);
}
.pd-top-20 {
  padding-top: 20px;
}
.align-center {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .content-profile-user.res.tabs-artwork
    .swap-right
    .tabs-market
    .ant-tabs-content-holder {
    margin-top: 0px;
    padding-top: 0px;
  }
}
.btn-edit {
  background: #ffffff;
  border: 0.9px solid rgba(112, 122, 138, 0.17);
  box-shadow: 0px 6px 20px rgba(158, 158, 158, 0.44);
  border-radius: 20px;
  padding: 10px 20px 10px 20px;
  cursor: pointer;
}

.btn-edit img {
  width: auto;
  padding-right: 5px;
}
