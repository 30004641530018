.main-stake-pool-v2 {
    background-position: top !important;
    padding-bottom: 50px;
    padding-top: 130px;
    background-size: contain !important;
}

.item-info {
    display: flex;
    justify-content: center;
    gap: 15px;
    width: 80%;
    margin: 0 auto;
}

.item-1 {
    width: 30%;
    border-radius: 6px;
    padding: 20px 60px 20px 60px;
    background: linear-gradient(90deg, #FFF9D9 -12.7%, #FFD9BD 112.66%);
}

.big-title {
    color: #25273D;
    text-align: center;
    font-family: "Poppins";
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
    padding-bottom: 30px;
}

.title-item {
    color: #707A8A;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.num-item {
    color: #1E2329;
    text-align: center;
    font-family: "Poppins";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.tabs-stake-pool.v2 {
    padding-top: 35px;
}

.wrap-all {
    width: 80%;
    height: auto;
    border-radius: 6px;
    /* background: linear-gradient(90deg, #FFF9D9 -12.7%, #FFD9BD 112.66%); */
    background: #F4F5FB;
}

.stake {
    display: flex;
    gap: 20px;
}

.action-pool {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    width: 20%;
}

.your-stake {
    border-radius: 6px;
    border: 0.9px solid #E9EDF3;
    /* background: linear-gradient(90deg, #FFF9D9 -12.7%, #FFD9BD 112.66%); */
     background: #F4F5FB;
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.your-stake.acc {
    border-radius: 6px;
    border: 0.9px solid #E9EDF3;
    /* background: linear-gradient(90deg, #FFF9D9 -12.7%, #FFD9BD 112.66%); */
    background: #F4F5FB;
    height: 50%;
}

.your-stake .title {
    color: #707A8A;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.your-stake .num {
    color: #1E2329;
    text-align: center;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.action-button {
    display: flex;
    flex-direction: column;
    gap: 13px;
    width: 90%;
    padding-top: 15px;
}

.btn-action {
    width: 100%;
    height: 50px;
}

.btn-action button {
    width: 100%;
    height: 100%;
}

.btn-stake-reward.dis {
    border: none;
    border-radius: 6px;
    background: hsl(240, 15%, 91%);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);

    color: #B6B8C3;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}

.btn-withdraw-reward.dis {
    border: none;
    border-radius: 6px;
    background: #E4E4EB;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);

    color: #B6B8C3;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}

.btn-stake-reward {
    border: none;
    border-radius: 6px;
    background: #1e2329;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);

    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}

.btn-withdraw-reward {
    border: none;
    border-radius: 6px;
    background: #1e2329;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);

    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}

.btn-stake-reward:hover {
    cursor: pointer;
}

.btn-withdraw-reward:hover {
    cursor: pointer;
}

.btn-stake-reward.dis:hover {
    cursor: not-allowed;
}

.btn-withdraw-reward.dis:hover {
    cursor: not-allowed;
}

.stake-your-token {
    color: #1E2329;
    text-align: center;
    font-family: "Poppins";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 30px;
}

img.img-step-stake {
    width: 30px;
    height: 30px;
    display: block;
}

.content-stake .ant-steps-icon {
    line-height: 0px !important;
}

.content-stake {
    padding: 15px 30px 30px 30px;
}

.content-stake .ant-steps-item-title:after {
    background-color: #AAB6CD !important;
}

/* .content-stake .ant-steps-item-active .ant-steps-item-title:after {
    background-color: #ff9100ed !important;
} */
.content-stake .ant-steps-item-title {
    font-size: 16px;
}

.content-stake .ant-steps-item-active .ant-steps-item-title {
    background: var(--main, linear-gradient(93deg, #FFD75A -40.87%, #EB0029 170.93%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-ac {
    background: var(--main, linear-gradient(93deg, #FFD75A -40.87%, #EB0029 170.93%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content-step1 .title {
    color: #1E2329;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 25px;
}

.content-step1 .des {
    padding-top: 5px;
    color: #707A8A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
}

.item-step1 {
    display: flex;
    gap: 10px;
    padding-top: 15px;
}

.card-item {
    background: #fff;
    padding: 10px 5px 10px 5px;
    width: 25%;
    height: 200px;
    border-radius: 6px;
}

.title-card {
    color: #1E2329;
    text-align: center;
    font-family: "Poppins";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.check-card {
    padding-bottom: 10px;
}
.check-card img{
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 auto;
}

.check-card.acive img:nth-child(2){
    display: none;
}
.check-card.acive img:nth-child(1){
    display: block;
}

.check-card img:nth-child(1){
    display: none;
}

.content-card {
    color: #707A8A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 166.5%;
    padding: 10px 20px 10px 20px;
}
.check-rule .text:hover{
    cursor: pointer;
}
.check-real {
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.check-real:hover{
    cursor: pointer;
}

/* .check-real span {
    padding-top: 5px;
} */

.steps-action {
    gap: 15px;
    display: flex;
    width: 80%;
    padding-top: 20px;
    justify-content: center;
}

.btn-control:hover {
    cursor: pointer;
}

.btn-control {
    width: 120px;
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    background: #1e2329;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    text-align: center;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}
.btn-control.dis {
    width: 120px;
    padding: 10px 15px;
    border: none;
    border-radius: 6px;
    background: #E4E4EB;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
    color: #B6B8C3;
    text-align: center;
    font-family: "Poppins";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.9px;
}
.btn-control.dis:hover{
    cursor: not-allowed;
}

.check-real span {
    color: #707A8A;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.terms {
    color: #1E2329 !important;
}

.terms:hover {
    text-decoration: underline;
}

.content-step2 .title {
    color: #1E2329;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-top: 25px;
}

.content-step2 .des {
    padding-top: 5px;
    color: #707A8A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.content-input {
    width: 70%;
    background: #fff;
    margin: 0 auto;
    border-radius: 6px;
    padding: 20px 30px 20px 30px;
}

.amount-stake-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.amount-stake-title .title-left {
    color: #1E2329;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
}

.amount-stake-title .title-right {
    color: #707A8A;
    font-family: 'Poppins';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.13px;
}

.amount-stake-title .title-right span {
    color: #1E2329;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.16px;
}

.input-amount {
    position: relative;
}

.input-amount .amount-stake {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 6px;
    background: #EFF2F5;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10) inset;
    padding-left: 15px;
    color: #000 !important;
}

.posi {
    gap: 3px;
    display: flex;
    position: absolute;
    /* left: 80%; */
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
}

.btn-max-stake {
    width: 50px;
    height: 36px;
    border: none;
    border-radius: 6px;
    background: #1E2329;
    color: #fff;
    font-weight: 600;
    text-align: center;
}

.palce-token {
    color: #616772;
    text-align: right;
    font-family: 'Poppins';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 138.5%;
}

.des-amount {
    color: #707A8A;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 157%;
    padding-top: 10px;
}

.staking-rule {
    text-align: start;
}

.des-amount li {
    text-align: start;
    padding-bottom: 3px;
}

.check-rule {
    text-align: start;
    display: flex;
    gap: 10px;
    align-items: center;
}

.un-fee {
    color: #1E2329;
}

.btn-max-stake:hover {
    cursor: pointer;
}

.content-step3 {
    padding-top: 25px;
}

.content-step3 .title {
    color: #1E2329;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 10px;
}

.content-step3 .des {
    color: #707A8A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.autho-content {
    margin: 0 auto;
    background: #fff;
    border-radius: 6px;
    width: 70%;
    padding: 30px 20px 30px 20px;
}

.content-step4 .title {
    padding-top: 45px;
    color: #1E2329;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 25px;
}

.autho-title {
    color: #707A8A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.autho-please {
    color: #707A8A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.autho-link {
    color: #229ED9;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
}

.autho-progress {
    width: 70%;
    margin: 0 auto;
    padding-bottom: 5px;
}

.autho-link:hover {
    text-decoration: underline;
}

.autho-progress .ant-progress-text {
    display: none;
}

.content-step4 .des {
    color: #707A8A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.confirm-content {
    padding: 60px 30px;
    background: #fff;
    width: 60%;
    margin: 0 auto;
    border-radius: 6px;
}

.cf-content1 {
    color: #707A8A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
}

.content-step5 .des {
    color: #707A8A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.content-step5 .title {
    padding-top: 25px;
    color: #1E2329;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.success-content {
    background: #fff;
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 6px;
}

.img-success img {
    width: 75px;
    height: 75px;
}

.mid-content1 {
    color: #707A8A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
}

.mid-content2 {
    color: #707A8A;
    text-align: center;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 151.5%;
    padding-bottom: 10px;
}

.mid-content {
    color: #1E2329;
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.mid-content3:hover {
    text-decoration: underline;
}
.spin-stake .ant-spin{
position: relative;

}
.spin-stake .ant-spin::before{
    content: "";
    border: 4px solid #E4E4EB;
    width: 100%;
    height: 100%;
    border-radius: 99px;
    position: absolute;
}
.modal-confirm .ant-modal-header .ant-modal-title{
color: #1E2329;
padding-top: 20px;
text-align: center;
font-family: 'Poppins';
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.modal-text1{
    color: #707A8A;

text-align: center;
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 151.5%;
padding-bottom: 20px;
}
.spin-stake {
    text-align: center;
}
.modal-confirm  .ant-modal-content{
    border-radius: 6px;
}
.modal-confirm .ant-modal-header{
    border-radius: 6px;
}
.modal-confirm .ant-modal-close-x{
    display: none;
}
.modal-confirm .ant-modal-body{
    padding: 10px 0 30px 0;
}
.img-warning {
    width: 70px;
    height: 70px;
    margin: 0 auto;
}
.unstake-warning {
    padding: 60px 0 60px 0;
    border-radius: 6px;
    background: linear-gradient(0deg, rgba(254, 191, 67, 0.05) 0%, rgba(254, 191, 67, 0.05) 100%), #FFF;
    width: 70%;
    margin: 60px auto 60px auto;
}
.content-warning1 {
    padding-top: 20px;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: var(--waring, linear-gradient(223deg, #FAC668 -1.23%, #DDA134 102.46%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.content-warning2 {
    padding-top: 10px;
    text-align: center;
    font-family: 'Poppins';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: var(--waring, linear-gradient(223deg, #FAC668 -1.23%, #DDA134 102.46%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.content-input.unstake{
    width: 50%;
    padding-bottom: 50px;
    margin-top: 15px;
}
.posi-unstake {
    gap: 3px;
    display: flex;
    position: absolute;
    /* left: 80%; */
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
}
.title.title-unstake{
    padding-top: 20px;
}


@media (min-width: 1500px) {
    .container-stake {
        max-width: 1400px;
        width: 100%;
        margin: 0 auto;
        padding: 0 10px;
    }
}

input:focus {
    outline: none ;
    border: 1px solid rgba(250, 198, 104, 1) !important;
}
.count-down-unstake {
    padding-top: 3px;
}
.time-down {
    color: #1E2329;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width: 991px) {
    .big-title {
        font-size: 32px;
    }
    .item-1 {
        width: 100%;
    }
    .item-info {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    .container-stake {
        padding: 0 10px;
    }
    .stake {
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .wrap-all {
        width: 100%;
        height: 100%;
    }
    .action-pool {
        width: 100%;
        height: 100%;
    }
    .content-stake {
        padding: 15px;
    }
    .item-step1 {
        flex-wrap: wrap;
    }
    .card-item {
        height: 100%;
        width: 100%;
    }
    .action-pool {
        gap: 0;
    }
    .your-stake {
        height: 100%;
        padding: 10px;
        margin-bottom: 15px;
    }
    .your-stake .title {
        margin-bottom: 5px;
    }
    .btn-withdraw-reward {
        font-size: 13px !important;
    }
    .btn-action {
        height: 40px;
        width: 60%;
    }
    .btn-stake-reward {
        font-size: 13px !important;
    }
    .steps-action {
        width: 100%;
    }
    .content-input {
        width: 100%;
        padding: 15px;
    }
    .amount-stake-title {
        display: block;
        justify-content: space-between;
        padding-bottom: 10px;
        text-align: justify;
    }
    .autho-content {
        width: 100%;
        padding: 15px;
    }
    .autho-progress {
        width: 100%;
    }
    .autho-link {
        overflow: hidden;
        position: relative;
        display: inline-block;
        margin: 0 5px 0 5px;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
    .confirm-content {
        width: 100%;
        padding: 15px;
    }
    .success-content {
        width: 100%;
        padding: 15px;
    }
    .mid-content3 {
        overflow: hidden;
        position: relative;
        display: inline-block;
        margin: 0 5px 0 5px;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
    .action-button {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    }
    .unstake-warning {
        width: 100%;
        margin: 10px 0;
        padding: 15px;
    }
    .title.title-unstake {
        padding-top: 0;
    }
    .content-input.unstake {
        width: 100%;
    }
    .tabs-stake-pool .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {
        margin: 0;
        width: 100%;
    }
    .tabs-stake-pool .ant-tabs-tab {
        width: calc(4 / 12 * 100%);
    }
    .main-stake-pool-v2 {
        padding-bottom: 0;
    }
}