.box-img-eror{
    text-align: center;
}
.box-img-eror img {
    width: 600px;
}
.text-eror .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 138%;
    /* identical to box height, or 28px */
    text-align: center;

    color: #25273D;
    margin-top: 20px;
}
.text-eror .desc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138.5%;
    /* or 22px */

    text-align: center;
    margin-top: 20px !important;
    color: #787A9B;
    width: 491px;
    margin: 0 auto;
}
.button-back {
    margin-top: 38px;
}
.button-back .back-to {
    width: 210px;
    height: 50px;
    background: #1E2329;
    box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
    border-radius: 6px;
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    border: 0px solid transparent;
    cursor: pointer;
}
.button-back .back-to a {
    color: #fff;
}
@media screen and (max-width :768px) {
    .box-img-eror img {
        width: 100%;
    }
    .text-eror .desc {
        width: 100%;
    }
}