.launchpad {
  max-width: 1166px;

  * {
    margin: 0;
    padding: 0;
  }

  margin: 96px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../../../../public/images/home/bg.png');
  padding-top: 64px;
  padding-bottom: 64px;
  background-size: cover;

  @media (max-width: 1024px) {
    max-width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat:no-repeat;
    margin-left: 8px;
    margin-right: 8px;
  }

  .new-title {
    font-family: Poppins;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0%;
    color: #F5CB11;
    @media (max-width: 1024px) {
     font-size: 16px;
     line-height: normal;
    }
  }

  .new-desc {
    margin-top: 16px;
    font-family: Poppins;
    font-weight: 600;
    font-size: 40px;
    line-height: 45px;
    letter-spacing: 1%;
    text-align: center;
    color: #FFFFFF;
    @media (max-width: 1024px) {
      font-size: 28px;
      line-height: normal;
     }
  }

  .new-btn {
    margin-top: 36px;
    box-shadow: 0px 2px 24px 0px #F5CB11;
    background: linear-gradient(270deg, #F5CB11 0%, #FF9B1B 100%),
      linear-gradient(180deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0) 100%);
    cursor: pointer;
    transition: 0.3s ease;
    border-radius: 10px;
    padding-top: 13px;
    padding-bottom: 14px;
    font-family: Poppins;
    font-weight: 600;
    font-size: 15px;
    line-height: 22.5px;
    letter-spacing: 6%;
    text-align: center;
    color: #090B16;
    width: 235px;
  }
}