.main-startpool {
  padding-top: 132px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* padding-bottom: 50px; */
}
.main-startpool .content-startpool {
  text-align: left;
}
.main-startpool .content-startpool .top-startpool {
  padding-bottom: 80px;
}
.main-startpool .content-startpool .top-startpool.ido-pool {
  text-align: center;
}
.main-startpool .content-startpool .top-startpool .title {
  font-weight: 500;
  font-size: 40px;
  line-height: 138%;
  color: #1e2329;
  margin-bottom: 5px;
}
.main-startpool .content-startpool .top-startpool .desc {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 138.5%;
  color: #616772;
}
.tabs-startpool .tabs-round .ant-tabs-nav-list {
  background: #f6f7fc;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding: 5px;
}
.tabs-startpool .tabs-round .ant-tabs-tab {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;
  color: #707a8a;
  padding: 8px 25px;
}
.tabs-startpool .tabs-round .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}
.tabs-startpool .tabs-round .ant-tabs-tab.ant-tabs-tab-active {
  background: #1e2329;
  /* shadow tab */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  color: #fff;
}
.tabs-startpool
  .tabs-round
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #fff;
}
.tabs-startpool .tabs-round .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}
.tabs-startpool .tabs-round .ant-tabs-nav {
  margin: 0 auto;
  margin-bottom: 50px;
}
.main-round-pool .round-slide {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 20px;
  background: #f6f7fc;
  border: 0.9px solid #e9edf3;
  border-radius: 6px;
  text-align: left;
}
.main-round-pool .round-slide .item {
  width: 15%;
}
.main-round-pool .round-slide .item .name {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #000000;
}
.round-pool .guide-swap {
  width: 100%;
}
.round-pool .ant-collapse-arrow {
  position: absolute;
  right: 20px;
  top: 40px;
  background: #1e2329;
  padding: 5px;
  border-radius: 99px;
}
.round-pool .ant-collapse-arrow svg {
  fill: #fff;
}
.round-pool .guide-swap .item-round .box-img {
  display: flex;
}
.round-pool .guide-swap .item-round .box-img .icon-big {
  width: 30px;
}
.round-pool .guide-swap .item-round .box-img .icon-small {
  width: 30px;
  margin-left: -8px;
}
.round-pool .guide-swap.LP .item-round .box-img .icon-small {
  width: 42px;
  margin-left: -8px;
}
.round-pool .guide-swap .item-round .box-name {
  display: block;
  margin-left: 10px;
}
.round-pool .guide-swap .item-round .box-name .title-earn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #000000;
  display: block;
}
.round-pool .guide-swap .item-round .box-name .title-stake {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #707a8a;
}
.round-pool .guide-swap .item-round .name-project {
  display: flex;
  align-items: center;
}
/* .tabs-startpool .round-pool .ant-collapse-header {
  padding: 15px 40px;
} */
.round-pool .guide-swap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.round-pool .guide-swap .item-round .name-project .number {
  display: flex;
}
.round-pool .guide-swap .item-round .name-project .number .txt {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1e2329;
  display: flex;
}
.round-pool .guide-swap .item-round .name-project .number .symbol {
  font-weight: 400;
  font-size: 16px;
  /* identical to box height */
  color: #707a8a;
}
.main-color-pool {
  color: #c99400 !important;
}
.round-pool .guide-swap .item-round:nth-child(1) {
  width: 20%;
}
.round-pool .guide-swap .item-round:nth-child(2) {
  width: 16%;
}
.round-pool .guide-swap .item-round:nth-child(3) {
  width: 14%;
}
.round-pool .guide-swap .item-round:nth-child(4) {
  width: 18%;
}
.round-pool .guide-swap .item-round:nth-child(5) {
  width: 18%;
}
.round-pool .guide-swap .item-round:nth-child(6) {
  width: 14%;
}

.main-round-pool .round-slide .item:nth-child(1) {
  width: 20%;
}
.main-round-pool .round-slide .item:nth-child(2) {
  width: 16%;
}
.main-round-pool .round-slide .item:nth-child(3) {
  width: 14%;
}
.main-round-pool .round-slide .item:nth-child(4) {
  width: 18%;
}
.main-round-pool .round-slide .item:nth-child(5) {
  width: 18%;
}
.main-round-pool .round-slide .item:nth-child(6) {
  width: 14%;
}
.round-pool .ant-collapse {
  border: 0px solid #d9d9d9;
  background: #fff;
}
.round-pool .ant-collapse-content {
  border-top: 0.5px solid #cdd5e4;
}
.round-pool .ant-collapse > .ant-collapse-item {
  border-bottom: 0.5px solid #cdd5e4;
}
.round-pool .ant-collapse > .ant-collapse-item:hover {
  background: #f6f7fc;
  border-radius: 6px;
  border-bottom: none !important;
}
.main-swap-pool {
  background: linear-gradient(
    91.05deg,
    rgba(255, 224, 167, 0.29) -1.65%,
    rgba(255, 132, 153, 0.29) 116.33%
  );
  backdrop-filter: blur(10px);
  border-radius: 0px;
  padding: 35px 65px;
}
.round-pool .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.main-swap-pool .item-block .content-block .reward-in .txt-l {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
}
.main-swap-pool .item-block .content-block .reward-in .txt-r {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  text-align: right;
  letter-spacing: 0.01em;
  color: #1e2329;
  display: flex;
}
.main-swap-pool .item-block {
  width: 33.33%;
}
.main-swap-pool {
  display: flex;
  flex-wrap: wrap;
}
.main-swap-pool .item-block .content-block .reward-in {
  display: flex;
  justify-content: space-between;
}
.main-swap-pool .item-block .content-block .view-port span img {
  width: 15px;
  margin-left: 5px;
}
.main-swap-pool .item-block .content-block .txt-r img {
  width: 15px;
  margin-left: 5px;
  padding-bottom: 5px;
}
.main-swap-pool .item-block:nth-child(1) .content-block {
  padding-right: 50px;
}

.main-swap-pool .item-block .box-stake {
  background: #e9bcba;
  border-radius: 8px;
  padding: 10px 15px;
}
.main-swap-pool .item-block .box-stake .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #1e2329;
  margin-bottom: 22px;
}
.main-swap-pool .item-block .box-stake .title.res {
  margin-bottom: 5px;
}
.main-swap-pool .item-block .box-stake .box-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-swap-pool .item-block .box-stake .box-bottom .round-l .number-stake {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  text-align: right;
  letter-spacing: 0.01em;
  color: #1e2329;
  margin-bottom: 5px;
  display: flex;
}
.main-swap-pool .item-block .box-stake .box-bottom .round-l .number-stake span {
  font-weight: 400;
}
.main-swap-pool .item-block .box-stake .box-bottom .round-l .number-stake img {
  cursor: pointer;
  padding-top: 5px;
}
.main-swap-pool .item-block .box-stake .box-bottom .round-l .number-rw {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
}
.btn-harvest {
  background: #1e2329;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #ffffff;
  padding: 10px 25px;
  border: 0px solid transparent;
  cursor: pointer;
}
.btn-unstake {
  background: #f3f3f4;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #1e2329;
  padding: 10px 25px;
  border: 0px solid transparent;
  cursor: pointer;
}
.main-swap-pool .item-block:nth-child(2) .content-block {
  margin-right: 40px;
}
.btn-plus {
  padding: 10px 15px !important;
  margin-left: 10px;
}
.btn-connect-wl {
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.06em;
  padding: 13px 20px;
  color: #ffffff;
  width: 100%;
  margin: auto;
  border: 0px solid transparent;
}
.round-connect {
  margin-top: 10px;
}
.btn-w-full {
  width: 100%;
}
.btn-bsc-primary {
  background: #1e2329;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #ffffff;
  padding: 10px 25px;
  border: 0px solid transparent;
  cursor: pointer;
}
.btn-bsc-disabled {
  background: #e4e4eb;
  color: #b6b8c4;
  /* cursor: no-drop; */
  cursor: url(../../assets/images/cursor-disable.svg) 15 15, move !important;
  opacity: inherit !important;
}
.btn-bsc-icon-loading {
  -webkit-animation: btnSpin 2s infinite linear;
  -moz-animation: btnSpin 2s infinite linear;
  -o-animation: btnSpin 2s infinite linear;
  -ms-animation: btnSpin 2s infinite linear;
  animation: btnSpin 2s infinite linear;
  display: block;
  margin-left: 5px;
  height: 20px;
  width: 20px;
}
.justify-content-row {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@-webkit-keyframes btnSpin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes btnSpin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes btnSpin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes btnSpin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@keyframes btnSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.schedules-p-time img {
  width: auto;
}

.modal-schedule .ant-modal-footer {
  display: none;
}

.modal-schedule .ant-modal-title {
  margin: 0;
  text-align: center;
  color: #1e2329;
  word-wrap: break-word;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
}
.modal-schedule .ant-modal-body {
  background: linear-gradient(
    91.05deg,
    rgba(255, 224, 167, 0.29) -1.65%,
    rgba(255, 132, 153, 0.29) 116.33%
  );
  padding: 24px;
  color: #1e2329;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  border-radius: 0 0 12px 12px;
}
.table-modal {
  width: 100%;
  text-align: center;
  background: #fff;
}
.table-modal tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid #cdd5e4;
  padding: 10px;
}
.modal-schedule .ant-modal-content {
  box-shadow: 0px 10px 30px rgb(145 153 162 / 16%);
  border-radius: 8px;
}
.modal-schedule .ant-modal-header {
  border-radius: 8px;
}
.btn-schedule {
  background: transparent;
  padding: 0;
  border: 0 solid transparent;
  margin-top: -8px;
  pointer-events: auto;
}
.text.details {
  font-weight: 500;
  font-size: 14px;
  color: #1e2329;
}
.round-r {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-swap {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  align-items: center;
}
.tabs-startpool {
  position: relative;
}
.filter-swap .search-project {
  margin-bottom: 0;
}
.ant-switch-checked {
  background-color: #1e2329;
}
.main-round-pool .round-slide.LP .item:nth-child(1) {
  width: 22%;
}
.main-round-pool .round-slide.LP .item:nth-child(2) {
  width: 16%;
}
.main-round-pool .round-slide.LP .item:nth-child(3) {
  width: 28%;
}
.main-round-pool .round-slide.LP .item:nth-child(4) {
  width: 18%;
}
.main-round-pool .round-slide.LP .item:nth-child(5) {
  width: 16%;
}
.round-pool .guide-swap.LP .item-round:nth-child(1) {
  width: 22%;
}
.round-pool .guide-swap.LP .item-round:nth-child(2) {
  width: 14%;
}
.round-pool .guide-swap.LP .item-round:nth-child(3) {
  width: 30%;
}
.round-pool .guide-swap.LP .item-round:nth-child(4) {
  width: 18%;
}
.round-pool .guide-swap.LP .item-round:nth-child(5) {
  width: 16%;
}

.main-round-pool .round-slide.IDO .item:nth-child(1) {
  width: 26%;
}
.main-round-pool .round-slide.IDO .item:nth-child(2) {
  width: 16%;
}
.main-round-pool .round-slide.IDO .item:nth-child(3) {
  width: 16%;
}
.main-round-pool .round-slide.IDO .item:nth-child(4) {
  width: 26%;
}
.main-round-pool .round-slide.IDO .item:nth-child(5) {
  width: 16%;
}
.round-pool .guide-swap.IDO .item-round:nth-child(1) {
  width: 26%;
}
.round-pool .guide-swap.IDO .item-round:nth-child(2) {
  width: 16%;
}
.round-pool .guide-swap.IDO .item-round:nth-child(3) {
  width: 16%;
}
.round-pool .guide-swap.IDO .item-round:nth-child(4) {
  width: 26%;
}
.round-pool .guide-swap.IDO .item-round:nth-child(5) {
  width: 16%;
}
.search-project .ant-input-group .ant-input {
  width: 200px;
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .main-swap-pool {
    padding: 20px 20px;
  }
  .btn-bsc-primary,
  .btn-unstake {
    padding: 10px 20px;
  }
  .main-swap-pool
    .item-block
    .box-stake
    .box-bottom
    .round-l
    .number-stake
    span {
    font-size: 16px;
  }
  .round-pool .ant-collapse-arrow {
    right: 10px;
  }
  .search-project .ant-input-group .ant-input {
    width: 180px;
  }
}
@media screen and (max-width: 991px) {
  .round-pool .ant-collapse-arrow {
    right: 10px;
  }
  .main-swap-pool {
    padding: 30px 30px;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(1) {
    width: 38%;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(2) {
    width: 20%;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(3) {
    width: 18%;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(4) {
    display: none;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(5) {
    width: 24%;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(1) {
    width: 38%;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(2) {
    width: 20%;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(3) {
    width: 18%;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(4) {
    display: none;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(5) {
    width: 24%;
  }
  .search-project .ant-input-group .ant-input {
    width: 180px;
  }
  .main-round-pool .round-slide .item:nth-child(3) {
    display: none;
  }
  .main-round-pool .round-slide .item:nth-child(5) {
    display: none;
  }
  .round-pool .guide-swap .item-round:nth-child(3) {
    display: none;
  }
  .round-pool .guide-swap .item-round:nth-child(5) {
    display: none;
  }
  .main-round-pool .round-slide .item:nth-child(1) {
    width: 32%;
  }
  .main-round-pool .round-slide .item:nth-child(2) {
    width: 20%;
  }
  .main-round-pool .round-slide .item:nth-child(4) {
    width: 28%;
  }
  .main-round-pool .round-slide .item:nth-child(6) {
    width: 20%;
  }
  .round-pool .guide-swap .item-round:nth-child(1) {
    width: 32%;
  }
  .round-pool .guide-swap .item-round:nth-child(2) {
    width: 20%;
  }
  .round-pool .guide-swap .item-round:nth-child(4) {
    width: 28%;
  }
  .round-pool .guide-swap .item-round:nth-child(6) {
    width: 20%;
  }
  .main-swap-pool .item-block {
    width: 100%;
    margin-bottom: 20px;
  }
  .main-swap-pool .item-block:nth-child(2) {
    width: 50%;
    padding: 0 5px 0px 0;
  }
  .main-swap-pool .item-block:nth-child(3) {
    width: 50%;
    padding: 0 0 0 5px;
  }
  .main-swap-pool .item-block:nth-child(2) .content-block {
    margin-right: 0;
  }
  .main-swap-pool .item-block:last-child {
    margin-bottom: 0;
  }
  .main-round-pool .round-slide.LP .item:nth-child(1) {
    width: 32%;
  }
  .main-round-pool .round-slide.LP .item:nth-child(2) {
    width: 20%;
  }
  .main-round-pool .round-slide.LP .item:nth-child(4) {
    width: 28%;
  }
  .main-round-pool .round-slide.LP .item:nth-child(5) {
    display: block;
  }
  .main-round-pool .round-slide.LP .item:nth-child(6) {
    width: 20%;
  }
  .round-pool .guide-swap.LP .item-round:nth-child(1) {
    width: 32%;
  }
  .round-pool .guide-swap.LP .item-round:nth-child(2) {
    width: 20%;
  }

  .round-pool .guide-swap.LP .item-round:nth-child(4) {
    width: 28%;
  }
  .round-pool .guide-swap.LP .item-round:nth-child(5) {
    display: block;
  }
  .round-pool .guide-swap.LP .item-round:nth-child(6) {
    width: 22%;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(3) {
    display: block;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(5) {
    display: block;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(3) {
    display: block;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(5) {
    display: block;
  }
  .filter-project.dis-991 {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .filter-swap {
    top: 60px;
  }
  .tabs-startpool .tabs-round .ant-tabs-nav {
    margin-bottom: 80px;
  }

  /* start pool */
  .main-round-pool .round-slide .item:nth-child(1) {
    width: 36%;
  }
  .main-round-pool .round-slide .item:nth-child(2) {
    width: 28%;
  }
  .main-round-pool .round-slide .item:nth-child(3) {
    display: none;
  }
  .main-round-pool .round-slide .item:nth-child(4) {
    width: 34%;
  }
  .main-round-pool .round-slide .item:nth-child(5) {
    display: none;
  }
  .main-round-pool .round-slide .item:nth-child(6) {
    display: none;
  }
  .round-pool .guide-swap .item-round:nth-child(1) {
    width: 36%;
  }
  .round-pool .guide-swap .item-round:nth-child(2) {
    width: 28%;
  }
  .round-pool .guide-swap .item-round:nth-child(3) {
    display: none;
  }
  .round-pool .guide-swap .item-round:nth-child(4) {
    width: 34%;
  }

  .round-pool .guide-swap .item-round:nth-child(5) {
    display: none;
  }
  .round-pool .guide-swap .item-round:nth-child(6) {
    display: none;
  }
  /* end start pools */
  /* farm pool */
  .main-round-pool .round-slide.LP .item:nth-child(1) {
    width: 38%;
  }
  .main-round-pool .round-slide.LP .item:nth-child(2) {
    width: 24%;
  }
  .main-round-pool .round-slide.LP .item:nth-child(4) {
    width: 38%;
  }
  .main-round-pool .round-slide.LP .item:nth-child(5) {
    display: none;
  }

  .round-pool .guide-swap.LP .item-round:nth-child(1) {
    width: 38%;
  }
  .round-pool .guide-swap.LP .item-round:nth-child(2) {
    width: 24%;
  }

  .round-pool .guide-swap.LP .item-round:nth-child(4) {
    width: 38%;
  }
  .round-pool .guide-swap.LP .item-round:nth-child(5) {
    display: none;
  }

  /* end farm pool */
  .main-round-pool .round-slide {
    padding: 20px;
  }
 
  /* .round-pool .guide-swap .item-round .name-project {
    display: block;
  } */
  .round-pool .guide-swap .item-round .box-name {
    margin-left: 10px;
    margin-top: 5px;
  }
  .round-pool .guide-swap .item-round .box-name .title-earn {
    font-size: 14px;
  }
  .round-pool .guide-swap .item-round .box-name .title-stake {
    font-size: 12px;
  }
  .round-pool .guide-swap .item-round .name-project .number .txt {
    font-size: 14px;
  }
  .round-pool .ant-collapse-arrow {
    right: 0;
    padding: 3px;
    top : 30px
  }
  .main-swap-pool {
    padding: 20px;
  }
  .main-swap-pool .item-block,
  .main-swap-pool .item-block:nth-child(2),
  .main-swap-pool .item-block:nth-child(3) {
    width: 100%;
    margin-bottom: 20px;
  }
  .main-swap-pool .item-block:nth-child(2) .content-block {
    margin-right: 0;
  }
  .main-swap-pool .item-block:last-child {
    margin-bottom: 0;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(1) {
    width: 36%;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(2) {
    width: 22%;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(3) {
    width: 18%;
    display: block;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(4) {
    display: none;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(5) {
    width: 24%;
    display: block;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(1) {
    width: 44%;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(2) {
    width: 16%;
  }
  /* .round-pool .guide-swap.IDO .item-round:nth-child(3) {
    width: 18%;
    display: block;
  } */
  .round-pool .guide-swap.IDO .item-round:nth-child(4) {
    display: none;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(5) {
    width: 20%;
    display: block;
  }
  .main-swap-pool .item-block:nth-child(2) {
    padding: 0px;
  }
  .main-swap-pool .item-block:nth-child(3) {
    padding: 0px;
  }
}
@media screen and (max-width: 550px) {
  .filter-swap {
    top: 60px;
  }
  .tabs-startpool .tabs-round .ant-tabs-nav {
    margin-bottom: 80px;
  }
  /* start pool */

  .main-round-pool .round-slide .item:nth-child(1) {
    width: 38%;
  }
  .main-round-pool .round-slide .item:nth-child(2) {
    width: 22%;
  }
  .main-round-pool .round-slide .item:nth-child(3) {
    display: none;
  }
  .main-round-pool .round-slide .item:nth-child(4) {
    width: 40%;
  }
  .main-round-pool .round-slide .item:nth-child(5) {
    display: none;
  }
  .main-round-pool .round-slide .item:nth-child(6) {
    display: none;
  }
  .round-pool .guide-swap .item-round:nth-child(1) {
    width: 38%;
  }
  .round-pool .guide-swap .item-round:nth-child(2) {
    width: 22%;
  }
  .round-pool .guide-swap .item-round:nth-child(3) {
    display: none;
  }
  .round-pool .guide-swap .item-round:nth-child(4) {
    width: 40%;
  }
  .round-pool .guide-swap .item-round:nth-child(5) {
    display: none;
  }
  .round-pool .guide-swap .item-round:nth-child(6) {
    display: none;
  }
  /* end start pool */
  /* farm pool  */
  .main-round-pool .round-slide.LP .item:nth-child(1) {
    width: 38%;
  }
  .main-round-pool .round-slide.LP .item:nth-child(2) {
    width: 20%;
  }
  .main-round-pool .round-slide.LP .item:nth-child(4) {
    width: 42%;
  }

  .round-pool .guide-swap.LP .item-round:nth-child(1) {
    width: 38%;
  }
  .round-pool .guide-swap.LP .item-round:nth-child(2) {
    width: 20%;
  }
  .round-pool .guide-swap.LP .item-round:nth-child(4) {
    width: 42%;
  }

  /* end farm pool  */

  .main-round-pool .round-slide {
    padding: 20px;
   
  }
  .tabs-startpool .round-pool .ant-collapse-header {
    padding: 20px;
  }
  /* .round-pool .guide-swap .item-round .name-project {
    display: block;
  } */
  .round-pool .guide-swap .item-round .box-name {
    margin-top: 5px;
  }
  .round-pool .guide-swap .item-round .box-name .title-earn {
    font-size: 14px;
  }
  .round-pool .guide-swap .item-round .box-name .title-stake {
    font-size: 12px;
  }
  .round-pool .guide-swap .item-round .name-project .number .txt {
    font-size: 14px;
  }
  .round-pool .ant-collapse-arrow {
    top: 35px;
    right: 0;
  }
  .main-swap-pool {
    padding: 20px;
  }
  .main-swap-pool .item-block {
    width: 100%;
    margin-bottom: 20px;
  }
  .main-swap-pool .item-block:nth-child(2) .content-block {
    margin-right: 0;
  }
  .main-swap-pool .item-block:last-child {
    margin-bottom: 0;
  }
  /* IDO pools */
  .main-round-pool .round-slide.IDO .item:nth-child(1) {
    width: 44%;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(2) {
    width: 24%;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(3) {
    display: none;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(5) {
    width: 32%;
    display: none;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(1) {
    width: 50%;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(2) {
    width: 24%;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(3) {
    display: none;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(5) {
    /* width: 26%; */
    display: none;
  }
  /* end IDO pool */
  .round-pool .guide-swap.LP .item-round .box-name .title-stake {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .round-pool .guide-swap .item-round:nth-child(4) {
    display: none;
  }
  .main-swap-pool .item-block:nth-child(1) .content-block {
    padding-right: 20px;
  }
}
@media screen and (max-width: 400px) {
  .main-round-pool .round-slide .item:nth-child(4) {
    display: none;
  }
  .main-round-pool .round-slide .item:nth-child(1) {
    width: 50%;
  }
  .main-round-pool .round-slide .item:nth-child(2) {
    width: 50%;
  }
  .round-pool .guide-swap .item-round:nth-child(4) {
    display: none;
  }
  .round-pool .guide-swap .item-round:nth-child(1) {
    width: 50%;
  }
  .round-pool .guide-swap .item-round:nth-child(2) {
    width: 50%;
  }

  .main-round-pool .round-slide.LP .item:nth-child(4) {
    display: none;
  }
  .main-round-pool .round-slide.LP .item:nth-child(1) {
    width: 60%;
  }
  .main-round-pool .round-slide.LP .item:nth-child(2) {
    width: 40%;
  }
  .round-pool .guide-swap.LP .item-round:nth-child(4) {
    display: none;
  }
  .round-pool .guide-swap.LP .item-round:nth-child(1) {
    width: 60%;
  }
  .round-pool .guide-swap.LP .item-round:nth-child(2) {
    width: 40%;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(1) {
    width: 65%;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(2) {
    width: 35%;
  }
  .main-round-pool .round-slide.IDO .item:nth-child(5) {
    display: none;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(1) {
    width: 65%;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(2) {
    width: 35%;
  }
  .round-pool .guide-swap.IDO .item-round:nth-child(5) {
    display: none;
  }
  .main-swap-pool .item-block:nth-child(1) .content-block {
    padding-right: 5px;
  }
}
.modal-roi-stake {
  /* width: 400px !important; */
  border-radius: 10px;
  background: #fff;
}
.modal-roi-stake .ant-modal-content {
  background: transparent;
}
.modal-roi-stake .ant-modal-header {
  background: #212121;
  border-bottom: 1px solid #fdd284;
  border-radius: 18px 18px 0 0;
}
.modal-roi-stake .ant-modal-header .ant-modal-title {
  color: #fdd284;
}
.modal-roi-stake .anticon svg {
  fill: #fdd284;
}
.modal-roi-stake .ant-modal-close-x {
  line-height: 48px;
}
.modal-roi-stake .ant-modal-footer {
  display: none;
}
.modal-roi-stake .ant-modal-body {
  background: #fff;
  color: #707a8a;
  border-radius: 0 0 18px 18px;
}
.modal-roi-stake .modal-roi-stake-title {
  border-radius: 18px 18px 0 0;
  color: #1e2329;
  padding: 15px 20px;
  background: #fff;
  font-weight: 600;
  font-size: 20px;
}
.modal-roi-stake .modal-roi-stake-title span {
  justify-content: space-between;
  display: flex;
}
.modal-roi-stake .modal-roi-stake-title hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #aab6cd;
}
.box-conent-modal {
  background: #fff;
  border-radius: 10px;
}
.box-conent-modal .table-modal table {
  width: 100%;
}
.box-conent-modal .table-modal table tr th {
  width: 33.33%;
  text-align: center;
  color: #1e2329;
  padding-bottom: 5px;
}
.box-conent-modal .table-modal table tr td {
  text-align: center;
  padding-bottom: 5px;
  color: #1e2329;
}
.list-desciption {
  margin-top: 15px;
}
.list-desciption li {
  font-size: 12px;
  margin-bottom: 5px;
  color: #1e2329;
}
.button-show-modal {
  border: none;
  background: transparent;
  cursor: pointer;
}
.button-hide-modal {
  border: none;
  background: transparent;
  cursor: pointer;
}
.view-port {
  line-height: 2.0715;
  text-align: left !important;
}

.ant-collapse-item > .ant-collapse-header .anticon {
  transform: rotate(-90deg);
}

.ant-collapse-item-active > .ant-collapse-header .anticon {
  transform: rotate(0deg);
}
.text-getmore-stake a {
  font-weight: 500;
  font-size: 14px;
  color: #1e2329;
}
.tooltip-cus.ant-tooltip .ant-tooltip-content .ant-tooltip-inner {
  background: #f6f7fc !important;
  border-radius: 8px !important;
  border: 1px solid #e9edf3;
  -webkit-text-fill-color: black;
}
.text-tooltip {
  display: flex;
}
.text-tooltip .icon {
  margin-right: 5px;
}
.text-tooltip .icon svg {
  fill: black;
  font-size: 18px;
  margin-top: -5px;
}
.text-tooltip .text {
  font-size: 12px;
  color: #000000;
}
.pd-left-3 {
  padding-left: 3px;
}
@media screen and (max-width: 550px) {
  .content-block.dis-desktop .view-port span img {
    width: 15px;
    margin-left: 5px;
  }
  .dis-mb-550 {
    display: none !important;
  }
}
@media screen and (min-width: 550px) {
  .dis-desktop {
    display: none !important;
  }
}
.connect-cus-start-pool .btn-connect {
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  width: 100%;
  display: block;
}
.connect-cus-start-pool .btn-connect img {
  display: none;
}

.text-getmore-stake a:hover {
  color: #c99400;
}
.pd-right-3 {
  padding-right: 3px;
}
@media (min-width: 768px) {
  .ant-modal.modal-cus-pool {
    max-width: 450px;
  }
}

.modal-cus-pool .ant-modal-footer {
  display: none;
}
.modal-cus-pool .ant-modal-close {
  display: none;
}
.modal-cus-pool .ant-modal-content {
  border-radius: 10px;
}
.modal-cus-pool .ant-modal-body {
  padding: 0px;
}
/* @media (max-width: 500px) {
  .modal-cus-pool {
    max-width: 400px;
    margin: 8px auto;
  }
} */
.item-round .name-project .box-logo {
  position: relative;
  width: 70px;
}
.item-round .name-project .box-logo .logo-big {
  width: 70px;
  height: 70px;
  background: #f6f7fc;
  border-radius: 50%;
  padding: 3px;
}
.item-round .name-project .box-logo .logo-small {
  position: absolute;
  width: 34px;
  height: 34px;
  right: -5px;
  bottom: 0;
  border-radius: 50%;
  background: #f6f7fc;
  padding: 1px;
}
.item-round .name-project .box-logo .logo-small-LP {
  position: absolute;
  width: auto;
  height: 30px;
  right: -10px;
  bottom: 0;
  padding: 2px;
}
@media screen and (max-width: 768px) {
  .item-round .name-project .box-logo {
    width: 50px;
  }
  .item-round .name-project .box-logo .logo-big {
    width: 50px;
    height: 50px;
  }
  .item-round .name-project .box-logo .logo-small {
    position: absolute;
    width: 25px;
    height: 25px;
  }
  .item-round .name-project .box-logo .logo-small-LP {
    position: absolute;
    width: auto;
    height: 25px;
  }
}
.farms .round-pool .ant-collapse-header {
  padding: 30px 40px;
}
@media screen and (max-width: 400px) {
.search-project.earn .ant-input-group .ant-input {
  width: 150px !important;
}
}
.connect-cus-start-pool .filter-chain {
  display: none;
}