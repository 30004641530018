.why-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 104%;
  padding-bottom: 30px;
  text-align: center;
  color: #25273d;
}

.box {
  width: calc(4 / 12 * 100%);
  margin: 10px 10px;
}

.wrap-box-why {
  height: calc(100% - 10px);
  width: 50%;
}

.box.public {
  width: 50%;
  height: 100%;
  position: relative;
  flex: 1;
  border: 1px solid #E9EFF5;
  border-radius: 24px;
  cursor: pointer;
}

.box.public:hover .gradient-shadow1 {
  opacity: 1;
}

.box.security {
  width: 100%;
  height: 50%;
  position: relative;
  flex: 1;
  border: 1px solid #E9EFF5;
  border-radius: 24px;
  cursor: pointer;
}

.box.security:hover .gradient-shadow1 {
  opacity: 1;
}

.box.com {
  width: 100%;
  height: 50%;
  position: relative;
  flex: 1;
  border: 1px solid #E9EFF5;
  border-radius: 24px;
  cursor: pointer;
}

.box.com:hover .gradient-shadow1 {
  opacity: 1;
}

.box-lit {
  display: flex;
  justify-content: center;
}

.why-bscs {
  background: #f9fafe;
  padding-top: 60px;
  padding-bottom: 60px;
}

.btn.btn-trade.why {
  background: #1e2329;
  color: #ffffff;
}

.btn.btn-trade.why:hover {
  background: #ffff;
  color: #1e2329;
}

.get-button.why {
  padding-top: 75px;
}

.icon-w {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.icon-secu img {
  width: 160px;
  height: 160px;
}

.icon-com img {
  width: 105px;
  height: auto;
}

.box-ins.box-ins-cus {
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
}

.icon-com {
  width: 160px;
}

.icon-secu {
  width: 160px;
}

.wrap-secu {
  text-align: left;
  width: calc(100% - 170px);
}

.title-w {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 168%;
  letter-spacing: 0.02em;
}

.title-w.pub {
  color: #1E2329;
  padding-top: 20px;
}

.title-w.sec {
  color: #1E2329;
  ;
}

.title-w.com {
  color: #1E2329;
  ;
}

.des-w {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 168%;
  color: #787a9b;
  text-align: start;
  padding-top: 15px;
}

.box {
  position: relative;
  z-index: 2;
  transition: 0.3s ease;
}

.box-ins {
  background: rgba(255, 255, 255, 1);
  border-radius: 24px;
  height: 100%;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 9;
}

.box-list {
  display: flex;
  height: 420px;
}

.gradient-shadow1 {
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  background: linear-gradient(137.86deg, #DE9FFC 2.2%, #4FFFFF 55.58%, #F5CB11 96.67%);
  border-radius: 24px;
  opacity: 0;
  filter: blur(8px);
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.why-text {
  font-family: Poppins;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 14%;
  text-align: center;
  color: #7A8195;
  margin-bottom: 16px;
}


@media screen and (max-width: 768px) {
  .why-title {
    font-size: 26px;
  }

  .box-list {
    display: flex;
    flex-wrap: wrap;
  }

  .box {
    width: 100%;
  }

  .get-button.why {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .app {
    width: 55%;
  }

  .why-bscs {
    padding-top: 30px;
  }

  .wrap-box-why {
    width: 100%;
  }

  .box.security {
    height: auto;
  }

  .box-ins {
    padding: 10px 15px;
  }

  .icon-secu {
    width: 100px;
  }

  .icon-secu img {
    width: 100px;
    height: 100px;
  }

  .wrap-secu {
    text-align: left;
    width: calc(100% - 110px);
  }

  .icon-com {
    width: 100px;
  }

  .icon-com img {
    width: 100px;
    height: auto;
  }

  .des-w {
    font-size: 14px;
    padding-top: 10px;
  }

  .box.public {
    height: auto;
  }

  .box-list {
    height: auto;
  }

  .box {
    margin: 15px auto;
  }

  .get-button {
    margin-bottom: 0;
  }
}