.community-support {
  * {
    padding: 0;
    margin: 0;
  }
  .container {
    margin: 100px auto 0 auto;
    width: 1166px !important;
    max-width: 1166px;

    @media (max-width: 768px) {
      width: 100% !important;
      padding: 16px;
      margin-top: 40px;
    }
  }

  .new-title {
    font-family: Poppins;
    font-weight: 500;
    font-size: 50px;
    line-height: 104%;
    letter-spacing: 0%;
    color: #25273d;
    @media (max-width: 768px) {
      font-size: 28px;
    }
  }
  .new-content {
    margin-top: 25px;
    font-family: Poppins;
    font-weight: 400;
    font-size: 20px;
    line-height: 138%;
    letter-spacing: 0%;
    color: #787a9b;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .flex {
    margin-top: 60px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      margin-top: 36px;
      flex-direction: column;
    }

    .flex-item {
      background: #ffffff;
      box-shadow: 0px 6px 20px 0px #d0d0d040;
      border: 1px solid #e9eff5;
      padding: 14px;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      gap: 14px;
      flex: 1;
      transition: 0.3s ease;
      cursor: pointer;
      &:hover {
        background: #dcd6d0;
      }

      .title {
        font-family: Poppins;
        font-weight: 700;
        font-size: 22px;
        line-height: 35px;
        letter-spacing: 10%;
        color: #25273d;
        text-align: start;
      }

      .flex-sentence {
        display: flex;
        align-items: center;
        gap: 9px;
        img {
          width: 16px;
          height: 16px;
        }

        p {
          font-family: Poppins;
          font-weight: 400;
          font-size: 14px;
          line-height: 26px;
          letter-spacing: 6%;
          color: #57597a;
        }
      }
    }
  }
}
