.table-makeour {
  p.desc {
    color: #787A9B;
    @media (max-width: 1024px) {
      margin-bottom: 0;
    }
  }

  .list-make {
    padding: 24px !important;
    background: #FFFFFF;
    border: 1px solid #E9EFF5;
    box-shadow: 0px 6px 36px 0px #787A9B1A;
    border-radius: 20px;
    margin-top: 64px;
    border: 1px solid #E9EFF5;
    @media (max-width: 1024px) {
      margin-top: 20px;
    }

    .text-top {
      color: #787A9B;
      font-family: Poppins;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 2%;
    }

    .text-bottom {
      margin-top: 6px;
      font-family: Poppins;
      font-weight: 600;
      font-size: 24px;
      letter-spacing: 0%;
      color: #000000 !important;
      @media (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }

  .title-makeour {
    color: #25273D !important;

  }


  @media (max-width: 1024px) {
    /* .colum {
      padding: 16px;
    } */
    .w-60 {
      width: 100%;
    }
  }
}