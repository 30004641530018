.wrap-incubaror {
    padding-top: 40px;
    @media (max-width: 768px) {
            padding-top: 10px;
    }
    .title-page {
        font-family: Poppins;
        font-weight: 600;
        font-size: 48px;
        line-height: 138%;
        letter-spacing: 0%;
        text-align: center;
        margin-bottom: 20px;
        color: #1E2329;
        span {
            background: linear-gradient(to right, #ff8f5a 10%, #eb0029 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        @media (max-width: 768px) {
            font-size: 36px;
            margin-bottom: 10px;
        }
    }
    .desc-page {
        padding: 0 20%;
        font-family: Poppins;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.6;
        letter-spacing: 0%;
        text-align: center;
        color: #787A9B;
        @media (max-width: 768px) {
            padding: 0;
            font-size: 14px;
        }
    }
}
.row-incubator {
    padding: 60px 0 0 0;
    @media (max-width: 768px) {
        padding: 24px 8px;
    }
    .title {
        font-family: Poppins;
        font-weight: 600;
        font-size: 48px;
        line-height: 104%;
        letter-spacing: 0%;
        text-align: center;
        color: #25273D;
        margin-bottom: 30px;
        @media (max-width: 768px) {
            font-size: 26px;
            margin-bottom: 20px;
        }
    }
    .guide-wrap {
        box-shadow: 0px 1px 14px 1px #0e3f7e1d;
        border: 1px solid #ECECEC;
        border-radius: 24px;
        background: #fff;
        padding: 15px;
        height: 100%;
        transition: 0.3s ease;
        cursor: pointer;
        &:hover {
            border: 1px solid #ff8f5a;
        }
        .box-img {
            padding-bottom: 15px;
            img {
                width: 70px;
                display: block;
                margin: 0 auto;
                 @media (max-width: 768px) {
                width: 50px;
            }
            }
        }
        .text {
            font-family: Poppins;
            font-weight: 600;
            font-size: 20px;
            line-height: 1.6;
            letter-spacing: 0%;
            text-align: center;
            color: #1E2329;
            margin-bottom: 15px;
            @media (max-width: 768px) {
                margin-bottom: 12px;
                font-size: 16px;
            }
        }
        .desc {
            font-family: Poppins;
            font-weight: 400;
            font-size: 16px;
            line-height: 1.6;
            letter-spacing: 0%;
            text-align: center;
            color: #787A9B;
             @media (max-width: 768px) {
                font-size: 14px;
            }
        }

    }
   @media (max-width: 768px) {
    div.colum.w-3 {
        width: 100% !important;
    }

   }
}

