.main-title-bonus {
    color: #1e2329;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 55px;
    margin-bottom: 45px;
}
.columns-bonus {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: center;
}
.colum-bonus {
    width: calc(3 / 12 * 100%);
    padding: 10px;
}
.content-bonus {
    background: linear-gradient(206.63deg,#eff3fe -.03%,#fbeffb 118.82%);
    border-radius: 6px;
    padding: 25px 15px;
    text-align: left;
}
.content-bonus .top-row-bn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.content-bonus .top-row-bn .left .name {
    color: #1e2329;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 5px; 
}
.content-bonus .top-row-bn .left .time {
    color: #707a8a;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .01em;
    line-height: 21px;
    text-align: left;
}
.content-bonus .top-row-bn .right img {
    width: 65px;
}
.middle-row-bn .surget-item-bn {
    background: #ead6ff;
    border-radius: 8px;
    padding: 15px;
}
.middle-row-bn .surget-item-bn .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
}
.middle-row-bn .surget-item-bn .item:last-child {
    padding-bottom: 0;
}
.middle-row-bn .surget-item-bn .item .l {
    color: #707a8a;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .01em;
    line-height: 21px;
}
.middle-row-bn .surget-item-bn .item .r {
    color: #1e2329;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .01em;
    line-height: 21px;
    text-align: right;
}
.select-row-bn {
    background: #1e2329;
    border: transparent;
    border-radius: 6px;
    box-shadow: 0 6px 20px hsl(0deg 0% 78% / 44%);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    height: 46px;
    letter-spacing: .06em;
    line-height: 22px;
    margin-top: 27px;
    text-align: center;
    width: 100%;
    cursor: pointer;
}
.logo-dt .box-img img {
    width: 80px;
    margin: 0 auto;
}
.table-bonus-dt .row-dt {
    background: linear-gradient(89.64deg,#fffceb -12.7%,#ffe8d8 112.66%);
    border: 1px solid #eecb8b;
    border-radius: 6px;
    color: #000;
    padding: 40px 40px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
}
.table-bonus-dt .row-dt .name {
    color: #1e2329;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: .01em;
    line-height: 21px;
    margin-bottom: 10px;
}
.table-bonus-dt .row-dt .box-img img {
    width: 60px;
}
.total-claim {
    color: #1e2329;
    font-size: 32px;
    font-weight: 700;
    line-height: 45px;
    margin: 20px 0;
}
.btn-claim-dt-bonus {
    background: #1e2329;
    border: 0 solid gray;
    border-radius: 6px;
    box-shadow: 0 6px 20px hsl(0deg 0% 78% / 44%);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    height: 40px;
    letter-spacing: .06em;
    line-height: 22px;
    text-align: center;
    width: 200px;
    cursor: pointer;
}
.btn-claim-dt-bonus.disbled {
    background: #e4e4eb!important;
    border-radius: 6px!important;
    box-shadow: 0 2px 0 rgba(0,0,0,.15)!important;
    color: #b6b8c3!important;
    font-size: 15px!important;
    font-weight: 500!important;
    letter-spacing: .06em!important;
    line-height: 22px!important;
    text-align: center!important;
    height: 40px;
    width: 200px;
    cursor: no-drop;
}
@media screen and (max-width: 768px) {
    .colum-bonus {
        width: 100%;
    }
}