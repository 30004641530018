.main-banner-new {
  * {
    margin: 0;
    padding: 0;
  }

  padding-top: 52px;

  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;
    width: 100%;
    text-align: start;

    @media (max-width: 1024px) {
      flex-direction: column;
      padding: 16px;
    }

    .content {
      max-width: 668px;

      @media (max-width: 1024px) {
        max-width: 100%;
      }

      .title {
        font-family: Poppins;
        font-weight: 600;
        font-size: 40px;
        line-height: 55.2px;
        letter-spacing: 0%;
        color: #25273D;

        @media (max-width: 1024px) {
          font-size: 28px;
          line-height: normal;
        }
      }

      .desc {
        margin-top: 12px;
        font-family: Poppins;
        font-weight: 400;
        font-size: 16px;
        line-height: 22.16px;
        letter-spacing: 0%;
        color: #787A9B;

        @media (max-width: 1024px) {
          font-size: 14px;
          line-height: normal;
        }
      }



    }

    .banner {
      /* display: block; */
      width: 560px;
      height: 560px;
      margin-top: -18px;
      max-width: 100%;
      @media (max-width: 1024px) {
        width: 100%;
        height: auto;
        margin-top: 0;
      }
    }
  }

  .cards-group {
    display: flex;
    justify-content: center;
    padding: 0 145px;
    gap: 20px;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
      padding: 16px;
      gap: 0;
    }

    .card {
      flex: 1;
      box-sizing: border-box;
      border: 1px solid transparent;
      text-align: start;
      padding: 16px;
      max-width: 360px;
      border-radius: 10px;
      margin: 8px;
      box-shadow: 0px 4px 12px 0px #474D5714;
      transition: 0.3s ease;

      .amount {
        font-family: Poppins;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 0%;
        color: #25273D;
      }

      .title {
        font-family: Poppins;
        font-weight: 400;
        font-size: 19.3px;
        line-height: 28.95px;
        letter-spacing: 0%;
        color: #7A8195;
      }

      &.card-1 {
        background: url('../../../../public/images/home/num-1.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      &.card-2 {
        background: url('../../../../public/images/home/num-2.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      &.card-3 {
        background: url('../../../../public/images/home/num-3.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 2px 2px #FF9B1B;
      }


      @media (max-width: 1024px) {
        width: 360px;
        max-width: 100%;
      }
    }
  }

  .networks {
    margin-top: 40px;
    @media (max-width: 1024px) {
      margin-top: 20px;
    }

    .content {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 44px;

      img {
        height: 14px;
        width: auto;
        @media (max-width: 1024px) {
          display: none;
        }
      }

      .text {
        font-family: Poppins;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 14%;
        color: #1B1B19;
        white-space: nowrap;
      }
    }

    .custom-marquee {
      position: unset;
      height: 158px;
      @media (max-width: 1024px) {
        height: 100px;
      }

      .rfm-overlay {
        height: 158px;
        
        @media (max-width: 1024px) {
          height: 100px;
        }

      }

      .marquee-item {
        margin: 0 12px;
        padding: 21px;
        transition: 0.3 ease;
        border-radius: 16px;
        border: none;

        @media (max-width: 1024px) {
          padding: 12px;
        }

        img {
          width: 43px;
          height: 43px;

          @media (max-width: 1024px) {
            width: 36px;
            height: 36px;
          }
        }

        box-shadow: 0px 4px 12px 0px #474D5714;

        &:hover {
          cursor: pointer;
          background: #e7e7e9;
          transform: scale(1.2);
        }
      }
    }
  }

  .btn-group {
    display: flex;
    align-items: center;
    margin-top: 36px;
    gap: 16px;
    @media (max-width: 1024px) {
      margin-top: 18px;
    }

    .btn-apply {
      padding: 13px 23px 14px 23px;
      border-radius: 6px;
      background: #F3F3F4;
      font-family: Poppins;
      font-weight: 500;
      font-size: 15px;
      line-height: 22.5px;
      letter-spacing: 6%;
      text-align: center;
      color: #1E2329;
      box-shadow: none;
      transition: 0.3s ease;

      @media (max-width: 1024px) {
        margin: 0;
        padding: 13px 20px;
        font-size: 14px;
      }

      &:hover {
        cursor: pointer;
        background: #e7e7e9 !important;
      }
    }
  }

  .banner-btn {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 4px 15px 4px 4px;
    background: #25273D;
    box-shadow: 0px 6px 20px 0px #C6C6C670;
    border-radius: 6px;
    transition: 0.3s ease;

    @media (max-width: 1024px) {
      padding: 4px 8px 4px 4px;
    }

    .icon {
      border-radius: 4px;
      background: linear-gradient(270deg, #F5CB11 0%, #FF9B1B 100%),
        linear-gradient(180deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0) 100%);
      padding: 9px;

      @media (max-width: 1024px) {
        padding: 5px;
        margin: 4px;
      }

      img {
        width: 24px;

        @media (max-width: 1024px) {
          width: 20px;
        }
      }
    }

    .text {
      font-family: Poppins;
      font-weight: 500;
      font-size: 15px;
      line-height: 22.5px;
      letter-spacing: 6%;
      color: #FFFFFF;

      @media (max-width: 1024px) {
        font-size: 14px;
      }
    }

    &:hover {
      cursor: pointer;
      background: linear-gradient(270deg, #F5CB11 0%, #FF9B1B 100%),
        linear-gradient(180deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0) 100%);

      .icon {
        background: #25273D;
      }

      .text {
        color: #25273D;
      }
    }
  }


}