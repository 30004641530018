.upcoming {
  margin-top: 28px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  @media (max-width: 1024px) {
    margin-top: 16px !important;
  }

  .main-title {
    margin-bottom: 0 !important;
  }

  .title.new {
    font-family: Poppins;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: 1%;
    text-align: center;
    margin-bottom: 0 !important;
    color: #25273D;

    @media (max-width: 1024px) {
      font-size: 28px;
      line-height: normal;
    }
  }

  .box-title {
    .symbol {
      font-family: Poppins;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0%;
      color: #1E232966 !important;
      margin-left: 8px;
    }
  }

  .item-slider {
    cursor: pointer;

    .content-row-funded {
      border-radius: 6px;

      .img-big {
        img {
          border-radius: 6px;


        }
      }
    }

    .list-raised {
      img {
        width: 16px;
        margin-right: 2.5px;
        display: inline-block;
      }

      li {
        margin-top: 8px;
      }

      span.left {
        font-family: Poppins;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0%;
      }

      span.right {
        font-family: Poppins;
        font-weight: 600 !important;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0%;
        text-align: right;
        color: #1E2329 !important;

        span {
          font-weight: 600 !important;
        }
      }

      span.right.time {
        background: #FBFBFC;
        .text-time {
          border-radius: 100px;
          padding: 4px 8px;
          background: linear-gradient(90deg, #1174F5 0%, #13B5EC 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    &:hover {
      .number.new {
        img {
          transform: unset
        }
      }

      .list-raised {
        img {
          transform: unset
        }
      }
    }

    .number.new {
      border-radius: 100px !important;
      padding: 5px 10px 5px 5px !important;
      color: #F0B90B !important;
      background: #F5CB1130 !important;
      font-family: Poppins;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0%;
      text-align: center;
      white-space: nowrap;

      img {
        display: inline-block;
        width: 24px;
        margin-right: 8px;
      }
    }
  }

  .btn-group {

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-top: 20px;
    }

    .btn.btn-show-more.new {
      margin-top: 36px;
      font-family: Poppins;
      font-weight: 500;
      font-size: 15px;
      line-height: 22.5px;
      letter-spacing: 6%;
      text-align: center;
      color: #1E2329;
      padding: 10px 34px;
      box-shadow: none;
      border-radius: 6px;
      transition: 0.3s ease;

      @media (max-width: 1024px) {
        margin: 0;
      }
    }

    .apply {
      margin-right: 21px;
      box-shadow: 0px 6px 20px 0px #C6C6C670;
      color: #FFFFFF !important;
      background: #1E2329;

      &:hover {
        background: #e7e7e9;
        color: #1E2329 !important;
      }

      @media (max-width: 1024px) {
        margin: 0;
      }
    }
  }
}