@media screen and (max-width: 768px) {
  .ant-row.content {
    padding: 0px 25px !important;
  }
  .col-acc {
    padding-left: 0px !important;
    padding-top: 20px;
  }
  .input-content {
    height: 50px !important;
  }
  .img-cp {
    right: 5px !important;
  }
}
.acc-tier {
  width: 100%;
  margin-left: 0;
  max-width: 100%;
}
.gr-btn-acc {
  padding-top: 15px;
}
.list-stake-acc {
  max-width: 100% !important;
}
.img-wl {
  width: 19px;
  height: 17px;
}
.text-wl {
  padding-left: 5px;
  width: 8%;
}
.wallet {
  background: #ffffff;
  height: auto;
  box-shadow: 0px 6px 20px rgb(120 122 155 / 18%);
  border-radius: 6px;
  padding: 18px 24px;
  margin-bottom: 15px;
}
.col-acc {
  padding-left: 70px;
}
.title-acc {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1e2329;
  padding-bottom: 12px;
  margin-bottom: 33px;
  position: relative;
}
.title-acc::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.5px;
  background: #aab6cd;
}

.title-name {
  text-align: left;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1e2329;
}
.input-content {
  width: calc(100%);
  height: 56px;
  background: #eff2f5;
  border-radius: 6px;
}
.input-wl {
  margin-top: 10px;
  position: relative;
}
.img-cp {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.img-cp:hover {
  cursor: pointer;
}
.img-link {
  width: 24px;
  height: 24px;
}
.input-link {
  display: flex;
  align-items: center;
  padding-top: 10px;
}
.ant-input.input-content-link {
  width: calc(100% - 245px);
  height: 56px;
  background: #eff2f5;
  border-radius: 6px;
  margin-left: 50px;
}
.btn-link {
  width: 50px;
  height: 50px;
  margin-left: 10px;
}
img.icon-link {
  width: auto;
}
.btn-link.cancel {
  background: rgba(244, 91, 117, 0.1);
  border-radius: 6px;
}
.btn-link.cancel:hover {
  border: 1px solid #f45b75;
  cursor: pointer;
}
.btn-link.ok:hover {
  cursor: pointer;
  border: 1px solid #1e2329;
}
.btn-link.ok {
  background: #f3f3f4;
  border-radius: 6px;
}
.btn-add-acc {
  background: #1e2329;
  border-radius: 6px;
  color: #ffff;
  width: 110px;
  height: 50px;
  margin-left: 12px;
  font-weight: 500;
  font-size: 15px;
}
span.text-wl-soon {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #7a8195;
  padding-left: 30px;
}
.input-link.soon {
  padding-top: 20px;
}
.btn-link.cp {
  background: #1e2329;
  border-radius: 6px;
}
.ant-input-disabled.input-content {
  color: #5a6776 !important;
  background: #eff2f5;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
  opacity: 1;
}
.top-mb {
  text-align: left;
  padding-top: 10px;
}

.input-content-link-mb {
  width: calc(100% - 60px);
  height: 50px;
  background: #eff2f5;
  border-radius: 6px;
  margin-left: 0px;
}
.btn-link.mb {
  width: 45px;
  height: 45px;
  margin-left: 5px;
}
.ant-input-disabled.input-content-link-mb {
  color: #5a6776 !important;
  background: #eff2f5;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled.input-content-link {
  color: #5a6776 !important;
  background: #eff2f5;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
  opacity: 1;
}
.input-wl.text {
  font-size: 16px;
}
.wallet-not {
  background: #ffffff;
  /* height: 219px; */
  box-shadow: 0px 6px 20px rgb(120 122 155 / 18%);
  border-radius: 6px;
  padding: 18px 24px;
  margin-bottom: 15px;
  padding-bottom: 36px;
}
.w-8-acc {
  width: 70%;
}
.w-8-acc.w-12-acc {
  width: 100%;
}
.w-4-acc {
  width: 30%;
  padding-left: 40px;
}
.kyc-round {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.ant-modal-body iframe {
  display: block !important;
  position: relative;
  z-index: 999;
}
.kyc-round .kyc-l img {
  width: 42px;
}
.kyc-round .kyc-l {
  width: 60px;
  text-align: left;
}
.kyc-round .kyc-right {
  width: calc(100% - 60px);
  text-align: left;
}
.kyc-round .kyc-right .txt {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 27px */
  color: #1e2329;
}
.kyc-round .kyc-right .btn-kyc {
  border: 0px solid transparent;
  background: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 168%;
  /* or 20px */

  text-decoration-line: underline;
  padding-left: 0;
  color: #787a9b;
  cursor: pointer;
}
.start-kyc .finish-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 168%;
  /* or 20px */

  text-decoration-line: underline;
  padding-left: 0;
  color: #787a9b;
}
@media screen and (max-width: 768px) {
  .w-8-acc {
    width: 100% !important;
  }
  .w-4-acc {
    width: 100% !important;
    padding-left: 10px;
  }
  .wallet.cus {
    height: auto;
  }
}
.main-tier .body-tier .list-tier .brTier:before {
  content: "";
  width: 8%;
  height: 4px;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
}
.main-tier .body-tier .list-tier .siTier:before {
  content: "";
  width: 23%;
  height: 4px;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
}
.main-tier .body-tier .list-tier .diaTier:before {
  content: "";
  width: 68%;
  height: 4px;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
}
.main-tier .body-tier .list-tier .suTier:before {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
}

.main-profile-user .ant-tabs-nav-wrap {
  justify-content: center;
  margin-bottom: 50px;
}
.main-profile-user .ant-tabs-top>.ant-tabs-nav:before {
  border-bottom: 0px solid #f0f0f0;
}
.main-profile-user .ant-tabs-nav-list {
  padding: 5px;
  background: #F6F7FC;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
}
.main-profile-user .ant-tabs-tab+.ant-tabs-tab {
  margin: 0;
}
.main-profile-user .ant-tabs-tab {
  padding: 12px 25px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  color: #707A8A;
}
.main-profile-user .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}
.main-profile-user .ant-tabs-tab.ant-tabs-tab-active {
  background: #1E2329;
  /* shadow tab */

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}
.main-profile-user .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.section-link {
    margin-bottom: 50px;
}
.section-link .content-link {
    text-align: left;
    padding-right: 30px;
}
.section-link .content-link .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 117%;
    /* or 47px */
    color: #25273D;
    text-align: left;
    margin-bottom: 20px;
}
/* .main-referrals {
    margin-bottom: 70px;
} */
.section-link .content-link .desc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138.5%;
    /* or 22px */
    color: #787A9B;
    margin-bottom: 20px;
}
.section-link .content-link .read-more {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #1E2329;
}
.section-link .content-ref .box-ref {
    background: url(https://s3-ap-southeast-1.amazonaws.com/bscstation.org/images/1c052e888707bff563c6e03947764beaa2204c3c8e38f6b18f.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    padding: 25px 25px 155px 25px;
    text-align: left;
}
.section-link .content-ref .box-ref .title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */

    margin-bottom: 32px;
    color: #FFFFFF;
}
.section-link .content-ref .input-address {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.section-link .content-ref .input-address .input {
    background: rgba(239, 242, 245, 0.1);
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(10px);
    /* Note: backdrop-filter has minimal browser support */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138.5%;
    /* or 22px */
    
    letter-spacing: 0.035em;
    padding: 15px;
    color: #FFFFFF;
    border-radius: 6px;
    cursor: pointer;
}
.section-link .content-ref .input-address .input img {
    width: auto;
    padding-left: 20px;
}
.section-link .content-ref .input-address .share {
    background: rgba(239, 242, 245, 0.1);
    border: 1px solid #FFFFFF;
    backdrop-filter: blur(10px);
    padding: 15px;
    border-radius: 6px;
    margin-left: 10px;
    cursor: pointer; 
    transition: 0.3s ease;
}
.section-link .content-ref .input-address .share:hover {
  background: rgba(239, 242, 245, 0.5);
}
.section-link .content-ref {
    position: relative;
    z-index: 1;
    margin-right: 10px
}
.section-link .content-ref::before {
    content: '';
    width: 105%;
    height: 132px;
    z-index: 2;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    border: 0.9px solid #D2D6E2;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 0px 0px 6px 6px;
}
.section-link .content-ref .lobo-bscs img {
    width: auto;
}
.section-link .content-ref .lobo-bscs {
    position: absolute;
    left: 20px;
    bottom: 68px;
    z-index: 3;
}
.section-link .columns {
    align-items: center;
}
.section-work {
    margin-bottom: 50px;
}
.section-work .wrap-work {
    background: #FFFFFF;
    border: 0.863612px solid #DFE3EA;
    border-radius: 22.0701px;
    padding: 25px;
    text-align: left;
}
.section-work .wrap-work .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */

    margin-bottom: 20px;
    color: #1E2329;
}
.section-work .wrap-work .title .read-more {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #1E2329;
}
.section-work .wrap-work .content-work {
    border-radius: 19.2568px;
    padding: 32px;
    position: relative;
}
.section-work .wrap-work .colum:nth-child(1) .content-work {
    background: linear-gradient(100.69deg, #FEFAE9 11.59%, #FCF1E9 103.08%);
}
.section-work .wrap-work .colum:nth-child(2) .content-work {
    background: linear-gradient(100.69deg, #FCF6FD 11.59%, #FBF0F6 103.08%);
}
.section-work .wrap-work .colum:nth-child(3) .content-work {
    background: linear-gradient(100.69deg, #EFF0FD 11.59%, #F9F2FA 103.08%);
}
.section-work .wrap-work .content-work .txt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 21.1825px;
    line-height: 32px;
    margin-bottom: 15px;
    color: #1E2329;
}
.section-work .wrap-work .content-work .desc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 13.4798px;
    line-height: 20px;
    
    color: #787A9B;
}
.section-work .wrap-work .content-work .img-work {
    margin-bottom: -32px;
    margin-right: -32px;
}
.section-work .wrap-work .content-work .img-work img {
    width: auto;
}
.section-work .wrap-work .content-work .img-work {
    text-align: right;
}
.section-work .wrap-work .content-work .number {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 21.1825px;
    line-height: 32px;
    /* identical to box height */

    text-align: center;

    color: #000000;
    border: 0.962841px solid #AAB6CD;
    padding: 5px;
    width: 44px;
    height: 44px;
    border-radius: 99px;
    position: absolute;
    left: 32px;
    bottom: 32px;
}
.section-dashboard .wrap-dashboard {
    background: #FFFFFF;
    border: 0.863612px solid #DFE3EA;
    border-radius: 22.0701px;
    padding: 25px;
    text-align: left;
}
.section-dashboard .wrap-dashboard .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */
    margin-bottom: 20px;

    color: #1E2329;
}
.section-dashboard .wrap-dashboard .item-row-3 {
    background: linear-gradient(89.64deg, rgba(255, 252, 235, 0.5) -12.7%, rgba(255, 232, 216, 0.5) 112.66%);
    border-radius: 18px;
    padding: 20px;
}
.section-dashboard .wrap-dashboard .item-row-3 .icon img {
    width: auto;
}
.section-dashboard .wrap-dashboard .item-row-3 .icon {
    background: linear-gradient(79.75deg, #61E8FF 8.93%, #569FFF 91.91%);
    border-radius: 99px;
    width: fit-content;
    padding: 10px;
    margin-right: 10px;
}
.section-dashboard .wrap-dashboard .item-row-3 {
    display: flex;
    align-items: center;
}
.section-dashboard .wrap-dashboard .item-row-3 .txt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #707A8A;
}
.section-dashboard .wrap-dashboard .item-row-3 .txt .number {
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #25273D;
}
.section-dashboard .wrap-dashboard .item-row-9 {
    background: #F7F8F9;
    border-radius: 18px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9 {
    display: flex;
    align-items: center;
    width: calc(4 / 12 * 100%);
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9 .icon {
    margin-right: 10px;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9 .icon img {
    width: auto;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9 .txt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #707A8A;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9 .txt .number {
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #25273D;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(2) {
    padding-left: 30px;
    border-left: 1px solid #E4E4E4;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(3) {
    padding-left: 30px;
    border-left: 1px solid #E4E4E4;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(2) .txt {
    position: relative;
    padding-left: 15px;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(2) .txt .number {
    margin-left: -15px;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(2) .txt:before {
    content: '';
    background: #E2B100;
    width: 8px;
    height: 8px;
    border-radius: 99px;
    position: absolute;
    left: 0;
    top: 6px;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(3) .txt {
    position: relative;
    padding-left: 15px;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(3) .txt .number {
    margin-left: -15px;
}
.section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(3) .txt:before {
    content: '';
    background: #0CB74F;
    width: 8px;
    height: 8px;
    border-radius: 99px;
    position: absolute;
    left: 0;
    top: 6px;
}
.search-project.res .ant-select-selector {
    width: 160px;
    background: #fff;
    border: 1px solid #E9EDF3;
}
.search-project.res .ant-select-selector .ant-select-selection-item {
    line-height: 44px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #1E2329;
}
.tabs-list-ref .ant-tabs-nav-wrap {
    justify-content: left;
    margin-bottom: 0;
}
.section-tabs-referral {
    margin-top: 30px;
}
.tabs-list-ref .ant-tabs-tab.ant-tabs-tab-active {
    background: transparent;
    box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0);
}
.tabs-list-ref .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
}
.tabs-list-ref .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2.5px solid #1E2329;
    border-radius: 0px;
}
.tabs-list-ref .ant-tabs-nav-list {
    background: transparent;
    box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0);
}
.tabs-list-ref .ant-tabs-tab {
    padding: 12px 30px 7px 0;
}
.tabs-list-ref .ant-tabs-top>.ant-tabs-nav:before {
    border-bottom: 1px solid #f0f0f0;
}
.tabs-list-ref .ant-tabs-nav-list {
    padding: 0;
}
.main-wrap-referral-list {
    margin-top: 30px;
}
.main-wrap-referral-list .header-tabs {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main-wrap-referral-list .header-tabs .title {
    text-align: left;
}
.main-wrap-referral-list .header-tabs .title .txt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
    /* identical to box height, or 146% */

    letter-spacing: 0.03em;

    color: #25273D;
    margin-bottom: 10px;
}
.main-wrap-referral-list .header-tabs .title .desc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 138.5%;
    /* or 22px */
    color: #707A8A;
}
.search-project.res {
    display: flex;
    align-items: center;
}
.search-project.res .search {
    position: relative;
}
.body-table {
    margin-top: 30px;
}
.body-table .main-table .tr-bottom .top {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height */
    
    color: #1E2329;
}
.body-table .main-table .tr-bottom .bot {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138.5%;
    /* or 22px */


    color: #707A8A;
}
.body-table .main-table .tr-bottom {
    border-bottom: 0.5px solid #CDD5E4;
}
.button-read-more .btn-read-more {
    margin-top: 30px;
    padding: 8px 15px;
    background: #1E2329;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    border: 0px solid transparent;
    color: #fff;
    cursor: pointer;
}
.section-rules {
    margin-top: 50px;
}
.collapes-rules .ant-collapse-content-box {
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #1E2329;
    padding: 15px 28px 28px 28px;
}
.collapes-rules .ant-collapse-content-box .bold {
    font-weight: 600;
}
.collapes-rules .ant-collapse-content {
    background: #f2f5f8;
    border-radius: 20px;
    border-top: 0px solid #d9d9d9;
    border-radius: 0 0 20px 20px;
}
.collapes-rules .ant-collapse {
    border: 0px solid #d9d9d9;
}
.collapes-rules .ant-collapse>.ant-collapse-item.ant-collapse-item-active>.ant-collapse-header {
    background: #f2f5f8;
    border-radius: 20px 20px 0 0;
    border-top: 0px solid #d9d9d9;
}
.collapes-rules .ant-collapse>.ant-collapse-item .ant-collapse-header {
    border-radius: 20px;
    background: #f2f5f8;
}
.collapes-rules .ant-collapse-item:last-child>.ant-collapse-content {
    border-radius: 0 0 20px 20px;
}
.collapes-rules .ant-collapse>.ant-collapse-item {
    border-bottom: 0px solid #d9d9d9;
    border-radius: 20px;
}
.collapes-rules .ant-collapse {
    background: transparent;
}
.collapes-rules .ant-collapse-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height */
    position: relative;
    padding: 22px 28px 15px 28px;
    color: #1E2329;
}
.collapes-rules .ant-collapse-header .ant-collapse-arrow {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px;
    background: #1E2329;
    border-radius: 99px;
}
.collapes-rules .ant-collapse-header .ant-collapse-arrow svg {
    fill: #fff;
    transform: rotate(-90deg);
}
.btn-claim-ref {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;
  background: #1E2329;
  /* button lock */
  padding: 10px 15px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  color: #FFFFFF;
  cursor: pointer;
  border: 0px solid transparent;
}
.btn-claim-ref.dis {
  color: #B6B8C3;
  background: #E4E4EB;
  cursor: no-drop;
}
.modal-qr-code {
  width: 420px !important;
}
.modal-qr-code .ant-modal-footer {
  display: none;
}
.modal-qr-code .ant-modal-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;

  color: #1E2329;
}
.modal-qr-code .ant-modal-header {
  padding: 25px 25px 10px 25px;
  border-radius: 6px 6px 0 0;
}
.modal-qr-code .ant-modal-body svg {
  width: 100%;
  margin-bottom: 15px;
}
.modal-qr-code .ant-modal-content {
  border-radius: 6px;
}
.modal-qr-code .ant-modal-close-icon svg {
  fill: #000;
}
.btn-code-save {
  background: #1E2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;
  border: 0px solid transparent;
  color: #FFFFFF;
  padding: 10px 45px;
  cursor: pointer;
}
.btn-code-cancel {
  background: #F3F3F4;
  border-radius: 6px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;
  border: 0px solid transparent;
  color: #1E2329;
  padding: 10px 45px;
  cursor: pointer;
}
.button-modal-qr-code {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto 20px;
}
.wrap-dashboard .search-project {
  margin-bottom: 0;
}
.date-picker {
  margin-left: 10px;
}
.date-picker .ant-picker-input {
  background: #EFF2F5;
  border-radius: 6px;
  padding: 5px 10px;
}
.date-picker .ant-picker-range {
  border-radius: 6px;
}
.main-referrals .ant-select-selector {
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0) !important;
}
.main-referrals .ant-input-group .ant-input {
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0);
}
.date-picker .ant-picker:hover {
  border-color: rgba(250, 198, 104, 1);
  border-right-width: 1px;
}
.main-referrals .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: rgba(250, 198, 104, 1);
  border-right-width: 1px;
}
.popover-row {
  padding: 10px 0;
}
.popover-row .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F6F7FC;
  border-radius: 6px;
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
}
.popover-row .item:last-child {
  margin-bottom: 0;
}
.popover-row .item .txt-l {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */


  color: #1E2329;
}
.popover-row .item .img {
  width: auto;
}
.ant-popover-placement-bottom {
  width: 200px !important;
  border-radius: 6px;
}
.ant-popover-placement-bottom .ant-popover-inner {
  border-radius: 6px;
}
.search-project.res .sort {
  display: flex;
  align-items: center;
}
.empty-colspan {
  margin: 40px 0;
}
@media screen and (max-width: 768px) {
    .section-link .content-link .title {
        font-size: 32px;
    }
    .main-profile-user .ant-tabs-nav-wrap {
        margin-bottom: 10px;
    }
    .section-link .content-ref .input-address .input {
        font-size: 12px;
        padding: 5px;
    }
    .section-link .content-ref .input-address .share {
        padding: 5px;
    }
    .section-link .content-ref .box-ref {
        padding: 10px 10px 100px 10px;
    }
    .section-link .content-ref::before {
        height: 80px;
    }
    .section-link .content-ref .lobo-bscs {
        bottom: 20px;
    }
    .section-link .content-ref .input-address .input img {
        padding-left: 5px;
    }
    .wrap-dashboard .w-25 {
        width: 100% !important;
    }
    .wrap-dashboard .w-75 {
        width: 100% !important;
    }
    .section-dashboard .wrap-dashboard .item-row-3 .txt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .section-dashboard .wrap-dashboard .item-row-9 .row-9 {
        width: 100%;
        display: block;
    }
    .section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(2) {
        padding-left: 0;
        border-left: 0px solid #E4E4E4;
    }
    .section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(3) {
        padding-left: 0;
        border-left: 0px solid #E4E4E4;
    }
    .section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(2) .txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(3) .txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(2) .txt:before {
        top: 13px;
    }
    .section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(3) .txt:before {
        top: 13px;
    }
    .section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(2) .txt .number {
        font-size: 16px;
    }
    .section-dashboard .wrap-dashboard .item-row-9 .row-9:nth-child(3) .txt .number {
        font-size: 16px;
    }
    .section-dashboard .wrap-dashboard .item-row-9 .row-9 .txt .number {
        font-size: 16px;
    }
    .section-dashboard .wrap-dashboard .item-row-9 .row-9 .icon {
        margin-right: 0;
        margin-bottom: 10px;
    }
    .main-profile-user .ant-tabs-tab {
      padding: 8px 20px;
    }
    .main-wrap-referral-list .header-tabs {
      display: block;
    }
    .main-wrap-referral-list .header-tabs .title {
      margin-bottom: 10px;
    }
    .main-wrap-referral-list {
      margin-top: 15px;
    }
    .search-project.res {
      flex-wrap: wrap;
    }
    .search-project.res .sort {
      width: 100%;
      padding-right: 0;
      margin-bottom: 5px;
      display: block;
    }
    .search-project.res .search {
      width: 100%;
    }
    .search-project.res .ant-select {
      width: 100% !important;
    }
    .search-project.res .ant-select-selector {
      width: 100% !important;
      padding-right: 0 !important;
    }
    .date-picker .ant-picker-range {
      width: 100%;
    }
    .date-picker {
      margin-left: 0;
      margin-top: 5px;
      width: 100%;
    }
    .main-wrap-referral-list .header-tabs .title .txt {
      font-size: 18px;
      margin-bottom: 5px;
    }
    .main-wrap-referral-list .header-tabs .title .desc {
      font-size: 13px;
      margin-bottom: 5px;
    }
    .content-header-panel {
      align-items: center;
    }
}
.content-ref-m .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.content-ref-m .item .txt-l {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #1E2329;
}
.content-ref-m .item .txt-r {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #1E2329;
  display: flex;
}
.content-ref-m .item .txt-r .bot {
  margin-left: 10px;
}
.body-table .ant-collapse-content-box {
  background: #F6F7FC;
  border-radius: 6px;
}
.body-table .ant-collapse-header {
  background: #F6F7FC;
  border: 0.9px solid #E9EDF3;
  border-radius: 6px;
}
.body-table .ant-collapse {
  border: 0px solid #E9EDF3;
}
.body-table .ant-collapse>.ant-collapse-item {
  border-bottom: 0px solid #d9d9d9;
}
.body-table .ant-collapse-item {
  margin-bottom: 10px;
}
.body-table .ant-collapse-header .anticon {
  position: absolute;
  right: 0px;
  top: 11px;
  padding: 5px;
  background: #000;
  border-radius: 99px;
}
.body-table .ant-collapse-header .anticon svg {
  fill: #fff;
}
.content-ref-m .item:last-child {
  margin-bottom: 0;
}
.body-table .content-header-panel .token-name-m {
  font-weight: 600;
}