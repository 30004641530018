.main-stake-details {
  padding-top: 130px;
  background-size: contain !important;
  background-position: top !important;
  padding-bottom: 50px;
}
.main-stake-details .main-title-pool {
  position: relative;
  align-items: center;
}
.main-stake-details .main-title-pool .button-back {
  color: #0dba88;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.main-stake-details .main-title-pool .button-back button {
  background: transparent;
  padding: 0;
  border: transparent;
}
.main-stake-details .main-title-pool .title-pool {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;

  color: #1e2329;
}
.main-stake-details .main-title-pool .box-img {
  position: relative;
  margin: 0 auto;
  margin-top: 10px;
}
.main-stake-details .main-title-pool .box-img img {
  position: relative;
  z-index: 2;
}
.main-stake-details .main-title-pool .box-img .img-small {
  position: absolute;
  top: -7px;
  left: -12px;
  z-index: 1;
}
.tabs-stake-details {
  margin-top: 40px;
}
.tabs-stake-details .columns {
  width: 650px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.tabs-stake-details .columns .colum {
  width: 320px;
}
.tabs-stake-details .columns .colum .item {
  background: linear-gradient(89.64deg, #fffceb -12.7%, #ffe8d8 112.66%);
  border-radius: 6px;
  padding: 20px 40px;
  text-align: center;
  color: #fff;
  border: 1px solid rgb(238 203 139);
}
.tabs-stake-details .columns .colum .item .title-txt {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  margin-bottom: 15px;

  color: #1e2329;
}
.tabs-stake-details .columns .colum .item .box-img {
  margin-bottom: 30px;
}
.tabs-stake-details .columns .colum .item .box-img img {
  width: 60px;
  height: auto;
}
.tabs-stake-details .columns .colum .item .balance {
  color: #1e2329;
  font-size: 32px;
  font-weight: bold;
  line-height: 45px;
  margin-bottom: 0px;
}
.tabs-stake-details .columns .colum .item .name-token {
  font-size: 18px;
  font-weight: bold;
  line-height: 45px;
  margin-bottom: 10px;
  color: #1e2329;
}
.tabs-stake-details .columns .colum .item .button-select .btn-select {
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  height: 40px;
  width: 100%;
  text-align: center;
  letter-spacing: 0.06em;
  border: 0px solid gray;
  color: #ffffff;
}
.tabs-stake-details .columns .colum .item .button-select.cus .btn-select {
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  border: 0px solid gray;
  font-weight: 500;
  font-size: 15px;
  height: 40px;
  width: 100%;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.06em;

  color: #ffffff;
}
.table-stake-details {
  width: 630px;
  margin: 0 auto;
  margin-top: 50px;
}
.modal-staking-main {
  width: 600px;
}
.modal-staking-main .ant-modal-footer {
  display: none;
}
.modal-staking-main .ant-modal-header {
  background: #fff;
  border-radius: 10px 10px 0 0;
  border-bottom: 0.5px solid #aab6cd !important;
}
.modal-staking-main .ant-modal-body {
  background: #fff;
  border-radius: 0 0 10px 10px;
}
.modal-staking-main .ant-modal-content svg {
  fill: #0dba88;
}
.main-header-modal .title {
  font-size: 20px;
  color: #1e2329;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: center;
}
.main-header-modal .desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 138.5%;
  /* or 22px */

  text-align: center;

  color: #616772;
}
.main-body-modal .staking-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.main-body-modal .staking-amount .title-staking {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #1e2329;
}
.main-body-modal .staking-amount .amount-avaiable {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 0.01em;

  color: #1e2329;
}
.main-body-modal .staking-amount .main-color {
  color: #fac668;
}
.main-body-modal .input-amount {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.main-body-modal .input-amount .ant-input {
  width: 100%;
  height: 48px;
  border: transparent;
  border-radius: 10px;
  background: #eff2f5;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 20px;
}
.main-body-modal .input-amount .name-token-staking {
  font-weight: 400;
  font-size: 16px;
  line-height: 138.5%;
  /* or 22px */

  text-align: right;

  color: #616772;
  position: absolute;
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
}
.main-body-modal .input-amount .button-max {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
}
.main-body-modal .input-amount .button-max button {
  background: #1e2329;
  border: transparent;
  width: 60px;
  height: 40px;
  text-align: center;
  border-radius: 6px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.main-body-modal .choose-earning .title-choose {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #1e2329;
}
.main-body-modal .choose-earning .table-earning {
  width: 95%;
  margin-left: auto;
  position: relative;
}
.main-body-modal .choose-earning .table-earning:last-child .item {
  margin-top: 0;
}
.main-body-modal .choose-earning .table-earning .ant-radio-group {
  width: 100%;
}
.main-body-modal .choose-earning .table-earning span.ant-radio + * {
  width: 100%;
  display: flex;
}
.main-body-modal .choose-earning .table-earning .item-top {
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
}
.main-body-modal .choose-earning .table-earning .item-top .text {
  width: calc(3 / 12 * 100%);
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #000000;

  margin-top: 20px;
}
.main-body-modal .choose-earning .table-earning .item-top .text span {
  display: block;
  margin-top: 5px;
}
.main-body-modal .choose-earning .table-earning .item-bottom {
  display: flex;
  flex-wrap: wrap;
}
.main-body-modal
  .choose-earning
  .table-earning
  .item-bottom
  span.ant-radio
  + * {
  width: 100%;
  display: flex;
}
.main-body-modal .choose-earning .table-earning .item-bottom .text {
  width: calc(4 / 12 * 100%);
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #000000;
  margin-top: 20px;
}
.main-body-modal .choose-earning .table-earning .item-bottom .text span {
  display: block;
  margin-top: 5px;
}
.main-body-modal .choose-earning .table-earning .ant-radio-wrapper {
  width: 100%;
  bottom: 0;
  color: #1c1b1b;
  display: flex;
}
.main-body-modal
  .choose-earning
  .table-earning
  .ant-radio-wrapper
  .ant-checkbox-inner {
  background-color: #404040;
  border: 1px solid #404040;
}
.main-body-modal .choose-earning .table-earning .ant-checkbox-wrapper {
  width: 100%;
  color: #1c1b1b;
}
.main-body-modal
  .choose-earning
  .table-earning
  .ant-checkbox-wrapper
  .ant-checkbox-inner {
  background-color: #404040;
  border: 1px solid #404040;
}
.main-body-modal
  .choose-earning
  .table-earning
  .ant-checkbox-wrapper
  .ant-checkbox
  + span {
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  display: flex;
}
.main-body-modal .unstake-amount {
  margin-top: 30px;
}
.main-body-modal .unstake-amount .item .txt {
  color: #fff;
  font-size: 14px;
  margin-bottom: 5px;
}
.main-body-modal .unstake-amount .item .txt span {
  margin-left: 5px;
  color: #fac668;
}
.main-body-modal .stake-rules {
  margin-top: 15px;
}
.main-body-modal .stake-rules .rules-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  color: #707a8a;
}
.main-body-modal .stake-rules .list-rules li {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;

  color: #707a8a;
}
.main-body-modal .stake-rules .list-rules li .tooltips {
  color: #0dba88;
}
.main-body-modal .stake-rules .ant-checkbox-wrapper {
  margin-top: 15px;
  margin-bottom: 15px;
  color: #707a8a;
}
.main-body-modal .stake-rules .ant-checkbox-wrapper .ant-checkbox-inner {
  background-color: #404040;
  border: 1px solid #404040;
}
.main-body-modal .button-select .btn-select {
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  border: transparent;
  width: 100%;
  font-weight: bold;
}
.main-body-modal .button-select.cus .btn-select {
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  height: 40px;
  color: #fff;
  font-size: 14px;
  border: transparent;
  width: 120px;
  font-weight: bold;
}
.modal-staking-main .ant-modal-content {
  border-radius: 10px;
  background-color: #1c1b1b;
}

@media screen and (max-width: 768px) {
  .tabs-stake-details .columns {
    width: 100%;
  }
  .tabs-stake-details .columns .colum {
    width: 100%;
    margin-bottom: 15px;
  }
  .table-stake-details {
    width: 100%;
    margin-top: 20px;
  }
  .table-stake-details .box-table table tr th {
    font-size: 13px !important;
    padding: 15px 10px;
  }
  .table-stake-details .box-table table tr td {
    font-size: 12px !important;
    padding: 15px 10px;
  }
  .main-body-modal .choose-earning .table-earning .item {
    font-size: 10px;
  }
  .main-body-modal .staking-amount .main-color {
    display: block;
  }
}
.unstake {
  background: #fff !important;
  margin-right: 10px;
  color: #0dba88 !important;
}
.btn-stake-pool-v2 {
  display: flex;
}
.btn-unlock.btn-wrap {
  margin-top: 0;
}
.btn-unlock.btn-wrap button {
  height: 40px !important;
  width: 100% !important;
  font-size: 14px;
  font-weight: none;
}
.table-earning .ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fac668;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: " ";
}

.table-earning .ant-radio-inner:after {
  position: absolute;
  top: 6px;
  left: 6px;
  background: #1e2329;
  display: block;
  width: 18px;
  height: 18px;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: " ";
}
.table-earning .ant-radio-checked .ant-radio-inner {
  border-color: #1e2329;
}
.stake-rules .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  background: #363636;
  border: 0 solid #d9d9d9;
}
button.btn-select.unstake:disabled {
  background: #ffffff;
  /* button lock */

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.06em;
  color: #b6b8c3 !important;
}
button.btn-select:disabled {
  background: #e4e4eb !important;
  /* button lock */

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15) !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  /* identical to box height */
  text-align: center !important;
  letter-spacing: 0.06em !important;
  color: #b6b8c3 !important;
}

.box-img {
  position: relative !important;
}
.box-img img {
  position: relative !important;
  /* z-index: 2 !important; */
}
.box-img .img-small-bot {
  position: absolute !important;
  top: 0px !important;
  left: 70px !important;
  z-index: 1 !important;
}
.tabs-stake-details .columns .colum .item .box-img img {
  width: 60px !important;
  height: auto;
}

@media screen and (max-width: 768px) {
  .main-stake-details .main-title-pool .title-pool {
    font-size: 20px;
    line-height: 45px;
    color: #1e2329;
    font-weight: bold;
    /* margin-right: 30px; */
  }
  .main-stake-details .main-title-pool .button-back {
    color: #0dba88;
    position: absolute;
    left: 0;
    top: 98%;
    transform: translateY(-50%);
  }
  .box-img .img-small-bot {
    position: absolute !important;
    top: 0px !important;
    left: 90px !important;
    z-index: 1 !important;
  }
}

.box-img-icon {
  left: 20px;
}
.main-stake-details .main-title-pool .box-img {
  width: 60px;
}
.banner-stake-pool .box-img img {
  position: inherit !important;
}

.textFee {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.getLinkLP {
  color: #0dba88;
  display: flex;
  font-size: 12px;
  align-items: center;
}
.getLinkLP:hover {
  cursor: pointer;
}
.iconLP {
  margin-left: 5px;
  width: 11px;
  height: 11px;
}
.textLP:hover {
  color: #0dba88 !important;
  text-decoration: underline !important;
}
.btn-select {
  cursor: pointer;
}
.table-earning .ant-radio-checked .ant-radio-inner:after {
  transform: scale(0.5) !important;
  opacity: 1 !important;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86) !important;
}
.back-mint-pool {
  border: none;
  position: absolute;
  left: 230px;
  top: 0px;
  display: flex;
  background: none;
  align-items: center;
}
.back-mint-pool:hover {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  button.back-mint-pool {
    border: none;
    position: absolute;
    top: 100px !important;
    left: 5px !important;
    display: flex;
    background: none;
    align-items: center;
  }
}
