.main-ino-launch {
  padding-top: 130px;
  background-size: contain !important;
  background-position: top !important;
  padding-bottom: 50px;
}
.top-content-ino .title-ino {
  font-weight: 500;
  font-size: 40px;
  line-height: 138%;
  /* or 55px */
  text-align: center;
  color: #1e2329;
  margin-bottom: 22px;
}
.top-content-ino .balance-ino .desc {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  color: #707a8a;
}
.top-content-ino .balance-ino .number {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  color: #1e2329;
}
.button-ino {
  margin: 30px 0 50px 0;
}
.button-ino .btn {
  width: 210px;
}
.btn-how-stake {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  color: #1e2329;
  background: transparent;
  border: 0px solid transparent;
}
.how-stake {
  margin-bottom: 20px;
}
.slide-rank-ino .ant-slider-track {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  border-radius: 16px;
  height: 10px;
  top: 0;
  z-index: 2;
}
.slide-rank-ino .ant-slider-disabled .ant-slider-handle {
  background-color: transparent;
  border-color: transparent !important;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: transparent;
  box-shadow: 0px 0px 0px transparent !important;
}
.ant-tooltip {
  padding-bottom: 4.3137085px;
}
.slide-rank-ino .ant-slider-step {
  background: #ffffff;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  height: 10px;
}
.slide-rank-ino .ant-slider:hover .ant-slider-track {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
}
.slide-rank-ino .ant-slider {
  padding: 1px 0;
  width: 75%;
  margin: 0 auto;
}
.slide-rank-ino .ant-slider-handle {
  margin-top: -3px;
}
.slide-rank-ino .ant-slider-handle.ant-tooltip-open {
  border-color: #ee4d3a;
}
.ant-tooltip-inner {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  border-radius: 99px;
}
.slide-rank-ino {
  margin-top: 70px;
  margin-bottom: 30px;
}
.tier-rank {
  margin: 0 auto;
  width: 650px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.tier-rank .item-tier {
  width: calc(4 / 12 * 100%);
  padding: 10px;
}
.tier-rank .item-tier:nth-child(1) .content-tier {
  padding: 20px 30px 30px 30px;
  background: linear-gradient(89.64deg, #fffceb -12.7%, #ffe8d8 112.66%);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tier-rank .item-tier:nth-child(2) .content-tier {
  padding: 20px 30px 30px 30px;
  background: linear-gradient(89.64deg, #ebfaff -12.7%, #d8ecff 112.66%);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tier-rank .item-tier:nth-child(3) .content-tier {
  padding: 20px 30px 30px 30px;
  background: linear-gradient(89.64deg, #ffebfe -12.7%, #f8e4ff 112.66%);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tier-rank .item-tier .content-tier .box-icon img {
  width: 85px;
}
.tier-rank .item-tier .content-tier .box-icon {
  margin-bottom: 5px;
}
.tier-rank .item-tier .content-tier .name-tier {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  color: #707a8a;
  margin-bottom: 10px;
}
.tier-rank .item-tier .content-tier .number-tier {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  text-align: center;
  color: #1e2329;
}
.content-ino-launch-bottom {
  margin-top: 60px;
}
.content-ino-launch-bottom .title {
  font-weight: 600;
  font-size: 30px;
  line-height: 130%;
  /* text-align: center; */
  margin-bottom: 10px;
  color: #1e2329;
  display: flex;
  justify-content: space-between;
}
.main-table {
  width: 100%;
}
.main-table .tr-bottom .box-pro-name {
  display: flex;
  align-items: center;
}
.main-table .tr-bottom .box-pro-name .icon-logo img {
  width: 87px;
  height: 48px;
  object-fit: contain;
}
.main-table .tr-bottom .box-pro-name .icon-logo {
  margin-right: 10px;
}
.main-table .tr-bottom .box-pro-name .name-pro {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #1e2329;
}
.main-table .tr-bottom .box-pro-name .name-pro .type {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.03em;
  color: #707a8a;
  display: block;
  margin-top: 2px;
}
.main-table .tr-bottom .type-sc {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  text-align: center;
  color: #e2b100;
  background: rgba(240, 185, 11, 0.1);
  border-radius: 3px;
  padding: 7px 0;
}
.main-table .tr-bottom .type-sl .icon-img img {
  width: 28px;
}
.main-table .tr-bottom .type-pr {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #c99400;
}
.main-table .tr-bottom {
  transition: 0.3s ease;
  /* cursor: pointer; */
}
.main-table .tr-bottom:hover {
  background: #f6f7fc;
  border-radius: 6px;
}
.main-details-ino {
  padding-top: 130px;
  background-size: contain !important;
  background-position: top !important;
  padding-bottom: 50px;
}
.table-ino-details .content-left .box-img img {
  width: 300px;
  margin: 0 auto;
}
.table-ino-details .content-left .box-img {
  /* padding: 130px 0;
  background: #ffffff;
  border-radius: 6px;
  height: 100%; */
  display: flex;
  padding: 50px 0;
  background: #ffffff;
  border-radius: 6px;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.content-info-user {
  background: linear-gradient(
    91.05deg,
    rgba(255, 224, 167, 0.29) -1.65%,
    rgba(255, 132, 153, 0.29) 116.33%
  );
  backdrop-filter: blur(10px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 0px 0px 6px 6px;
}
.content-info-user .list-info-user {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 20px 25px;
  flex-wrap: wrap;
}
.content-info-user .list-info-user li {
  list-style-type: none;
  text-align: left;
}
.content-info-user .list-info-user li .swap .txt-top {
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  color: #707a8a;
  margin-bottom: 5px;
}
.content-info-user .list-info-user li .swap .txt-bottom {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  color: #1e2329;
}
@keyframes leaves {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@keyframes move {
  0% {
    transform: translateX(0) rotate(0deg);
  }
  99% {
    transform: translateX(550px) rotate(0deg);
  }
  100% {
    transform: translateX(550px) rotate(360deg);
  }
  99% {
    transform: translateX(550px) rotate(0deg);
  }
  0% {
    transform: translateX(0) rotateX(360deg);
  }
}

.move-animation {
  animation: move 3s ease infinite alternate;
  -webkit-animation: move 3s ease infinite alternate;
}

.rotate-animation {
  animation: leaves 2s ease-in-out infinite alternate;
  -webkit-animation: leaves 2s ease-in-out infinite alternate;
}
.table-ino-details .content-right {
  background: #ffffff;
  border: 0.9px solid #e9edf3;
  box-shadow: 0px 4px 30px rgba(132, 132, 132, 0.1);
  border-radius: 6px;
  padding: 20px;
  text-align: left;
}
.table-ino-details .content-right .desc-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
  margin-bottom: 5px;
}
.table-ino-details .content-right .title-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #1e2329;
  padding-bottom: 15px;
  border-bottom: 0.5px solid #aab6cd;
}
.box-current-price .title-price {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box-current-price {
  padding: 15px 0;
}
.box-current-price .price-box-round .img-bnb {
  width: 28px;
}
.box-current-price .price-box-round {
  display: flex;
  align-items: center;
}
.box-current-price .price-box-round .number-price {
  font-weight: 600;
  font-size: 26px;
  line-height: 138.5%;
  /* identical to box height, or 36px */
  color: #1e2329;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.box-current-price .price-box-round .number-price .symbol-price {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
  margin-left: 5px;
}
.box-current-price .list-price-rank {
  margin-bottom: 0;
  padding-left: 0;
  background: linear-gradient(89.64deg, #fffceb -12.7%, #ffe8d8 112.66%);
  border-radius: 6px;
  padding: 12px 20px;
  margin-top: 12px;
  margin-bottom: 12px;
}
.box-current-price .list-price-rank li {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.box-current-price .list-price-rank li:last-child {
  margin-bottom: 0;
}
.box-current-price .list-price-rank li .txt-l {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
}
.box-current-price .list-price-rank li .txt-r {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: right;
  letter-spacing: 0.01em;
  color: #1e2329;
}
.box-current-price .list-price-rank li .txt-r img {
  width: 28px;
  margin-right: 5px;
}
.box-current-price .title-price .symbol-now {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  text-align: right;
  letter-spacing: 0.01em;
  color: #1e2329;
}
.box-current-price .title-price .symbol-now img {
  width: 28px;
  margin-right: 5px;
}
.box-current-price .box-name-round {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  padding-top: 12px;
  border-top: 0.5px solid #aab6cd;
}
.box-current-price .box-name-round .round-id {
  width: 50%;
  padding: 10px;
}
.box-current-price .box-name-round .round-id .content-id {
  background: #ffffff;
  border: 1px solid #1e2329;
  /* shadow tab */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  text-align: center;
  padding: 8px 0;
}
.box-current-price .box-name-round .round-id .content-id .box-img {
  width: 44px;
  height: auto;
  margin: 0 auto;
  padding-bottom: 10px;
}
.box-current-price .box-name-round .round-id .content-id .name-id {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.03em;
  color: #1e2329;
  padding-bottom: 5px;
}
.box-current-price .box-name-round .round-id .content-id .quality-id {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.03em;
  color: #687788;
}
.button-ino-details {
  margin-top: 15px;
}
.tab-round-ino {
  margin-top: 30px;
}
.tab-round-ino .tabs-ino .ant-tabs-tab-btn {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  letter-spacing: 0.03em;
  color: #1e2329;
}
.tab-round-ino .tabs-ino .ant-tabs-ink-bar {
  background: #1e2329;
}
.content-tabs-rules {
  text-align: left;
}
.type-ss {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #c99400;
}
.content-timeline .ant-steps-item-icon {
  display: none;
}
.content-timeline .ant-steps-item-title:after {
  display: none;
}
.content-timeline .ant-steps-item-content {
  text-align: center;
  width: 100%;
}
.content-timeline .ant-steps {
  background: #f6f7fc;
  border-radius: 6px;
  padding: 20px;
}
.content-timeline .ant-steps-item-active {
  background: #e2e5f2;
  border-radius: 6px;
}
.content-timeline .ant-steps-item-description {
  max-width: 100% !important;
}
.content-timeline .ant-steps-item-container {
  padding: 20px 0;
}
.amount-right {
  display: flex;
  justify-content: space-between;
  padding-bottom: 7px;
  padding-top: 5px;
}
.amount-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.amount-left .react-numeric-input {
  margin-left: 5px;
  width: 100%;
  max-width: 130px;
}
.btn-max {
  font-size: 12px;
  width: 39px;
  color: #e2b100;
  background: rgba(255, 201, 22, 0.1);
  border: 1px solid #e2b100;
  border-radius: 6px;
  height: 22px;
}
.gr-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.gr-btn .form-control {
  border-radius: 6px !important;
  background: #eff2f5 !important;
}
.gr-btn b {
  width: 3.9ex !important;
  border-radius: 6px !important;
  background: #1e2329 !important;
}
.gr-btn i {
  background: #fff !important;
}
.gr-btn b:hover {
  cursor: pointer !important;
}

.ino-progress .ant-progress-bg,
.ant-progress-success-bg {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  border-radius: 6px;
}
.ino-progress {
  padding-top: 10px;
}
.text-progress {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #707a8a;
}
.number-box {
  display: flex;
  justify-content: flex-end;
}
.ino-progress .ant-progress-text {
  display: none;
}
.ino-progress .ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  padding-right: 0;
}
.text-progress {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}
.percent-num {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  text-align: right;
  letter-spacing: 0.03em;

  color: #1e2329;
}
.bought-num {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #1e2329;
}
.bought-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #707a8a;
}
.amount-title {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #707a8a;
}
.react-numeric-input .form-control {
  width: 48%;
  margin-left: 26%;
  background: #eff2f5;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding-left: 1ex !important;
  padding-right: 1ex !important;
}
.react-numeric-input .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: transparent;
  outline: 0;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
}
.img-octopus img {
  width: 131px;
}
.img-octopus {
  position: absolute;
  top: 5%;
  left: 0;
}
.img-butterfly img {
  width: 44px;
}
.img-butterfly {
  position: absolute;
  left: 23%;
  top: -34px;
}
.img-jellyfish img {
  width: 172px;
  transform: rotate(22.09deg);
  height: 212px;
}
.img-jellyfish {
  position: absolute;
  right: 0;
  top: 0;
}

.btn-max:hover {
  cursor: pointer;
}

.title-upcoming-sale:hover {
  cursor: pointer;
}
.content-id :hover {
  cursor: pointer;
}
.content-id.active {
  border: 2px solid #0dba88 !important;
  border-radius: 5px;
}

.empty {
  width: 100%;
}
.no-data {
  width: auto;
  max-width: 150px;
}

@media screen and (max-width: 768px) {
  .img-octopus {
    display: none;
  }
  .img-jellyfish {
    display: none;
  }
  .img-butterfly {
    position: absolute;
    left: 14%;
    top: -37px;
  }
  .img-butterfly {
    display: none;
  }
  .main-ino-launch {
    padding-top: 100px;
    padding-bottom: 40px;
  }
  .top-content-ino .title-ino {
    font-size: 28px;
  }
  .tier-rank {
    width: 100%;
  }
  .tier-rank .item-tier {
    /* width: 33%; */
  }
  .tier-rank .item-tier .content-tier {
    padding: 15px !important;
  }
  /* .content-ino-launch-bottom .title {
        font-size: 20px;
    } */
  /* .table-upcoming-ino .w-25 {
    width: 100% !important;
  } */
  .main-table .tr-bottom .box-pro-name .icon-logo img {
    width: 30px;
  }
  .main-table tr td {
    padding: 10px;
    font-size: 12px;
  }
  .main-table .tr-bottom .box-pro-name .name-pro {
    font-size: 12px;
  }
  .main-table .tr-bottom .box-pro-name .name-pro .type {
    font-size: 10px;
  }
  .main-table tr th {
    padding: 10px 5px;
    font-size: 12px;
  }
}
.nodata {
  padding-top: 50px;
}
.content-upcoming .box-img .img-big .allow-open-ino {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #fff;
  font-size: 12px;
  background: #07e0e0;
  padding: 4px 8px;
  border-radius: 4px;
}
.loading-inoMore {
  width: 100%;
  padding-top: 25px;
}
.box-pro-name {
  cursor: pointer;
}
.content-left {
  height: 75%;
}
span.txt-bottom-close {
  color: red;
  font-weight: 600;
}
.text-close {
  height: 100%;
  display: flex;
  align-items: center;
}
.connect {
  display: flex;
  justify-content: center;
}
.backIno:hover {
  cursor: pointer;
}
.content-timeline .ant-steps-item:last-child {
  flex: auto;
}
.box-table table {
  width: 100%;
}
.tap-hash {
  display: flex;
}
.imgCP :hover {
  cursor: pointer;
}

.main-table tr th {
  padding: 25px 20px !important;
  border-radius: 6px;
}
.custom-class .ant-message-notice-content {
  display: flex;
  width: fit-content;
  padding: 5px 15px 5px 7px;
  pointer-events: all;
  margin-left: auto;
  justify-content: right;
  box-shadow: 0px 6px 20px rgba(120, 122, 155, 0.18);
  border-radius: 36.5px;
  background: #ffffff;
}
.empty-detail {
  width: 100%;
  margin: 185px auto;
}
.custom-class .ant-message-warning .anticon.anticon-exclamation-circle {
  background: linear-gradient(
    223.03deg,
    rgba(250, 198, 104, 0.2) -1.23%,
    rgba(221, 161, 52, 0.2) 102.46%
  );
  border-radius: 50%;
  padding: 10px;
}
.custom-class .ant-message-success .anticon.anticon-check-circle {
  background: linear-gradient(
    223.03deg,
    rgba(29, 230, 107, 0.2) -1.23%,
    rgba(12, 183, 79, 0.2) 102.46%
  );
  border-radius: 50%;
  padding: 10px;
}
.custom-class .ant-message-error .anticon.anticon-close-circle {
  background: linear-gradient(
    223.03deg,
    rgba(250, 94, 94, 0.2) -1.23%,
    rgba(242, 69, 69, 0.2) 102.46%
  );
  border-radius: 50%;
  padding: 10px;
}

.custom-class span:nth-child(2) {
  color: #1e2329 !important;
}
@media screen and (max-width: 1200px) {
  .table-upcoming-ino .w-25 {
    width: 33.3% !important;
  }
  /* .table-ino-details .w-4 {
    width: 100%;
  } */
}
@media screen and (max-width: 1024px) {
  .status-ino {
    width: 100%;
    display: flex;
    justify-content: center;
  }
 
  /* .table-ino-details .w-4 {
    width: 100%;
  } */
}
@media screen and (max-width: 550px) {
  .table-ino-details .w-4 {
    width: 100%;
  }
}
.project-name-m.img {
  display: flex;
  align-items: center;
}
.project-name-m.img img {
  width: 34px;
}
.name-round-m {
  margin-left: 10px;
}
.content-header-panel.mobile-ino .project-name-m {
  padding-left: 0 !important;
}
.content-header-panel.mobile-ino .project-name-m:nth-child(1) {
  width: 20%;
  margin: auto;
}
.content-header-panel.mobile-ino .project-name-m:nth-child(2) {
  width: 40%;
  margin: 0 auto;
}
.content-header-panel.mobile-ino .token-name-m.cus {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #c99400;
}
.content-header-panel.mobile-ino .token-name-m {
  width: 30%;
}
.table-mobile.ino .title-rounds:nth-child(1) {
  width: 10%;
}
.table-mobile.ino .title-rounds:nth-child(2) {
  width: 40%;
}
.table-mobile.ino .title-rounds:nth-child(1) {
  width: 30%;
}
.content-joined-mobile.ino-mobile .item-content .text-l {
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #707a8a;
}
.content-joined-mobile.ino-mobile .item-content .text-r {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #1e2329;
}

.button-open-box {
  background-color: #2aba88;
  box-shadow: none;
  color: #ffffff;
  font-weight: 400;
  border: 0px solid transparent;
  border-radius: 8px;
  font-size: 14px;
  width: 110px;
  padding: 5px 10px;
}
.button-open-box.disble {
  background: #495057;
  color: #2aba88;
  pointer-events: none;
}
.button-open-box:hover {
  cursor: pointer;
}
.tooltips-lick {
  font-size: 10px;
  color: #2aba88;
}
.btn-how-stake:hover {
  cursor: pointer;
}
.content-ino-launch-bottom .title .search-project {
  text-align: left;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  align-items: flex-end;
}
.sort {
  padding-right: 10px;
}
.content-ino-launch-bottom .title .search-project .ant-select-selector {
  display: flex;
  align-items: center;
}
.content-ino-launch-bottom .mobie {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobie .search-project {
  justify-content: center;
}
.mobie .sort {
  width: 50%;
}
.mobie .search {
  width: 50%;
}
.mobie .sort .ant-select .ant-select-single .ant-select-show-arrow {
  width: 100%;
}
.mobie .sort .ant-select-selector {
  width: 100%;
}

.mobie .search .ant-input {
  width: 100%;
}
.mobie .sort .ant-select {
  width: 100%;
}
.mobie .search .ant-input {
  width: 100% !important;
}
.sort .ant-select-selector .ant-select-selection-item {
  padding-right: 0px;
}
.finished {
  background: linear-gradient(90.55deg, #fff5e5 -5.78%, #ffe0e2 112.05%);
  height: auto;
  box-shadow: 0 6px 20px hsl(0deg 0% 78% / 44%);
  border-radius: 6px;
  width: 100%;
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;
  padding: 13px 30px;
}
.finished:hover {
  cursor: context-menu;
}

.finished span {
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
}
@media screen and (max-width: 768px) {
  .table-upcoming-ino .w-25 {
    width: 50% !important;
  }
  .content-upcoming .box-img .img-small {
    width: 50px;
    bottom: -30px;
  }
}
@media screen and (max-width: 500px) {
  .tier-rank .item-tier .content-tier .number-tier {
    font-size: 14px !important;
  }
  .content-upcoming .box-img .img-big {
    max-width: 100%;
  }
  .table-upcoming-ino .w-25 {
    width: 100% !important;
  }
  .content-upcoming .box-img .img-small {
    width: 80px;
    bottom: -40px;
  }
}