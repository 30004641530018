.normal-text {
  text-transform: none !important;
}

.fund {
  * {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 1024px) {
    padding-top: 0 !important;
  }

  .container {
    margin: 0 auto;
  }

  .title.new {
    font-family: Poppins;
    font-weight: 600;
    font-size: 48px;
    line-height: 54px;
    letter-spacing: 1%;
    text-align: center;
    margin-bottom: 16px;
    color: #25273D;

    @media (max-width: 1024px) {
      font-size: 28px;
      line-height: normal;
      margin-bottom: 4px;
    }
  }

  .desc.new {
    font-family: Poppins;
    font-weight: 400;
    font-size: 16px;
    line-height: 22.16px;
    letter-spacing: 0%;
    text-align: center;
    color: #616772;
    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: normal;
    }
  }

  .search-project.new .ant-input-group .ant-input {
    width: 380px;
  }

  .fund-table-container {
    overflow-x: auto;
    cursor: pointer;

    .fund-table {
      width: 100%;
      border: 1px solid #E9EDF3;
      border-radius: 6px;
      text-align: left;
      border-collapse: collapse;

      .fund-table-header-tr {
        border: 1px solid #E9EDF3;
        background: #F6F7FC;

        .fund-table-header-th {
          padding: 12.5px 36px;
          font-family: Poppins;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 1%;
          color: #000000;

          @media (max-width: 1024px) {
            padding: 10px;
          }
        }
      }

      .fund-table-body-tr {
        transition: 0.3s ease;
        border: 1px solid #E9EDF3;

        &:hover {
          background: #F6F7FC;
        }

        .fund-table-body-td {
          padding: 12.5px 36px;
          font-family: Poppins;
          font-weight: 500;
          font-size: 17px;
          line-height: 25.5px;
          letter-spacing: 1%;
          color: #1E2329;
          @media (max-width: 1024px) {
            padding: 10px;
            font-size: 14px;
            line-height: normal;
          }

          .td-name-container {
            display: flex;
            align-items: center;
            gap: 7.5px;

            .td-name-logo {
              border-radius: 50%;
              display: block;
              width: 36px;
              height: 36px;
            }

            .td-name {
              .name {
                font-family: Poppins;
                font-weight: 500;
                font-size: 17px;
                line-height: 25.5px;
                letter-spacing: 1%;
                color: #1E2329;
                @media (max-width: 1024px) {
                  font-size: 14px;
                  line-height: normal;
                }
              }

              .symbol {
                margin-top: 4px;
                font-family: Poppins;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 1%;
                color: #AEB7C6;
                @media (max-width: 1024px) {
                  font-size: 12px;
                  line-height: normal;
                }
              }
            }
          }

          .high {
            font-family: Poppins;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 1%;
            text-align: center;
            color: #0CB74F;
            padding: 4px 10px;
            background: #1BE06833;
            border-radius: 37px;
          }

          &.exchange {
            text-align: end;
            color: #B8B9BD;
            gap: 4px;
            a img {
              width: 30px;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }
}