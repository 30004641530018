.btn-set-price:disabled {
    background: #bfbfbf;
    color: #fff;
    cursor: no-drop;
    border: none;
}
.btn-cancel-price:disabled {
    background: #bfbfbf;
    color: #fff;
    cursor: no-drop;
    border: none;
}