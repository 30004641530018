.verify-bscs {
    padding-top: 100px;
    background: url(./img/bg-verify.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: contain;
}
.logo-verify img{
    width: 135px;
    height: 53px;
}
.status-verify img {
    width: 160px;
    height: 160px;
}
.des-verify {
    width: 70%;
    margin: 0 auto;
    color: #787A9B;
    text-align: center;
    font-size: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 138.5%;
    padding-bottom: 27px;
}
.title-verify{
    color: #25273D;
    text-align: center;
    font-size: 40px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    line-height: 138%;
    padding-bottom: 14px;
}
.logo-verify {
    padding-bottom: 45px;
}
.input-verify {
    padding-bottom: 27px;
    width: 40%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
}
.input-verify input {
    width: 100%;
    height: 54px;
    border-radius: 6px;
    background: #FBFDFF;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10) inset;
    border: none;
    padding-left: 10px;
    outline: none;
    font-family: 'Poppins';
    font-style: normal;
       
}
.input-verify input[type=text]:focus-visible {
    border: 1px solid #40a9ff8f
}
.input-verify input[type=text]::placeholder {
    color: #AEB7C6;
        font-size: 14px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        line-height: 138.5%;
        letter-spacing: 0.56px;
}

.icon-verify img{
    width: 24px;
    height: 24px;
}
.icon-verify {
    border-radius: 6px;
    background: #1E2329;
    padding: 8px;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 1%;
}
.icon-verify :hover{
    cursor: pointer;
}
.modal-stt img{
    width: 62px;
    height: 62px;
}
.title-stt {
    color: #1E2329;
    text-align: center;
    font-size: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    padding-top: 20px;
    padding-bottom: 8px;
}
.des-stt {
    color: #1E2329;
    text-align: center;
    font-size: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 127.023%;
}
.modal-stt {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}
.modal-content-verify{
    color: #787A9B;
    font-size: 14px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 172.5%;
    padding: 0 20px;
}
.content3 {
    padding-top: 20px;
    color: #787A9B;
    font-size: 14px;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    line-height: 180%;
}
.content4 {
    color: #787A9B;
    font-size: 14px;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    line-height: 180%;
}
.ok-verify{
    border-radius: 6px;
    background: #1E2329;
    box-shadow: 0px 6px 20px 0px rgba(198, 198, 198, 0.44);
    color: #fff;
}
.popup-verify .ant-modal-footer{
    text-align: center;
    border-top:none;
    padding-bottom: 40px;
}
.ok-verify {
    border-radius: 6px;
    background: #1E2329;
    box-shadow: 0px 6px 20px 0px rgba(198, 198, 198, 0.44);
    color: #fff;
    border: none;
    width: 50%;
    height: 45px;
}
.ok-verify:hover{
    cursor: pointer;
}
.modal-content-verify-s {
    color: #787A9B;
    font-size: 14px;
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
    line-height: 180%;
    padding-left: 20px
}

@media screen and (max-width: 768px) {
    .title-verify{ 
        font-size: 32px;
    }
    .des-verify {
        padding: 0px 15px 27px 15px;
        width: 100%;
    }
    .input-verify{
        width: 90%;
        padding-top: 10px;
    }
    .status-verify img {
        width: 100px;
        height: 100px;
    }
}