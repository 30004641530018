.join {
  max-width: 1166px;

  * {
    margin: 0;
    padding: 0;
  }

  margin: 0 auto;

  @media (max-width: 1024px) {
    padding: 0 32px;
  }

  .title-new {
    margin-bottom: 0;
  }

  .new-desc {
    margin-top: 16px;
    font-family: Poppins;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0%;
    text-align: center;
    color: #707A8A;

    @media (max-width: 1024px) {
      font-size: 14px;
      line-height: normal;
    }
  }

  .flex {
    margin-top: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 22px;

    @media (max-width: 1024px) {
     flex-direction: column;
    }

    .new-box {
      flex: 1;
      box-shadow:
        0px 24px 24px -12px #0E3F7E0A,
        0px 12px 12px -6px #0E3F7E0A,
        0px 6px 6px -3px #2A33460A,
        0px 3px 3px -1.5px #2A33460A,
        0px 1px 1px -0.5px #2A33450A,
        0px 0px 0px 1px #0E3F7E0A;
      border-radius: 16px;
      padding: 16px;
      background: #FFFFFF;
      min-height: 298px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: 0.5s ease;

      @media (max-width: 1024px) {
        width: 100%;
        max-width: 100%;
      }

      &:hover {
        cursor: pointer;
        background: linear-gradient(270deg, #F5CB11 0%, #FF9B1B 100%),
          linear-gradient(180deg, rgba(255, 255, 255, 0.32) 0%, rgba(255, 255, 255, 0) 100%);

      }

      img {
        height: 60px;
        width: auto;
        display: block;
      }

      .box-title {
        font-family: Poppins;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0%;
        color: #1E2329;
        text-align: start;
        margin-top: 12px;
      }

      .box-content {
        font-family: Poppins;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0%;
        color: #707A8A;
        text-align: start;
        margin-top: 12px;
      }

      .box-btn {
        box-shadow: 0px 6px 20px 0px #C6C6C670;
        background: #1E2329;
        border-radius: 6px;
        width: 100%;
        padding-top: 13px;
        padding-bottom: 14px;
        color: #D9D9D9;
      }
    }
  }
}