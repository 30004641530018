.main-body {
  padding-top: 92px;
}

.columns.res {
  align-items: center;
}

.content-ido-banner {
  text-align: left;
}

.content-ido-banner .title-ido {
  font-weight: 500;
  font-size: 40px;
  line-height: 138%;
  color: #1e2329;
  margin-bottom: 17px;
}

.content-ido-banner .desc-ido {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 138.5%;
  color: #616772;
  margin-bottom: 24px;
}

.content-ido-banner .list-info-stake {
  background: linear-gradient(89.64deg, #fffceb -12.7%, #ffe8d8 112.66%);
  border-radius: 6px;
  padding: 12px 17px;
  display: flex;
  max-width: 400px;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px;
}

.content-ido-banner .list-info-stake li {
  list-style-type: none;
}

.content-ido-banner .list-info-stake li span {
  display: block;
}

.content-ido-banner .list-info-stake li .text-top {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #707a8a;
  margin-bottom: 5px;
}

.content-ido-banner .list-info-stake li .text-bottom {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #1e2329;
}

.button-ido-stake {
  margin-left: -10px;
}

.btn-stake {
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  width: 142px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #ffffff;
  transition: 0.3s ease;
}

.text-pro-cus {
  position: absolute;
  top: 0%;
  right: 1%;
  color: #1e2329;
  font-size: 14px;
}

.process-join-ido .ant-progress-text {
  display: none;
}

.btn-stake:hover {
  background: #3f4a57;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
}

.btn-stake-pool {
  background: #fff;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  width: 142px;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;
  color: #1e2329;
  transition: 0.3s ease;
}

.btn-stake-pool:hover {
  color: #707a8a;
}

.main-tier {
  max-width: 430px;
  width: 100%;
  margin-left: auto;
  background: linear-gradient(98.28deg, #ffd7b0 2.24%, #df9752 112.02%);
  box-shadow: -10px 20px 40px rgba(208, 208, 208, 0.25);
  border-radius: 6px;
}

.main-tier .top-tier {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  position: relative;
  z-index: 1;
  height: 80px;
}

.main-tier .top-tier .logo-tier-bscs {
  width: 67px;
}

.main-tier .top-tier .logo-tier-current {
  width: 54px;
}

.main-tier .body-tier .list-tier {
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
  margin-bottom: 0;
}

.main-tier .body-tier {
  position: relative;
  background: rgba(255, 255, 255, 0.5);
  border: 0.9px solid #d2d6e2;
  backdrop-filter: blur(20px);
  border-radius: 0px 0px 6px 6px;
  margin: -5px;
  position: relative;
}

.main-tier .body-tier::before {
  content: "";
  background: linear-gradient(98.28deg, #ffd7b0 2.24%, #e6a466 112.02%);
  border-radius: 19px 19px 0 0;
  width: 239.27px;
  height: 62px;
  position: absolute;
  right: 15px;
  top: -63px;
  z-index: -1;
  opacity: 0.5;
}

.main-tier .body-tier .list-tier li {
  /* list-style-type: none;
  text-align: center; */
  list-style-type: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.main-tier .body-tier .list-tier li .img-tier {
  z-index: 1;
  margin: 5px auto;
  position: relative;
}

.main-tier .body-tier .list-tier li:nth-child(1) .img-tier {
  width: 25px;
}

.main-tier .body-tier .list-tier li:nth-child(2) .img-tier {
  width: 25px;
}

.main-tier .body-tier .list-tier li:nth-child(3) .img-tier {
  width: 25px;
}

.main-tier .body-tier .list-tier li:nth-child(4) .img-tier {
  width: 30px;
}

.main-tier .body-tier .list-tier li:nth-child(5) .img-tier {
  width: 33px;
}

.main-tier .body-tier .list-tier li:nth-child(6) .img-tier {
  width: 33px;
  height: 30px;
}

.main-tier .body-tier .list-tier li .number-stake {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #1e2329;
}

.main-tier .body-tier .list-tier li .name-tier {
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #707a8a;
  text-transform: uppercase;
}

.line-tier {
  z-index: 0;
  width: 100%;
  height: 4px;
  background: #9a9a9a;
  box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.65);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.main-tabs-ido .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.main-tabs-ido .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  margin: 0 auto;
}

.main-tabs-ido .ant-tabs-tab {
  width: 130px;
  justify-content: center;
  padding: 8px 0;
}

.main-tabs-ido .ant-tabs-tab.ant-tabs-tab-active {
  background: #1e2329;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.main-tabs-ido .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.main-tabs-ido .ant-tabs-tab .ant-tabs-tab-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #707a8a;
}

.main-tabs-ido .ant-tabs-nav-list {
  background: #f6f7fc;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  padding: 5px;
}

.main-tabs-ido .ant-tabs-tab+.ant-tabs-tab {
  margin: 0;
}

.main-tabs-ido .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}

.main-tabs-ido .ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: 0px solid #f0f0f0;
}

.main-caledar .fc-theme-standard th {
  border: 1px solid transparent !important;
}

.main-caledar .fc-theme-standard th .fc-scrollgrid-sync-inner {
  height: 30px;
  line-height: 30px;
}

.main-caledar .fc-theme-standard .fc-scrollgrid {
  border: 1px solid transparent !important;
}

.main-caledar .fc-theme-standard td {
  border: 1px solid transparent !important;
  padding-bottom: 10px;
  min-height: 80px;
}

.main-caledar .fc-col-header-cell .fc-scrollgrid-sync-inner {
  background: #fff;
  margin-right: 3px;
  border-radius: 5px;
}

.main-caledar .fc-daygrid-day .fc-daygrid-day-frame {
  background: #f5f7ff;
  border-radius: 6px;
  margin-right: 3px;
  margin-bottom: 3px;
  width: 150px;
  min-height: 80px;
}

.main-caledar .fc-day-past .fc-daygrid-day-frame {
  border-radius: 6px;
  border: 1px solid #aab6cd;
  background: #fff;
  min-height: 80px;
}

.main-caledar .fc-day-today .fc-daygrid-day-frame {
  background: linear-gradient(104.31deg, #ffd953 -40.22%, #eb0029 350.82%);
  box-shadow: 0px 2px 13px #fac668;
  border-radius: 6px;
  color: #fff;
}

.main-caledar .fc-button {
  background: #f5f7fc;
  color: #000;
  border: 1px solid transparent !important;
}

.main-caledar .fc-button:hover {
  background: linear-gradient(90deg, #0079ff 2%, #3255d1 100%);
  color: #fff;
  border: 1px solid transparent !important;
}

.main-caledar .fc-button:nth-child(1) {
  margin-right: 5px;
}

.main-caledar .fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}

.main-caledar .fc-media-screen .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk:nth-child(1) {
  margin: 0 auto;
}

.main-caledar .fc-media-screen .fc-header-toolbar.fc-toolbar .fc-toolbar-chunk:nth-child(3) .fc-today-button {
  display: none;
}

.main-caledar {
  margin-top: 20px;
  position: relative;
  height: 700px;
}

.main-caledar .fc .fc-button-primary:focus {
  box-shadow: 0 0 0 0 rgb(76 91 106 / 50%);
}

.switch-calendar {
  display: flex;
  align-items: center;
  justify-content: end;
  /* margin-bottom: -35px; */
  width: 200px;
  margin-left: auto;
  z-index: 999;
  position: absolute;
  right: 0;
  top: 15px;
}

.switch-calendar span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #1e2329;
  margin-right: 5px;
}

.bage-v {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  border-radius: 5px;
  padding: 6px 10px;
  background: #1E3A8A;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  align-items: center;
  z-index: 9;
}

.bage-d {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  border-radius: 5px;
  padding: 6px 10px;
  background: green;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  align-items: center;
  z-index: 9;
}

.bage-d-detail {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: green;
  width: fit-content;
  color: #fff;
  padding: 6px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.main-tabs-ido .ant-tabs {
  position: relative;
  z-index: 5;
  width: auto;
  margin: auto;
}

.list-info-details .txt-r .tooltips {
  color: #c99400;
}

.main-tabs-ido {
  position: relative;
  margin-bottom: 50px;
}

.main-list-upcoming .content-up {
  background: #fff !important;
  border: 0.9px solid #eff3f8;
  padding: 8px;
  /* background: linear-gradient(206.08deg, #fdf0f7 -4.04%, #fff6f1 106.7%); */
  border-radius: 6px;
}

.main-list-upcoming .content-up .box-content-top .box-img {
  margin: 0 auto;
}

.main-list-upcoming .content-up .box-content-top {
  border-radius: 6px;
  text-align: center;
}

.main-list-upcoming .content-up .box-content-bottom {
  padding: 10px 24px;
  text-align: left;
}

.box-content-bottom .guide-swap .name-token {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1e2329;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.box-content-bottom .guide-swap .name-token .symbol {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
  margin-left: 10px;
}

.box-content-bottom .guide-swap .desc {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #707a8a;
  margin-bottom: 15px;
}

.box-content-bottom .guide-swap .list-swap {
  padding-left: 0;
}

.box-content-bottom .guide-swap .list-swap li {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.box-content-bottom .guide-swap .list-swap li:last-child {
  margin-bottom: 0;
}

.box-content-bottom .guide-swap .list-swap li .text-l {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
}

.box-content-bottom .guide-swap .list-swap li .text-r {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #1e2329;
}

.box-content-bottom .guide-swap .list-swap {
  padding: 10px 15px;
  /* background: #f8dddd; */
  background: #f2f5f8 !important;
  border-radius: 8px;
}

.box-content-bottom .guide-swap .list-socail {
  margin-top: 20px;
  margin-bottom: 20px;
}

.box-content-bottom .guide-swap .list-socail .box-icon-sc {
  width: 20px;
}

.box-content-bottom .guide-swap .list-socail .box-icon-sc img {
  margin-left: 10px;
  width: 20px;
}

.box-content-bottom .guide-swap .list-socail .box-icon-sc {
  display: flex;
  align-items: center;
  width: auto;
}

.box-content-bottom .guide-swap .list-socail .text-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.box-content-bottom .guide-swap .list-socail .text-item:last-child {
  margin-bottom: 0;
}

.box-content-bottom .guide-swap .list-socail .text-item .text-l {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #707a8a;
}

.box-content-bottom .guide-swap .list-socail .text-item .text-r {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #1e2329;
  display: flex;
  align-items: center;
}

.box-content-bottom .guide-swap .list-socail .text-item .text-r .icon-symbol {
  width: 28px;
  margin-right: 5px;
}

.btn-view {
  width: 100%;
  background: #1e2329;
  color: #fff;
  margin: 0pc;
  transition: 0.3s ease;
}

.btn-view:hover {
  background: #3f4a57;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
}

.img-copy {
  width: 16px;
  margin-left: 5px;
}

.main-list-upcoming .columns {
  justify-content: center;
}

.main-list-upcoming .colum:nth-child(5n + 1) .content-up {
  background: linear-gradient(206.08deg, #fdf0f7 -4.04%, #fff6f1 106.7%);
}

.main-list-upcoming .colum:nth-child(5n + 2) .content-up {
  background: linear-gradient(206.63deg, #eff3fe -0.03%, #fbeffb 118.82%);
}

.main-list-upcoming .colum:nth-child(5n + 3) .content-up {
  background: linear-gradient(203.97deg, #fff7ec 10.1%, #fdf2d2 106.19%);
}

.main-list-upcoming .colum:nth-child(5n + 4) .content-up {
  background: linear-gradient(200.25deg, #f7ffff -2.56%, #e0feff 104.96%);
}

.main-list-upcoming .colum:nth-child(5n + 5) .content-up {
  background: linear-gradient(206.02deg, #f5faff -13.71%, #e4e9ee 118.7%);
}

.main-list-upcoming .colum:nth-child(5n + 1) .box-content-bottom .guide-swap .list-swap {
  background: #f8dddd;
}

.main-list-upcoming .colum:nth-child(5n + 2) .box-content-bottom .guide-swap .list-swap {
  background: #ead6ff;
}

.main-list-upcoming .colum:nth-child(5n + 3) .box-content-bottom .guide-swap .list-swap {
  background: #ffebb8;
}

.main-list-upcoming .colum:nth-child(5n + 4) .box-content-bottom .guide-swap .list-swap {
  background: #cff7f7;
}

.main-list-upcoming .colum:nth-child(5n + 5) .box-content-bottom .guide-swap .list-swap {
  background: #dee4ea;
}

.full-search {
  text-align: center;
  margin-top: 18px;
}

.full-search .btn-search-project {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;

  color: #1e2329;
  background: transparent;
  border: 0px solid transparent;
  cursor: pointer;
  padding: 0;
}

.full-search .btn-search-project:hover {
  text-decoration-line: underline;
}

.search-project .ant-input-group-addon {
  display: none;
}

.search-project {
  position: relative;
}

.search-project .icon-search {
  width: 20px;
  position: absolute;
  left: 10px;
  top: 10px;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto-webkit-focus-ring-color !important;
}

.breadcrum-swap .list-breadcrum {
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.breadcrum-swap .list-breadcrum li {
  list-style-type: none;
  padding-right: 10px;
  color: #707a8a;
}

.breadcrum-swap .list-breadcrum li:last-child {
  color: #1e2329;
}

.info-project-l .name-project {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #1e2329;
  margin-bottom: 5px;
}

.info-project-l .name-project .token {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
}

.info-project-l .social-name .box-icon-sc img {
  width: 20px;
  margin-right: 25px;
}

.social-project {
  text-align: left;
  margin-left: 25px;
}

.info-project-l .box-icon {
  width: 76px;
}

.info-project-l .box-icon img {
  border-radius: 99px;
}

.content-ido-details .info-project-l {
  display: flex;
  align-items: center;
}

.info-project-l .social-name .box-address .img-copy img {
  width: 16px;
}

.info-project-l .social-name {
  display: flex;
  align-items: self-end;
}

.info-project-l .social-name .box-address {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.03em;
  color: #1e2329;
  margin-left: 25px;
}

.info-project-r .list-token-info {
  padding-left: 0;
  margin-bottom: 0;
  text-align: left;
  width: 47%;
  position: relative;
}

.info-project-r .list-token-info li {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.info-project-r .list-token-info li:last-child {
  margin-bottom: 0;
}

.content-ido-details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.info-project-r {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.info-project-r .list-token-info li .text-l {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
}

.info-project-r .list-token-info li .text-r {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.01em;
  color: #1e2329;
  display: flex;
  align-items: center;
}

.info-project-r .list-token-info li .text-r .icon-symbol img {
  width: 24px;
  margin-right: 5px;
}

.name-color {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  color: #707a8a;
}

.info-project-r .list-token-info:first-child::before {
  content: "";
  background: #aab6cd;
  width: 0.5px;
  height: 100%;
  position: absolute;
  right: -6%;
  top: 0;
}

.content-ido-details .desc {
  text-align: left;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #707a8a;
  margin-top: 15px;
  margin-bottom: 0;
  padding-right: 50px;
}

/* .content-details-rounds {
  background: linear-gradient(206.08deg, #fdf0f7 -4.04%, #fff6f1 106.7%);
  border-radius: 6px;
  padding: 15px 30px 35px 30px;
  height: auto;
  position: relative;
} */
.content-details-rounds {
  border: 0.9px solid #eff3f8;
  background: #fff !important;
  border-radius: 6px;
  padding: 15px 30px 35px 30px;
  height: auto;
  position: relative;
}

.content-details-rounds .rounds-name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #1e2329;
  margin-bottom: 7px;
}

.content-details-rounds .url-name .img img {
  width: 30px;
  border-radius: 99px;
}

.content-details-rounds .url-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
  margin-bottom: 17px;
}

/* .content-details-rounds .list-info-details {
  padding-left: 0;
  margin-bottom: 0;
  text-align: left;
  padding: 16px;
  border-radius: 6px;
  background: #f8dddd;
  margin-bottom: 15px;
} */
.content-details-rounds .list-info-details {
  background: linear-gradient(68deg, #fffceb 0.01%, #ffe8d8 100%) !important;
  padding-left: 0;
  margin-bottom: 0;
  text-align: left;
  padding: 16px;
  border-radius: 6px;
  background: #f8dddd;
  margin-bottom: 15px;
}

.content-details-rounds .list-info-details li {
  list-style-type: none;
}

.content-details-rounds .list-info-details li:nth-child(5) {
  display: flex;
  flex-wrap: wrap;
}

.content-details-rounds .list-info-details li:nth-child(5) .txt-l {
  width: 30%;
}

.content-details-rounds .list-info-details li:nth-child(5) .txt-r {
  width: 70%;
}

.content-details-rounds .list-info-details li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.content-details-rounds .list-info-details li:last-child {
  margin-bottom: 0;
}

.content-details-rounds .list-info-details li .txt-l {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
}

.content-details-rounds .list-info-details li .txt-r {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: right;
  letter-spacing: 0.01em;
  color: #1e2329;
}

.content-details-rounds .rounds-join-pool {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
  position: relative;
  width: fit-content;
  margin: 0 auto;
}

.content-details-rounds .rounds-join-pool .times-join {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #1e2329;
  display: block;
  margin-top: 6px;
}

.content-details-rounds .rounds-join-pool::before {
  content: "";
  /* background: #fb8f8f; */
  background: #f2b005 !important;
  width: 10px;
  height: 10px;
  border-radius: 99px;
  position: absolute;
  top: 5px;
  left: -17px;
}

.content-details-rounds .rounds-join-pool::after {
  content: "";
  /* background: #fb8f8f; */
  background: #f2b005 !important;
  opacity: 0.5;
  width: 16px;
  height: 16px;
  border-radius: 99px;
  position: absolute;
  top: 1.5px;
  left: -20px;
}

.main-list-details {
  margin-top: 35px;
  margin-bottom: 35px;
}

.content-details-rounds .rounds-process {
  text-align: left;
  margin-top: 15px;
  position: relative;
  margin-bottom: 0px;
}

.content-details-rounds .rounds-process .title-process {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
  display: flex;
}

.rounds-process .ant-progress-inner {
  background: #ffffff;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.rounds-process .ant-progress-text {
  position: absolute;
  right: 0;
  top: -20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: 0.03em;
  color: #1e2329;
}

.rounds-process .ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  padding-right: 0;
}

.rounds-process .balance-rounds {
  position: absolute;
  right: 0;
  bottom: -25px;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: right;
  letter-spacing: 0.03em;
  color: #707a8a;
}

.btn-join {
  height: auto;
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  width: 100%;
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.06em;
}

.mar-b-10 {
  margin-bottom: 10px !important;
}

.main-list-details .columns {
  justify-content: center;
}

.main-list-details .colum:nth-child(3n + 1) .content-details-rounds {
  background: linear-gradient(206.08deg, #fdf0f7 -4.04%, #fff6f1 106.7%);
}

.main-list-details .colum:nth-child(3n + 2) .content-details-rounds {
  background: linear-gradient(206.63deg, #eff3fe -0.03%, #fbeffb 118.82%);
}

.main-list-details .colum:nth-child(3n + 3) .content-details-rounds {
  background: linear-gradient(203.97deg, #fff7ec 10.1%, #fdf2d2 106.19%);
}

.main-list-details .colum:nth-child(3n + 1) .content-details-rounds .list-info-details {
  background: #f8dddd;
}

.main-list-details .colum:nth-child(3n + 2) .content-details-rounds .list-info-details {
  background: #ead6ff;
}

.main-list-details .colum:nth-child(3n + 3) .content-details-rounds .list-info-details {
  background: #ffebb8;
}

.main-list-details .colum:nth-child(3n + 1) .content-details-rounds .rounds-join-pool::before {
  background: #fb8f8f;
}

.main-list-details .colum:nth-child(3n + 1) .content-details-rounds .rounds-join-pool::after {
  background: #fb8f8f;
}

.main-list-details .colum:nth-child(3n + 2) .content-details-rounds .rounds-join-pool::before {
  background: #c58dff;
}

.main-list-details .colum:nth-child(3n + 2) .content-details-rounds .rounds-join-pool::after {
  background: #c58dff;
}

.main-list-details .colum:nth-child(3n + 3) .content-details-rounds .rounds-join-pool::before {
  background: #f2b005;
}

.main-list-details .colum:nth-child(3n + 3) .content-details-rounds .rounds-join-pool::after {
  background: #f2b005;
}

.icon-r {
  width: 30px;
  background: #fff;
  border-radius: 99px;
  box-shadow: 0px 6px 20px rgb(198 198 198 / 79%);
  padding: 4px;
  margin-right: 5px;
}

.icon-r img {
  border-radius: 99px;
}

.main-list-end {
  width: 100%;
  margin-top: 40px;
}

.main-list-end .main-table {
  width: 100%;
}

.main-list-end .main-table .tr-bottom {
  transition: 0.3s ease;
}

.main-list-end .main-table .tr-bottom:hover {
  background: #f6f7fc;
}

.main-table tr th {
  padding: 20px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #000000;
}

.main-table .tr-top {
  background: #f6f7fc;
  border: 0.9px solid #e9edf3;
  border-radius: 6px;
}

.main-table tr td {
  padding: 20px;
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #1e2329;
}

.main-table tr td.name-project {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #1e2329;
}

.main-table tr td .icon-symbol img {
  width: 28px;
  margin-right: 5px;
}

.main-color-down {
  width: 60px;
  height: 20px;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: #b70c0c;
  background: rgba(224, 27, 27, 0.2);
  border-radius: 37px;
  padding: 5px 10px;
  margin-left: 5px;
}

.main-color-up {
  width: 60px;
  height: 20px;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: #0cb74f;
  background: rgba(27, 224, 104, 0.2);
  border-radius: 37px;
  padding: 5px 10px;
  margin-left: 5px;
}

.search-project {
  text-align: left;
  margin-bottom: 20px;
  position: relative;
}

.search-project .ant-input-group .ant-input {
  width: 250px;
  height: 44px;
  background: #fbfdff;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding-left: 40px;
  transition: 0.1s ease;
}

.search-project .ant-input-group-addon {
  position: absolute;
  left: 10px !important;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border: 2px solid #ff373766;
  background: transparent;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
  position: absolute;
  left: 10px !important;
  top: 50%;
  transform: translateY(-50%);
}

.search-project .ant-btn {
  background: transparent;
  border: 0px solid transparent;
}

.box-search-round {
  display: flex;
  justify-content: space-between;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  width: 120px;
  height: 44px;
  background: #eff2f5;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.filter-project .ant-select-selection-item {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1e2329;
  line-height: 44px !important;
}

.main-table tr td .main-action {
  width: 82px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.01em;
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  color: #fff;
  padding: 7px 15px;
}

.linkMedia {
  padding-right: 10px;
}
.linkMedia.new {
  padding-right: 0;
}

.btnCalender {
  color: #707a8a;
  background: #f5f7ff;
  border: none;
  margin-left: 10px;
  transition: 0.3s ease;
}

.btn-loadmore {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.06em;
  color: #1e2329;
  background: #f3f3f4;
  border-radius: 6px;
  width: 210px;
  height: 44px;
  border: none;
  cursor: pointer;
}

.main-full-project {
  margin-top: 10px;
  text-align: left;
}

.w-8 {
  width: 60%;
}

.w-4 {
  width: 40%;
}

.slider-big .item-slider-big {
  margin-bottom: 10px;
  border-radius: 6px;
}

.slider-small .item-slider-small {
  padding: 10px;
}

.slider-small {
  margin: 0 -10px;
}

.content-swap-search .box-img .img img {
  width: 50px;
}

.content-swap-search .box-img {
  margin-bottom: 10px;
}

.content-swap-search {
  background: #ffffff;
  border: 0.9px solid #e9edf3;
  box-shadow: 0px 4px 30px rgba(132, 132, 132, 0.1);
  border-radius: 6px;
  padding: 15px;
  position: fixed;
  width: 446px;
  z-index: 999;
}

.content-swap-search .name-project-s {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #1e2329;
  margin-bottom: 10px;
}

.content-swap-search .name-project-s .token-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
}

.content-swap-search .desc {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #707a8a;
  margin-bottom: 10px;
}

.social-address .list-social .box-icon-sc img {
  width: 20px;
  margin-right: 14px;
}

.social-address .box-address .img-copy img {
  width: 20px;
}

.social-address .box-address {
  padding: 4px;
  background: #f6f7fc;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #1e2329;
}

.social-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.list-info-search .list-s {
  padding-left: 0;
  margin-bottom: 0;
}

.list-info-search .list-s li {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.list-info-search .list-s li .txt-l {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
}

.list-info-search .list-s li .txt-r {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  text-align: right;
  letter-spacing: 0.01em;
  color: #1e2329;
  display: flex;
  gap: 5px;
}

.list-info-search .list-s:first-child {
  padding-bottom: 18px;
  border-bottom: 0.5px solid #aab6cd;
  margin-bottom: 28px;
}

.list-info-search .list-s:nth-child(2) {
  margin-bottom: 20px;
}

.content-description .content-desc .item-desc .title {
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  /* identical to box height */
  color: #1e2329;
  margin-bottom: 15px;
}

.content-description .content-desc .item-desc .desc-s {
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: #707a8a;
}

.content-description .content-desc .item-desc .desc-s b,
strong {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: black;
}

.content-description .content-desc {
  margin-top: 45px;
}

.content-description .content-desc .item-desc {
  margin-bottom: 30px;
}

.box-apply-launch {
  margin-top: 80px;
  margin-bottom: 40px;
}

.box-apply-launch .desc {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 138.5%;
  /* or 22px */
  color: #e2b100;
}

.box-apply-launch .txt {
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  color: #1e2329;
}

.btn-apply-launch {
  width: 235px;
}

.btn-loadmore :hover {
  cursor: pointer;
}

.imgChain {
  width: 20px;
  height: 20px;
}

.main-color-price {
  color: #c99400 !important;
}

.item-slider-big>div {
  width: 100% !important;
}

.main-caledar .btnCalender {
  position: relative;
  font-size: 14px;
  background: transparent;
  cursor: pointer;
}

.main-caledar .btnCalender::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 99px;
  background: linear-gradient(104.31deg, #ffd953 -40.22%, #eb0029 350.82%);
  position: absolute;
  left: -7px;
  top: 48%;
  transform: translateY(-50%);
}

.button-name {
  padding: 0;
  background: transparent;
  border: 0px solid transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #1e2329;
  cursor: pointer;
}

.button-detail {
  padding: 0;
  background: transparent;
  border: 0px solid transparent;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height */
  color: #fff;
  cursor: pointer;
}

.bsc-p-launchpad_detail-allocations-empty {
  background-color: #1a1916;
  height: 80px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* span {
    font-size: 15px;
    color: #BEBCBA;
  } */
}

.row-claim-token {
  display: flex;
  justify-content: space-between;
}

.row-allocation-token {
  display: flex;
  justify-content: space-between;
}

.row-allocation-token-apt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.text {
  font-weight: 400;
  font-size: 14px;
  color: #707a8a;
  text-align: left;
}

.name-row {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #1e2329;
}

.row-claim-box {
  padding-top: 21px;
}

.row-chane {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.btn-rounds .ant-btn[disabled],
.btn-rounds .ant-btn[disabled]:active,
.btn-rounds .ant-btn[disabled]:focus,
.btn-rounds .ant-btn[disabled]:hover {
  background: #e4e4eb;
  box-shadow: 0px 2px 0px rgb(0 0 0 / 15%);
  border-radius: 6px;
  color: #b6b8c4;
}

.btn-rounds {
  /* display: flex; */
  justify-content: space-between;
  padding-top: 10px;
}

@media screen and (max-width: 768px) {
  .w-40 {
    width: 100% !important;
  }
}

@media screen and (max-width: 550px) {
  .ido-banner {
    margin-bottom: 30px;
  }

  .main-tabs-ido.mobile .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    line-height: 42px;
  }

  .main-tabs-ido.mobile .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #ffffff;
    border: 1px solid #e9edf3;
    border-radius: 6px;
    width: 100%;
    margin-right: auto;
  }

  .main-tabs-ido.mobile .ant-select {
    text-align: left;
    width: 100%;
  }

  .select-mobile {
    text-align: left;
    margin-bottom: 15px;
    width: 50%;
  }

  .search-project .ant-input-group .ant-input {
    width: 100%;
    display: block;
  }

  .main-list-end.mobile .box-search-round {
    display: flex;
    flex-wrap: wrap;
  }

  .collapse-mobile-ido {
    width: 100%;
  }

  .project-name-m {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height */
    color: #1e2329;
  }

  .project-name-m .button-name {
    font-size: 12px;
  }

  .collapse-mobile-ido .anticon {
    position: absolute;
    right: 0;
    top: 13px;
  }

  .collapse-mobile-ido .anticon-right {
    background: #1e2329;
    border-radius: 99px;
    padding: 5px;
  }

  .collapse-mobile-ido .anticon-right svg {
    fill: #fff;
  }

  .collapse-mobile-ido .ant-collapse>.ant-collapse-item {
    border-bottom: 0px solid #d9d9d9;
    background: #fff;
  }

  .collapse-mobile-ido .ant-collapse {
    border: 0px solid #d9d9d9;
  }

  .content-header-panel {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .content-header-panel .project-name-m {
    width: 40%;
    padding-left: 10px;
    text-align: left;
  }

  .content-header-panel .token-name-m {
    width: 40%;
    font-size: 12px;
  }

  .collapse-mobile-ido .ant-collapse-header-text {
    width: 100%;
  }

  .collapse-mobile-ido .ant-collapse-header {
    padding: 10px 0;
  }

  .table-mobile {
    display: flex;
  }

  .table-mobile .title-rounds {
    width: 40%;
    padding: 15px 0;
  }

  .table-mobile {
    background: #f6f7fc;
    border: 0.9px solid #e9edf3;
    border-radius: 6px;
  }

  .collapse-mobile-ido .ant-collapse-content {
    background: #f6f7fc;
    border-radius: 6px;
    border-top: 0px solid #d9d9d9;
  }

  .content-joined-mobile .item-content {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .content-joined-mobile .item-content:last-child {
    margin-bottom: 0;
  }

  .content-joined-mobile .item-content .text-r {
    color: #c99400;
    border-radius: 12px;
    border: none;
  }

  .content-joined-mobile .item-content .text-l {
    font-weight: 600;
  }

  .search-project {
    width: 100%;
    display: flex;
  }

  .main-list-end {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .content-ido-banner .title-ido {
    font-size: 28px;
  }

  .ido-banner .content-ido-banner .desc-ido {
    font-size: 14px;
  }

  .ido-banner .content-ido-banner .list-info-stake li .text-top {
    font-size: 10px;
  }

  .ido-banner .content-ido-banner .list-info-stake li .text-bottom {
    font-size: 14px;
  }

  .btn-stake {
    font-size: 14px;
    width: 50%;
  }

  .btn-stake-pool {
    font-size: 14px;
    width: 50%;
  }

  .button-ido-stake {
    display: flex;
  }

  .content-ido-details .info-project-l {
    flex-wrap: wrap;
  }

  .social-project {
    margin-left: 0;
  }

  .info-project-l .social-name {
    flex-wrap: wrap;
  }

  .info-project-l .social-name .box-address {
    margin-left: 0;
    margin-top: 10px;
  }

  .info-project-r .list-token-info {
    width: 100%;
  }

  .info-project-r .list-token-info:first-child {
    margin-bottom: 10px;
  }

  .info-project-r {
    flex-wrap: wrap;
  }

  .name-color {
    font-size: 14px;
  }

  .w-8 {
    width: 100%;
  }

  .w-4 {
    width: 100% !important;
  }

  .table-swap .columns {
    flex-direction: column-reverse;
  }

  .social-address {
    display: block;
  }

  .social-address .box-address {
    width: fit-content;
    margin-top: 10px;
  }

  .switch-calendar {
    top: 12px;
  }
}

img.coppy:hover {
  cursor: pointer;
}

.main-tier .body-tier .list-tier .cuTier:before {
  content: "";
  width: 10%;
  height: 4px;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
}

.main-tier .body-tier .list-tier .agTier:before {
  content: "";
  width: 25%;
  height: 4px;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
}

.main-tier .body-tier .list-tier .auTier:before {
  content: "";
  width: 36%;
  height: 4px;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
}

.main-tier .body-tier .list-tier .pTier:before {
  content: "";
  width: 51%;
  height: 4px;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
}

.main-tier .body-tier .list-tier .cTier:before {
  content: "";
  width: 70%;
  height: 4px;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
}

.main-tier .body-tier .list-tier .dTier:before {
  content: "";
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
}

main-action .button-name {
  color: #fff;
  font-size: 12px;
}

.inf-claim .anticon.anticon-schedule {
  font-size: 20px;
  margin-top: 5px;
}

.inf-claim .anticon.anticon-schedule svg {
  fill: rgb(16, 142, 233);
}

.btn-claim {
  height: auto;
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  width: 47%;
  margin: 0;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.06em;
}

.btn-claim:hover {
  background: #3f4a57;
  color: #ffff;
}

.btn-refund:hover {
  color: #1e2329;
  background: #f0f0f0;
}

.btn-refund {
  height: auto;
  background: #fff;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  width: 47%;
  margin: 0;
  color: #1e2329;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.06em;
}

/* .columns .ant-spin.ant-spin-lg.ant-spin-spinning.ant-spin-show-text{
  padding-top: 30px;
    color: #edb37b;
}
.columns i.ant-spin-dot-item{
  background: #edb37b;
} */
.ant-message-notice-content {
  background: #fff0df;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  /* height: 70px; */
}

/* .ant-message-custom-content.ant-message-warning {
  padding-top: 6px;
}
.ant-message-custom-content.ant-message-success {
  padding-top: 6px;
}
.ant-message-custom-content.ant-message-info {
  padding-top: 6px;
}
.ant-message-custom-content.ant-message-error {
  padding-top: 6px;
} */
/* á */
.claimedJoin {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  letter-spacing: 0.01em !important;
  color: #c99400 !important;
}

.loading .box-content-top,
.loading .name-token,
.loading .desc,
.loading .list-swap,
.loading .list-socail,
.loading .button-view,
.loading .full-search {
  position: relative;
  overflow: hidden;
}

.loading .box-content-top::before,
.loading .name-token::before,
.loading .desc::before,
.loading .list-swap::before,
.loading .list-socail::before,
.loading .button-view::before,
.loading .full-search::before {
  background-color: #dddddd;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loading .box-content-top::after,
.loading .name-token::after,
.loading .desc::after,
.loading .list-swap::after,
.loading .list-socail::after,
.loading .button-view::after,
.loading .full-search::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #dddddd, #ffffff 50%, #dddddd);
  /* animation: skeletonLoading 2s infinite alternate; */
}

@keyframes skeletonLoading {
  from {
    left: -50%;
  }

  to {
    left: 50%;
  }
}

.desc .read-more-less--more,
.desc .read-more-less--less {
  font-weight: 500;
  color: darkcyan;
}

.loading-idoMore {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.emptyData {
  padding: 20% 0;
}

.fc-scrollgrid-sync-table tr {
  height: 80px;
}

.fc-scrollgrid-sync-table {
  height: fit-content !important;
}

.slick-slide img {
  display: block;
  border-radius: 6px;
}

.item-slider-big iframe {
  border-radius: 6px;
}

.round-ending .content-ending-round {
  border: 1px solid #e9eff5;
  padding: 20px;
  border-radius: 6px;
  height: 190px;
  cursor: pointer;
}

.round-ending .content-ending-round .top-ending .box-l {
  display: flex;
}

.round-ending .content-ending-round .top-ending .box-l .name {
  font-weight: 500;
  font-size: 20px;

  /* identical to box height */

  letter-spacing: 0.01em;
  text-align: left;
  color: #1e2329;
  margin-left: 10px;
}

.round-ending .content-ending-round .top-ending .box-l .icon-logo {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  background: gray;
  border-radius: 99px;
  padding: 5px;
}

.round-ending .content-ending-round .top-ending .box-l .icon-logo img {
  border-radius: 99px;

}

.round-ending .content-ending-round .top-ending .box-l .name .title-name {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  display: block;
  color: #707a8a;
}

.price-realtime {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.7px;
  background: rgba(224, 27, 27, 0.2);
  border-radius: 37px;
  color: #b70c0c;
  padding: 4px 8px;
  margin-left: 5px;
}

.round-ending .content-ending-round .top-ending {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.round-ending .content-ending-round .top-ending .box-l {
  width: 60%;
}

.round-ending .content-ending-round .top-ending .box-r {
  width: 40%;
}

.box-img-price {
  width: 116px;
  margin-left: auto;
}

.bottom-ending .list-chain {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0;
  padding-left: 0;
  margin-top: 23px;
}

.bottom-ending .list-chain li {
  list-style-type: none;
  width: 33%;
  text-align: left;
}

.bottom-ending .list-chain li .text-t {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #707a8a;
  margin-bottom: 5px;
}

.bottom-ending .list-chain li .text-b {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #1e2329;
}

.bottom-ending .list-chain li .text-b .icon-symbol {
  margin-right: 5px;
}

.custom-class .ant-message-notice-content {
  display: flex;
  width: fit-content;
  padding: 5px 15px 5px 7px;
  pointer-events: all;
  margin-left: auto;
  justify-content: right;
  box-shadow: 0px 6px 20px rgba(120, 122, 155, 0.18);
  border-radius: 36.5px;
  background: #ffffff;
}

.custom-class .ant-message-warning .anticon.anticon-exclamation-circle {
  background: linear-gradient(223.03deg,
      rgba(250, 198, 104, 0.2) -1.23%,
      rgba(221, 161, 52, 0.2) 102.46%);
  border-radius: 50%;
  padding: 10px;
}

.custom-class .ant-message-success .anticon.anticon-check-circle {
  background: linear-gradient(223.03deg,
      rgba(29, 230, 107, 0.2) -1.23%,
      rgba(12, 183, 79, 0.2) 102.46%);
  border-radius: 50%;
  padding: 10px;
}

.custom-class .ant-message-error .anticon.anticon-close-circle {
  background: linear-gradient(223.03deg,
      rgba(250, 94, 94, 0.2) -1.23%,
      rgba(242, 69, 69, 0.2) 102.46%);
  border-radius: 50%;
  padding: 10px;
}

.custom-class span:nth-child(2) {
  color: #1e2329 !important;
}

p.p-empty {
  padding-top: 30px;
}

.icon-bage {
  width: 13px;
  height: 13px;
}

.bage-custom {
  position: relative;
}

.bage {
  font-size: 12px;
  display: flex;
  border-radius: 22px;
  padding: 4px 11px;
  background: #627eea;
  position: absolute;
  top: 24px;
  /* left: 192px; */
  right: 4px;
  color: #fff;
  align-items: center;
  z-index: 9;
}

.bage-r {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  border-radius: 5px;
  padding: 6px 10px;
  background: #e2b100;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  align-items: center;
  z-index: 9;
}

.bage-s {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  border-radius: 5px;
  padding: 6px 10px;
  background: #3aa3ff;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  align-items: center;
  z-index: 9;
}

.icon-bage {
  margin-right: 6px;
  width: 13px;
  height: 13px;
  margin-bottom: 2px;
}

.main-caledar.mobile {
  height: 100%;
}

.btn-claim-rf {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.btn-claim-rf .claim-1 {
  width: 48%;
}

.btn-claim-rf .rf-1 {
  width: 48%;
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  color: #1e2329;
}

.content-swap-search-ido {
  background: #ffffff;
  border: 0.9px solid #e9edf3;
  box-shadow: 0px 4px 30px rgb(132 132 132 / 10%);
  border-radius: 6px;
  padding: 15px;
  width: 100%;
  z-index: 999;
  margin-top: 47px;
}

.content-swap-search-ido .box-img .img img {
  width: 50px;
}

.content-swap-search-ido .box-img {
  margin-bottom: 10px;
}

.content-swap-search-ido .name-project-s {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  color: #1e2329;
  margin-bottom: 10px;
}

.content-swap-search-ido .name-project-s .token-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
}

.content-swap-search-ido .desc {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #707a8a;
  margin-bottom: 10px;
}

.w-40 {
  width: 40%;
}

.button-view a:hover {
  color: #fff;
}

.round-ido-m svg {
  transform: rotate(180deg) !important;
}

.ant-collapse-item-active.round-ido-m svg {
  transform: rotate(-90deg) !important;
}

.logo-tier-current {
  animation: trasolate 1s infinite linear;
  animation-duration: 4s;
}

.img-tier img:hover {
  transform: scale(1.7);
  cursor: pointer;
  transition: transform 0.2s;
}

@keyframes trasolate {
  0% {
    transform: perspective(400px) rotate3d(0, 1, 0, 0deg);
  }

  100% {
    transform: perspective(400px) rotate3d(0, 1, 0, 360deg);
  }
}

.main-caledar .fc .fc-daygrid-day:hover .fc-daygrid-day-frame {
  background: linear-gradient(104.31deg, #ffd953 -40.22%, #eb0029 350.82%);
  box-shadow: 0px 2px 13px #fac668;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
}

.main-caledar .fc .fc-daygrid-day:hover .btnCalender {
  color: #fff !important;
}

.main-caledar .fc .fc-daygrid-day.fc-day-future:hover .fc-daygrid-day-frame {
  background: #a2a7ab;
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width: 320px) {
  .modal-claimed-after .bsc-p-launchpad_detail-allocations table>tr td:nth-child(2) {
    font-size: 11px !important;
  }

  .modal-claimed-after .bsc-p-launchpad_detail-allocations table>tr td:nth-child(3) {
    font-size: 11px;
    padding-left: 10px !important;
  }

  .modal-claimed-after .btn-disible-cus {
    font-size: 10px !important;
  }

  .modal-claimed-after .btn-waiting-cus {
    font-size: 10px !important;
  }
}

.box-search-round.ido {
  display: flex;
  justify-content: flex-start !important;
}

@media screen and (max-width: 550px) {
  .content-description .content-desc .item-desc .desc-s img {
    width: 100% !important;
    height: auto !important;
  }
}

.custom-class-claim {
  width: 100%;
  /* margin-right: 10%; */
}

.custom-class-rf {
  width: 45%;
  /* margin-left: 10px; */
}

.btn-waiting {
  width: 100% !important;
}

span.ant-skeleton-button.btn-waiting {
  width: 100% !important;
  height: 48px !important;
  border-radius: 6px !important;
}

.not-sp-mb {
  color: #ffff;
  font-weight: 500;
  position: fixed;
  top: 70px;
  width: 95%;
  z-index: 999;
}

.not-sp-mb .ant-alert-with-description .ant-alert-description {
  display: block;
  text-align: start;
}

.not-sp-mb .ant-alert-warning {
  background-color: #fffbe6;
  border: 1px solid #ffe58f !important;
  border-radius: 8px !important;
}

.modal-not-sp .ant-btn.ant-btn-default {
  display: none;
}

.round-kyc-details {
  background: #627eea;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  border-radius: 22px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: fit-content;
  padding: 5px 10px;
  position: absolute;
  right: -10px;
  top: 10px;
  border: 0px solid transparent;
  cursor: pointer;
}

.text-kyc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin-top: 40px;
  color: #707a8a;
  margin-bottom: 20px;
}

.kyc-btn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.06em;

  color: #ffffff;
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  border: 0px solid transparent;
  width: 310px;
  height: 50px;
  margin: 7px auto;
  cursor: pointer;
}

.box-btn-kyc {
  text-align: center;
}

.kyc-btn.cus {
  background: #e5e5e8;
  color: #000;
}

.min-max {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #707a8a;

  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
  padding-top: 10px;
  font-weight: 600;
}

.submit-tier {
  padding-bottom: 10px;
}

.submit-tier .ant-input-number-input {
  background-color: #f5f5f5;
}

.submit-tier .ant-btn:hover {
  border-color: transparent;
}

.submit-tier .ant-input-number-group-wrapper {
  width: 100%;
}

.bage-s-detail {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #3aa3ff;
  width: fit-content;
  color: #fff;
  padding: 6px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.bage-v-detail {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #1E3A8A;
  width: fit-content;
  color: #fff;
  padding: 6px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.bage-r-detail {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #e2b100;
  width: fit-content;
  color: #fff;
  padding: 6px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.ant-modal.modal-disclaimer {
  width: 50% !important;
}

@media screen and (max-width: 768px) {
  .ant-modal.modal-disclaimer {
    width: 90% !important;
  }
}

.modal-disclaimer .ant-modal-close {
  display: none;
}

.modal-disclaimer .ant-modal-header {
  padding: 20px 24px 0px 24px;
}

.modal-disclaimer .ant-modal-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #1e2329;
  text-align: center;
}

.li-bot {
  padding-bottom: 5px;
}

.modal-disclaimer .title {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #1e2329;
  padding-bottom: 15px;
  font-family: "Poppins";
  font-style: normal;
}

.modal-disclaimer .list-text-claimer {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #707a8a;
  font-family: "Poppins";
  font-style: normal;
}

.li-mid {
  list-style-type: none;
}

.modal-disclaimer .ant-modal-body {
  padding: 24px 35px;
}

.modal-disclaimer .list-checkbox {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #1e2329;
  list-style-type: none;
}

.modal-disclaimer .ant-select-selector {
  width: 150px !important;
  height: auto !important;
}

.modal-disclaimer .box-select {
  padding-top: 10px;
}

.modal-disclaimer .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border: 1px solid #e9edf3;
  box-shadow: none;
}

.footer-modal {
  text-align: center;
}

.btn-modal-claimer.disable {
  background: #e4e4eb;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  border: none;
  width: 190px;
  color: #bfbfbf;
  height: 40px;
}

.btn-modal-claimer {
  background: #1e2329;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  border: none;
  width: 190px;
  color: #fff;
  height: 40px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
}

.btn-modal-claimer:hover {
  cursor: pointer;
  background: #3f4a57;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
}

.btn-modal-claimer.disable:hover {
  cursor: no-drop;
}

.btn-refund {
  height: auto;
  background: #fff;
  box-shadow: 0px 6px 20px rgba(198, 198, 198, 0.44);
  border-radius: 6px;
  /* width: 100%; */
  margin: 0;
  color: #1e2329;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.06em;
}

.btn-refund:hover {
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(120, 122, 155, 0.18);
}

.btn-join.custom-class-claim:hover {
  color: #1e2329 !important;
}

.count-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 138%;
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.count-num-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding-right: 5px;
}

.count-num-img {
  width: 20px;
  height: 20px;
}

.count-num {
  display: flex;
  align-items: center;
}

.wrap-count {
  padding: 20px 34px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;

  box-shadow: 0px 10px 20px rgba(255, 109, 76, 0.25);
}

.count-coming {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.count-coming::before {
  content: "";
  width: 198.73px;
  background: linear-gradient(93.41deg, #ffd75a -40.87%, #eb0029 170.93%),
    linear-gradient(0deg, #ffffff, #ffffff);
  height: 104.11px;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: -6;
  top: 50%;
  left: 50%;
  border-radius: 10px;
}

.btn-claim-sol {
  background: #66d8db;
  border-radius: 3px;
  color: #fff;
  padding: 7px 11px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid transparent;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  width: 48%;
}

.btn-claim-sol:hover {
  cursor: pointer;
  background: #2ac6ca;
}

.btn-claim-sol:disabled {
  background: #e4e4eb;
  color: #b6b8c3;
  cursor: no-drop;
}

.btn-refund-sol {
  background: #66d8db;
  border-radius: 3px;
  color: #fff;
  padding: 7px 11px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid transparent;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  width: 48%;
}

.btn-refund-sol:hover {
  cursor: pointer;
  background: #2ac6ca;
}

.btn-refund-sol:disabled {
  background: #e4e4eb;
  color: #b6b8c3;
  cursor: no-drop;
}

.btn-refundAll-sol {
  background: #66d8db;
  border-radius: 3px;
  color: #fff;
  padding: 7px 11px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid transparent;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  margin: 5px 0;
}

.btn-refundAll-sol:hover {
  cursor: pointer;
  background: #2ac6ca;
}

.btn-refundAll-sol:disabled {
  background: #e4e4eb;
  color: #b6b8c3;
  cursor: no-drop;
}

@media screen and (max-width: 768px) {
  .btn-claim-sol {
    width: 100%;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .btn-refund-sol {
    width: 100%;
    font-size: 14px;
    margin-bottom: 3px;
  }

  .btn-refundAll-sol {
    margin: 0;
    padding: 7px 0;
  }
}

.submit-tier .ant-input-number {
  border: 1px solid #fac668;
}

.submit-tier .ant-input-number-group-addon {
  border: 1px solid #fac668;
  border-left: 0px solid transparent;
}

.color-green {
  color: #1e2329 !important;
}

.color-yellow {
  color: #e2b100 !important;
}

/* csss affiatio  */
.round-affiliate {
  background: #ffffff;
  border: 0.9px solid #e9edf3;
  box-shadow: 0px 4px 30px rgba(132, 132, 132, 0.1);
  border-radius: 6px;
  padding: 30px;
}

.round-affiliate .content-affiliate {
  text-align: left;
}

.round-affiliate .content-affiliate .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height */

  margin-bottom: 30px;
  color: #1e2329;
}

.round-affiliate .content-affiliate .flex-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.round-affiliate .content-affiliate .flex-item .txt-l {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #707a8a;
}

.round-affiliate .content-affiliate .flex-item .txt-r.account {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 0.03em;
  background: #f6f7fc;
  border-radius: 6px;
  padding: 5px 10px;
  color: #1e2329;
}

.round-affiliate .content-affiliate .flex-item .txt-r.account img {
  width: 15px;
}

.round-affiliate .content-affiliate .flex-item {
  margin-bottom: 15px;
}

.round-affiliate .content-affiliate .flex-item .txt-r {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 0.03em;
  color: #1e2329;
}

.round-affiliate .content-affiliate .flex-item .txt-r.claim .btn-claim-ref {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;
  background: #1e2329;
  /* button lock */
  padding: 10px 15px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  color: #ffffff;
  cursor: pointer;
  border: 0px solid transparent;
}

.round-affiliate .content-affiliate .flex-item .txt-r.claim .btn-claim-ref.dis {
  color: #b6b8c3;
  background: #e4e4eb;
  cursor: no-drop;
}

.round-affiliate .content-affiliate .flex-item .txt-r .row-raised {
  display: block;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;
  background: #627eea;
  border-radius: 22px;
  padding: 7px 15px;
  color: #ffffff;
  margin-top: 5px;
}

.round-affiliate .content-affiliate .flex-item.border-row {
  border-bottom: 1px solid #aab6cd63;
  padding-bottom: 12px;
  margin-bottom: 12px;
}

.round-affiliate .content-affiliate {
  padding-right: 30px;
}

.round-affiliate .content-affiliate.border-rights {
  padding-right: 0;
  padding-left: 30px;
  position: relative;
}

.round-affiliate .content-affiliate.border-rights::before {
  content: "";
  background: #aab6cd63;
  width: 1px;
  height: 100%;
  position: absolute;
  left: -10px;
  top: 0;
}

.btn-top-reward {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.06em;
  background: #1e2329;
  border-radius: 6px;
  color: #ffffff;
  padding: 13px 27px;
  border: 0px solid transparent;
  cursor: pointer;
}

.round-affiliate .content-affiliate.border-rights .title {
  margin-bottom: 20px;
  text-align: right;
}

.modal-ref {
  width: 692px !important;
}

.modal-ref .ant-modal-header {
  padding: 35px 25px 25px 25px;
}

.modal-ref .ant-modal-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;

  color: #1e2329;
}

.modal-ref .ant-modal-footer {
  display: none;
}

.modal-ref .main-table .tr-top {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #000000;
}

.not-ref {
  text-align: center;
  margin: 70px 0 50px 0;
}

.not-ref img {
  width: auto;
}

.btn-claim-ref .ant-spin-dot-item {
  background: #fff;
}

.btn-claim-ref .ant-spin {
  margin-left: 10px;
}

.process-join-num {
  text-align: end;
  color: #707a8a;
}

.times-join-detail {
  color: #1e2329;
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .round-affiliate {
    padding: 10px;
  }

  .round-affiliate .content-affiliate {
    padding-right: 0;
  }

  .round-affiliate .content-affiliate .title {
    margin-bottom: 15px;
  }

  .round-affiliate .content-affiliate .flex-item {
    display: block;
    border-bottom: 1px solid #aab6cd63;
    padding-bottom: 10px;
  }

  .round-affiliate .content-affiliate .flex-item .txt-r {
    text-align: left;
    margin-top: 10px;
  }

  .round-affiliate .content-affiliate .flex-item .txt-r.account {
    text-align: left;
    width: fit-content;
  }

  .round-affiliate .content-affiliate .flex-item .txt-r.claim .btn-claim-ref {
    font-size: 14px;
  }

  .round-affiliate .content-affiliate.border-rights .title {
    text-align: left;
  }

  .round-affiliate .content-affiliate.border-rights {
    padding-left: 0;
  }

  .round-affiliate .content-affiliate.border-rights::before {
    display: none;
  }

  .round-affiliate .content-affiliate .flex-item .txt-r .row-raised {
    width: fit-content;
    margin-top: 10px;
  }

  .round-affiliate .content-affiliate.border-rights .flex-item:last-child {
    border-bottom: 0px solid #aab6cd63;
    margin-bottom: 0;
  }

  .modal-ref {
    width: 100% !important;
  }

  .modal-ref .main-table tr td {
    font-size: 14px;
  }

  .round-affiliate .content-affiliate .title {
    font-size: 20px;
  }

  .btn-top-reward {
    font-size: 14px;
  }
}

.rc-virtual-list-holder {
  overflow-y: scroll !important;
}

.btn-fund {
  padding: 6px 10px;
  font-size: 14px;
}

.y-allo {
  text-align: left;
  color: #707a8a;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.42px;
  padding-bottom: 10px;
}

.line-allo {
  height: 1px;
  width: 100%;
  background: #d9d9d9;
  margin-top: 5px;
  margin-bottom: 15px;
}

.next-ves {
  color: #1e2329;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.modal-goto-sui .ant-modal-title {
  text-align: center;
  color: #1e2329;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.modal-goto-sui .ant-modal-header {
  padding-bottom: 0px;
}

.modal-goto-sui .ant-modal-body {
  padding-top: 15px;
}

.noti-sui {
  font-size: 16px;
  text-align: center;
  color: #1e2329;
  font-family: "Inter";
  padding-bottom: 10px;
}

.go-sui {
  text-align: center;
}

.go-sui button {
  background: #1e2329;
  box-shadow: 0 6px 20px hsla(0, 0%, 78%, 0.44);
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  border: none;
  border-radius: 6px;
  height: 40px;
  width: 190px;
}

.go-sui button:hover {
  cursor: pointer;
}

.modal-goto-sui .ant-modal-close {
  display: none;
}

.rounds-process .ant-progress-text {
  width: auto;
}

.please-connect:hover {
  cursor: text !important;
}

.confirm-rf {
  font-size: 16px;
  color: rgba(0, 0, 0, .85);
  text-align: center;
}

.choose-text {
  color: #1E2329;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.choose1-text1 {
  color: #1E2329;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.54px;
}

.choose1-text2 {
  color: #707A8A;
  font-family: 'Poppins';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
}

.choose1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 20px 30px;
  border: 1px solid #E1AE47;
  background: rgba(225, 174, 71, 0.05);
  margin-bottom: 14px;
}

.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}


.radio-item input[type='radio'] {
  display: none;
}

.radio-item label {
  color: #666;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 18px;
  height: 18px;
  border-radius: 11px;
  border: 1px solid #E1AE47;
  background-color: transparent;
  cursor: pointer;
}

.radio-item input[type=radio]:checked+label:after {
  border-radius: 11px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #E1AE47;
}

.no-choose {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  border-radius: 10px;
  border: 1px solid #DBE1EA;
  background: #FFF;
  margin-bottom: 14px;
}

.set-price {
  color: #1E2329;
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 8px;
}

.set-price-label {
  display: flex;
  justify-content: space-between;
}

.label-left {
  color: #1E2329;
  font-family: 'Poppins';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
}

.label-right {
  color: #707A8A;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
}

input.input-set-price {
  width: 100%;
  border: none;
  height: 45px;
  border-radius: 6px;
  background: #EFF2F5;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.10) inset;
}

.bot-text-set {
  color: #707A8A;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  padding-top: 3px;
}

.group-btn-price {
  padding-top: 15px;
  display: flex;
  gap: 10px;
}

.btn-set-price {
  width: 70%;
  border-radius: 6px;
  background: #1E2329;
  color: #fff;
  border: none;
  height: 42px;
  text-align: center;
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.9px;
}

.btn-cancel-price {
  width: 30%;
  color: #1E2329;
  height: 42px;
  text-align: center;
  font-family: 'Poppins';
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.9px;
  border-radius: 6px;
  background: #F3F3F4;
  border: none;
}

.refund-detal-col .ant-collapse-header-text {
  color: #C99400;
  font-family: 'Poppins';
  font-size: 15px;
  font-weight: 500;
}

.refund-detal-col .ant-collapse-arrow svg {
  fill: #C99400;
  font-size: 16px;
}

.refund-detal-col .ant-collapse-arrow {
  right: 25% !important;
}

.detail-rf-text {
  color: #707A8A;
  font-family: 'Poppins';
  font-size: 14px;
  text-align: start;
}

.group-button-rf {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  padding: 15px 0;
}

.refund-detal-col {
  background-color: #F3F3F4;
  border-radius: 10px;
}

.partially-rf {
  color: #fff;
  background: #000;
  height: 50px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(60% - 7px);
  border: 1px solid transparent;
  transition: 0.3s ease-in-out
}
.rf-usdc{
  margin-top: 10px;
}
.partially-rf:hover {
  background: #fff;
  cursor: pointer;
  color: #000;
  border: 1px solid #0000006d;
}

.partially-rf[disabled] {
  background: #e4e4eb;
  cursor: not-allowed;
  color: #b6b8c4;
  border: 1px solid #2d0a0207;
}


.full-rf {
  color: #B32100;
  background: transparent;
  border: 1px solid #B32100;
  height: 50px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(40% - 7px);
  transition: 0.3s ease-in-out
}

.full-rf:hover {
  background: #fff;
  cursor: pointer;
  color: #ef3308;
  border: 1px solid #ef3308;
}

.full-rf[disabled] {
  background: #e4e4eb;
  cursor: not-allowed;
  color: #b6b8c4;
  border: 1px solid #2d0a0207;
}

.group-button-rf1 {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-spin-dot-item {
  background-color: #0538f0;
}


.btn-claim-vang {
  color: #fff;
  background: #000;
  height: 50px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid transparent;
  transition: 0.3s ease-in-out
}

.btn-claim-vang:hover {
  background: #fff;
  cursor: pointer;
  color: #000;
  border: 1px solid #0000006d;
}

.btn-claim-vang[disabled] {
  background: #e4e4eb;
  cursor: not-allowed;
  color: #b6b8c4;
  border: 1px solid #2d0a0207;
}